import { useState } from 'react';
import { useChangeElementQuantity } from '../../../api/elements/change-element-quantity';
import { useChangeModuleQuantity } from '../../../api/modules/change-module-quantity';
import { NavigationPath } from '../../../data/constants';

export interface WarehouseQuantityState {
  showQuantityConfirmationModal: boolean;
  setShowQuantityConfirmationModal: (showModal: boolean) => void;
  quantity: number;
  setQuantity: (newQuantity: number) => void;
  callChangeElementQuantity: (request: any) => any;
  quantityModalTitle: string;
  quantityModalBodyText: string;
  craftElementNavigationPath: NavigationPath;
}

export function useWarehouseQuantityState(props: {
  warehouseQuantity: number;
  callChangeQuantity: (request: any) => any;
  modalTitleSuffix: string;
  modalBodyTextExtension: string;
  craftElementNavigationPath: NavigationPath;
}): WarehouseQuantityState {
  const [showQuantityConfirmationModal, setShowQuantityConfirmationModal] =
    useState(false);
  const [quantity, setQuantity] = useState(props.warehouseQuantity ?? 0);

  return {
    showQuantityConfirmationModal: showQuantityConfirmationModal,
    setShowQuantityConfirmationModal: setShowQuantityConfirmationModal,
    quantity: quantity,
    setQuantity: setQuantity,
    callChangeElementQuantity: props.callChangeQuantity,
    quantityModalTitle: `Zaktualizowanie ilości ${props.modalTitleSuffix}`,
    quantityModalBodyText: `Czy na pewno chcesz ustawić stan magazynu dla wybranego ${props.modalBodyTextExtension} na ilość ${quantity}?`,
    craftElementNavigationPath: props.craftElementNavigationPath,
  };
}

export function useWarehouseElementQuantityState(
  warehouseQuantity: number
): WarehouseQuantityState {
  const { callChangeElementQuantity } = useChangeElementQuantity();

  return useWarehouseQuantityState({
    warehouseQuantity: warehouseQuantity,
    callChangeQuantity: callChangeElementQuantity,
    modalTitleSuffix: 'elementów',
    modalBodyTextExtension: 'elementu',
    craftElementNavigationPath: NavigationPath.CRAFT_ELEMENT,
  });
}

export function useWarehouseModuleQuantityState(
  warehouseQuantity: number
): WarehouseQuantityState {
  const { callChangeModuleQuantity } = useChangeModuleQuantity();

  return useWarehouseQuantityState({
    warehouseQuantity: warehouseQuantity,
    callChangeQuantity: callChangeModuleQuantity,
    modalTitleSuffix: 'modułów',
    modalBodyTextExtension: 'modułu',
    craftElementNavigationPath: NavigationPath.CRAFT_MODULE,
  });
}
