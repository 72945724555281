import {
  faPenToSquare,
  faTrashCan,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import commonStyles from '../../../../desktop-view/Common.module.scss';
import { BaseRendererType, CustomTableState } from '../../CustomTable';
import { SchemaQuantityState } from '../../field-states/SchemaQuantityState';
import { SubModulesModificationProps } from '../../../modules/SubModulesTable';
import { SubModuleModel } from '../../../../data/module-model';

export function SubModulesActionButtonsRenderer(
  props: BaseRendererType
): JSX.Element {
  const tableState: CustomTableState = props.tableState;
  const object = tableState.object;
  const schemaState: SchemaQuantityState = props.cellStates['schemaQuantity'];
  const subModulesModificationProps: SubModulesModificationProps =
    tableState.externalProps;

  const handleEditClick = (module: SubModuleModel) => {
    tableState.setObject({
      ...module,
      isEditMode: true,
    });
  };

  const handleConfirmationClick = (moduleId: string, newQuantity: number) => {
    tableState.setObject({
      ...object,
      isEditMode: false,
    });
    schemaState.setQuantity(newQuantity);
    subModulesModificationProps.updateSubModuleQuantity(moduleId, newQuantity);
  };

  const handleRemovalClick = (module: SubModuleModel) => {
    tableState.setObject({
      ...module,
      isEditMode: false,
    });
    schemaState.setQuantity(module.schemaQuantity);
  };

  const handleDeleteClick = (moduleId: string) => {
    subModulesModificationProps.removeSubModule(moduleId);
  };

  const isEditMode = () => {
    return object.isEditMode;
  };

  return (
    <>
      {isEditMode() && (
        <FontAwesomeIcon
          icon={faCheck}
          size={'xl'}
          className={`${commonStyles.iconGreen} me-3`}
          onClick={() =>
            handleConfirmationClick(object.id, schemaState.quantity)
          }
        />
      )}
      {!isEditMode() && (
        <FontAwesomeIcon
          icon={faPenToSquare}
          size={'xl'}
          className={`${commonStyles.iconPrimary} me-3`}
          onClick={() => handleEditClick(object)}
        />
      )}
      {isEditMode() && (
        <FontAwesomeIcon
          icon={faXmark}
          size={'xl'}
          className={`${commonStyles.iconRed} me-3`}
          onClick={() => handleRemovalClick(object)}
        />
      )}
      {!isEditMode() && (
        <FontAwesomeIcon
          icon={faTrashCan}
          size={'xl'}
          className={commonStyles.iconRed}
          onClick={() => handleDeleteClick(object.id)}
        />
      )}
    </>
  );
}
