import { Col, FloatingLabel, Form, FormGroup, Row } from 'react-bootstrap';
import React, { useMemo } from 'react';
import { debounce } from 'lodash';
import { GetWarehouseHistoryRequest } from '../../api/history/get-warehouse-history';
import { OperationType } from '../../data/history-model';
import { warehouseOperationTypeMappings } from '../../api/operation-type';

export interface WarehouseHistoryFiltersProps {
  warehouseHistoryRequest: GetWarehouseHistoryRequest;
  setWarehouseHistoryRequest: (request: GetWarehouseHistoryRequest) => void;
}

export function WarehouseHistoryFilters(
  props: Readonly<WarehouseHistoryFiltersProps>
) {
  const { warehouseHistoryRequest, setWarehouseHistoryRequest } = props;

  const operationTypes = useMemo(() => warehouseOperationTypeMappings(), []);

  const onStockKeepingUnitChanged = debounce((stockKeepingUnit: string) => {
    setWarehouseHistoryRequest({
      ...warehouseHistoryRequest,
      stockKeepingUnit,
      page: {
        ...warehouseHistoryRequest.page,
        pageNumber: 0,
      },
    });
  }, 500);

  const onEmailChanged = debounce((email: string) => {
    setWarehouseHistoryRequest({
      ...warehouseHistoryRequest,
      email,
      page: { ...warehouseHistoryRequest.page, pageNumber: 0 },
    });
  }, 500);

  const onOperationTypeChanged = debounce((operationType: string) => {
    setWarehouseHistoryRequest({
      ...warehouseHistoryRequest,
      operationType: operationType !== '' ? operationType : undefined,
      page: { ...warehouseHistoryRequest.page, pageNumber: 0 },
    });
  }, 500);

  const onDateFromChanged = debounce((dateFrom: string) => {
    setWarehouseHistoryRequest({
      ...warehouseHistoryRequest,
      dateFrom: new Date(dateFrom),
      page: { ...warehouseHistoryRequest.page, pageNumber: 0 },
    });
  }, 500);

  const onDateToChanged = debounce((dateTo: string) => {
    setWarehouseHistoryRequest({
      ...warehouseHistoryRequest,
      dateTo: new Date(dateTo),
      page: { ...warehouseHistoryRequest.page, pageNumber: 0 },
    });
  }, 500);

  return (
    <Form className='pt-5 pb-5'>
      <Row>
        <Col
          sm='2'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='stockKeepingUnit'
              label='SKU'>
              <Form.Control
                type='text'
                placeholder='Stock Keeping Unit'
                onChange={e => onStockKeepingUnitChanged(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
        <Col
          sm='3'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='email'
              label='E-Mail'>
              <Form.Control
                type='text'
                placeholder='E-Mail'
                onChange={e => onEmailChanged(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
        <Col
          sm='3'
          className='mb-2'>
          <FloatingLabel
            controlId='operationType'
            label='Typ akcji'>
            <Form.Select onChange={e => onOperationTypeChanged(e.target.value)}>
              <option value={''}>Wybierz Typ Akcji...</option>
              {Object.keys(OperationType).map(key => {
                const value = operationTypes.get(key);
                return (
                  <option
                    key={key}
                    value={key}>
                    {value}
                  </option>
                );
              })}
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Col
          sm='2'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='dateFrom'
              label='Data od'>
              <Form.Control
                type='date'
                placeholder='Data od'
                onChange={e => onDateFromChanged(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
        <Col
          sm='2'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='dateTo'
              label='Data do'>
              <Form.Control
                type='date'
                placeholder='Data do'
                onChange={e => onDateToChanged(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}
