import { usePostAction } from '../http-client';

interface ChangeModuleQuantityRequest {
  id: string;
  oldQuantity: number;
  newQuantity: number;
}

function useChangeModuleQuantity() {
  const { callPost } = usePostAction<ChangeModuleQuantityRequest, void>(
    '/change-module-quantity'
  );
  return { callChangeModuleQuantity: callPost };
}

export { useChangeModuleQuantity };
