import { useState } from 'react';

export interface SchemaQuantityState {
  quantity: number;
  setQuantity: (newQuantity: number) => void;
}

export function useSchemaQuantityState(
  schemaQuantity: number
): SchemaQuantityState {
  const [quantity, setQuantity] = useState(schemaQuantity);

  return {
    quantity: quantity,
    setQuantity: setQuantity,
  };
}
