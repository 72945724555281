import { AxiosInstance } from 'axios';
import { WebAuthProvider } from './web-auth-provider';
import { InitLoginProps } from './init-login-props';
import { UserInfo } from './user-info';

export interface AuthProvider {
  getUserInfo(): UserInfo;

  decorateWithAuth(httpClient: AxiosInstance): AxiosInstance;

  logout(): Promise<void>;

  initLoginFlow(): Promise<InitLoginProps>;

  isLoggedIn(): boolean;

  setInitialURL(url: string): void;

  navigateToPasswordUpdate(): void;
}

export const authProvider: AuthProvider = new WebAuthProvider();
