import { ConfigService } from './config-service';
import { LocalStorageRepository } from './local-storage-repository';
import { TokenInfo } from '../../data/common-model';
import { RequestPreparer } from './request-preparer';
import { TokenRequestPerformer } from './token-request-performer';
import {
  AuthorizationListener,
  AuthorizationNotifier,
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
} from '@openid/appauth';
import { sleep } from './sleep';
import { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { AuthProvider } from './auth';
import {
  ACCESS_TOKEN_EXPIRATION_DEBOUNCE_SECONDS,
  INITIAL_URL_LOCAL_STORAGE_KEY,
  REDIRECT_URI,
  TIME_FOR_REDIRECTING_TO_OAUTH_SERVER_MILLIS,
  TIME_TO_WAIT_BEFORE_CHECKING_IF_REFRESH_TOKEN_FINISHED_MILLIS,
  TOKEN_LOCAL_STORAGE_KEY,
  UPDATE_PASSWORD_URL,
} from './auth-consts';
import { InitLoginProps } from './init-login-props';
import { jwtDecode } from 'jwt-decode';
import { UserInfo } from './user-info';
import { TokenContent, UserRole } from './token-content';

export class WebAuthProvider implements AuthProvider {
  private initialURLRepository = new LocalStorageRepository<string>(
    INITIAL_URL_LOCAL_STORAGE_KEY
  );
  private configService = new ConfigService();
  private tokenRepository: LocalStorageRepository<TokenInfo> =
    new LocalStorageRepository<TokenInfo>(TOKEN_LOCAL_STORAGE_KEY);
  private refreshTokenInProgress = false;
  private requestPreparer = new RequestPreparer();
  private tokenRequestPerformer = new TokenRequestPerformer();

  private isBeforeRedirect() {
    return !window.location.hash;
  }

  setInitialURL(url: string): void {
    this.initialURLRepository.setValue(url);
  }

  private async handleLoginFlowPartBeforeRedirect(
    config: AuthorizationServiceConfiguration
  ) {
    const redirectHandler = new RedirectRequestHandler();
    redirectHandler.performAuthorizationRequest(
      config,
      this.requestPreparer.prepareAuthorizationRequest()
    );
    await sleep(TIME_FOR_REDIRECTING_TO_OAUTH_SERVER_MILLIS);

    return Promise.reject(
      'Invalid state redirect to oauth server should happened before'
    );
  }

  private prepareAuthorizationListener(
    config: AuthorizationServiceConfiguration,
    resolve: (value: TokenInfo) => void,
    reject: (value: string) => void
  ) {
    const listener: AuthorizationListener = (request, response, error) => {
      if (response && request.internal) {
        this.tokenRequestPerformer
          .fetchInitialToken(config, request, response)
          .then(resolve, reject);
      } else {
        reject('Fail in authorization listener');
      }
    };
    return listener;
  }

  private mapToUserInfo(tokenInfo: TokenInfo): UserInfo {
    const decodedToken = jwtDecode<TokenContent>(tokenInfo.accessToken);
    const userRoles = decodedToken.realm_access?.roles?.filter(it =>
      Object.keys(UserRole).includes(it)
    );
    if (userRoles.length === 1) {
      const role = userRoles[0]; // we support only one role now;
      return {
        firstName: decodedToken.given_name,
        lastName: decodedToken.family_name,
        email: decodedToken.email,
        role: role,
      };
    } else {
      this.logout().then();
      throw Error('User has invalid roles');
    }
  }

  private async handleLoginFlowPartAfterRedirect(
    config: AuthorizationServiceConfiguration
  ): Promise<void> {
    const redirectHandler = new RedirectRequestHandler();

    return new Promise<void>(async (resolve, reject) => {
      const resolveAndSaveToken = (tokenInfo: TokenInfo) => {
        this.tokenRepository.setValue(tokenInfo);
        resolve();
      };
      const authorizationListener: AuthorizationListener =
        this.prepareAuthorizationListener(config, resolveAndSaveToken, reject);

      const notifier = new AuthorizationNotifier();
      notifier.setAuthorizationListener(authorizationListener);
      redirectHandler.setAuthorizationNotifier(notifier);
      await redirectHandler.completeAuthorizationRequestIfPossible();
    });
  }

  async initLoginFlow(): Promise<InitLoginProps> {
    const config = await this.configService.getConfig();

    if (this.isBeforeRedirect()) {
      return this.handleLoginFlowPartBeforeRedirect(config);
    } else {
      return this.handleLoginFlowPartAfterRedirect(config).then(() => {
        return {
          redirectURL: this.initialURLRepository.removeValue(),
        };
      });
    }
  }

  private accessTokenIsValid() {
    const expirationTimestampSeconds =
      this.tokenRepository.getValue()?.expirationTimestampSeconds ?? -1;
    const nowInSeconds = Date.now() / 1000;
    return (
      nowInSeconds + ACCESS_TOKEN_EXPIRATION_DEBOUNCE_SECONDS <=
      expirationTimestampSeconds
    );
  }

  isLoggedIn() {
    return this.tokenRepository.getValue() != null;
  }

  private async waitUntilFinishAnotherRefreshTokenCall() {
    while (this.refreshTokenInProgress) {
      await sleep(
        TIME_TO_WAIT_BEFORE_CHECKING_IF_REFRESH_TOKEN_FINISHED_MILLIS
      );
    }
  }

  private async tryRefreshToken(): Promise<void> {
    if (this.refreshTokenInProgress) {
      await this.waitUntilFinishAnotherRefreshTokenCall();
      const token = this.tokenRepository.getValue();
      if (token == null) {
        return Promise.reject('Another refresh token failed');
      }
    } else {
      this.refreshTokenInProgress = true;
      const tokenInfo = this.tokenRepository.getValue();
      if (tokenInfo == null) {
        this.refreshTokenInProgress = false;
        return Promise.reject('No refresh token');
      }
      const config = await this.configService.getConfig();
      try {
        const response = await this.tokenRequestPerformer.fetchNextTokenRequest(
          config,
          tokenInfo
        );
        this.tokenRepository.setValue(response);
      } catch (error) {
        this.refreshTokenInProgress = false;
        return Promise.reject('No refresh token request failed');
      } finally {
        this.refreshTokenInProgress = false;
      }
    }
  }

  async logout() {
    const token = this.tokenRepository.removeValue();
    const config = await this.configService.getConfig();
    this.configService.clear();
    if (config && token) {
      window.location.href = `${config.endSessionEndpoint}?post_logout_redirect_uri=${REDIRECT_URI}&id_token_hint=${token.idToken}`;
    } else {
      window.location.reload();
      return Promise.reject('Logout failed');
    }
  }

  private addAuthHeader(
    config: InternalAxiosRequestConfig<any>,
    token: string
  ): InternalAxiosRequestConfig<any> {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  }

  decorateWithAuth(httpClient: AxiosInstance): AxiosInstance {
    const handleAuth = async (config: InternalAxiosRequestConfig<any>) => {
      if (!this.accessTokenIsValid()) {
        try {
          await this.tryRefreshToken();
        } catch (error) {
          this.logout();
        }
      }
      const token = this.tokenRepository.getValue()?.accessToken;
      if (token) {
        this.addAuthHeader(
          config,
          this.tokenRepository.getValue()?.accessToken!
        );
      } else {
        throw Promise.reject('No access token to add to request');
      }
      return config;
    };

    httpClient.interceptors.request.use(handleAuth);
    return httpClient;
  }

  getUserInfo(): UserInfo {
    const token = this.tokenRepository.getValue();
    if (token) {
      return this.mapToUserInfo(token);
    } else {
      this.logout().then();
      throw Error('Failed to get user info');
    }
  }

  navigateToPasswordUpdate() {
    window.location.href = UPDATE_PASSWORD_URL;
  }
}
