import { useSetTitle } from '../../../context/TitleContext';
import { useParams } from 'react-router-dom';
import { useUpdateProduct } from '../../../api/products/update-product';
import {
  GetSingleProductRequest,
  useGetSingleProduct,
} from '../../../api/products/get-single-product';
import React, { useState } from 'react';
import {
  ProductSchemaForm,
  ProductSchemaFormModel,
} from '../../../components/products/ProductSchemaForm';
import { apiError, apiSuccess } from '../../../api/http-client';
import { SchemaFormModal } from '../../../components/form/SchemaFormModal';
import { CenteredSpinner } from '../../../components/CenteredSpinner';

const EditProductSchema = () => {
  useSetTitle('Schematy - Edytuj produkt');
  const { productId } = useParams();
  const { updateProduct } = useUpdateProduct();
  const [request] = useState<GetSingleProductRequest>({ id: productId! });
  const { response } = useGetSingleProduct(request);
  const product = response?.data;
  const [showAfterSubmitModal, setShowAfterSubmitModal] = useState(false);

  const handleSubmit = async (form: ProductSchemaFormModel) => {
    return updateProduct({
      id: product?.id!,
      name: form.name,
      stockKeepingUnit: form.stockKeepingUnit,
      category: form.category,
      europeanArticleNumber: form.europeanArticleNumber,
      imageId: form.imageId,
      moduleList: form.moduleList,
    })
      .then(response => {
        setShowAfterSubmitModal(true);
        return apiSuccess(response);
      })
      .catch(e => {
        return apiError(e);
      });
  };

  return product ? (
    <>
      <ProductSchemaForm
        onSubmit={handleSubmit}
        productSchema={product}
        editMode={true}
      />
      <SchemaFormModal
        show={showAfterSubmitModal}
        setShow={setShowAfterSubmitModal}
        title={'Edytowano schemat produktu'}
        body={
          'Udało się edytować schemat produktu! Wróć do listy lub dokonaj kolejnych zmian.'
        }
        backButtonText={'Wróć do listy'}
        nextButtonText={'Dokonaj kolejnych zmian'}
      />
    </>
  ) : (
    <CenteredSpinner />
  );
};

export { EditProductSchema };
