import React, { useState } from 'react';
import {
  ElementSchemaForm,
  ElementSchemaFormModel,
} from '../../../components/elements/ElementSchemaForm';
import { useSetTitle } from '../../../context/TitleContext';
import { useCreateElement } from '../../../api/elements/create-element';
import { SchemaFormModal } from '../../../components/form/SchemaFormModal';

export default function AddElementSchema() {
  useSetTitle('Schematy - Dodaj element');
  const { callCreateElement } = useCreateElement();
  const [showAfterSubmitModal, setShowAfterSubmitModal] = useState(false);

  const handleSubmit = async (elementSchemaForm: ElementSchemaFormModel) => {
    return callCreateElement({
      name: elementSchemaForm.name,
      stockKeepingUnit: elementSchemaForm.stockKeepingUnit,
      category: elementSchemaForm.category,
      imageId: elementSchemaForm.imageId,
      isElementSet: elementSchemaForm.isElementSet,
      subElementList: elementSchemaForm.subElementList,
    }).then(response => {
      setShowAfterSubmitModal(!response.error);
      return response;
    });
  };

  return (
    <>
      <ElementSchemaForm
        onSubmit={handleSubmit}
        editMode={false}
      />
      <SchemaFormModal
        show={showAfterSubmitModal}
        setShow={setShowAfterSubmitModal}
        title={'Dodano nowy schemat elementu'}
        body={
          'Udało się dodać nowy schemat elementu! Wróć do listy lub dodaj kolejny schemat.'
        }
        backButtonText={'Wróć do listy'}
        nextButtonText={'Dodaj kolejny schemat'}
      />
    </>
  );
}
