import { CenteredSpinner } from '../../../components/CenteredSpinner';
import CustomPagination from '../../../components/CustomPagination';
import {
  CustomColumn,
  CustomTable,
} from '../../../components/table/CustomTable';
import { useWarehouseHistory } from '../../../api/history/warehouse-history';
import { Col, Row } from 'react-bootstrap';
import { WarehouseHistoryFilters } from '../../../components/history/WarehouseHistoryFilters';
import { OperationTypeRenderer } from '../../../components/table/renderers/OperationTypeRenderer';
import { DateWithMinutesRenderer } from '../../../components/table/renderers/DateRenderer';

const columns: CustomColumn[] = [
  {
    name: 'SKU',
    field: 'stockKeepingUnit',
    className: 'col-sm-2',
  },
  {
    name: 'E-Mail',
    field: 'email',
    className: 'col-sm-2',
  },
  {
    name: 'Imie',
    field: 'firstName',
    className: 'col-sm-1',
  },
  {
    name: 'Nazwisko',
    field: 'lastName',
    className: 'col-sm-2',
  },
  {
    name: 'Typ Akcji',
    field: 'operationType',
    className: 'col-sm-2',
    renderer: OperationTypeRenderer,
  },
  {
    name: 'Data',
    field: 'creationTimestamp',
    className: 'col-sm-1',
    renderer: DateWithMinutesRenderer,
  },
  {
    name: 'Ilość przed zmianą',
    field: 'oldQuantity',
    className: 'col-sm-1',
  },
  {
    name: 'Ilość po zmianie',
    field: 'newQuantity',
    className: 'col-sm-1',
  },
];

export default function WarehouseHistory() {
  const {
    warehouseHistoryResponse,
    isLoading,
    getWarehouseHistoryRequest,
    setGetWarehouseHistoryRequest,
    handlePaginationChange,
  } = useWarehouseHistory();

  const isWarehouseHistoryEmpty =
    warehouseHistoryResponse.warehouseHistory.length === 0;

  return (
    <>
      <Row className='align-items-sm-center'>
        <Col sm='10'>
          <WarehouseHistoryFilters
            warehouseHistoryRequest={getWarehouseHistoryRequest}
            setWarehouseHistoryRequest={setGetWarehouseHistoryRequest}
          />
        </Col>
      </Row>
      {isLoading && <CenteredSpinner />}
      {!isLoading && (
        <CustomTable
          columns={columns}
          data={warehouseHistoryResponse.warehouseHistory}
          emptyListMessage='Brak wpisów w historii magazynu.'
          refetch={() =>
            setGetWarehouseHistoryRequest({ ...getWarehouseHistoryRequest })
          }
        />
      )}
      {!isWarehouseHistoryEmpty && (
        <CustomPagination
          pageNumber={warehouseHistoryResponse.page.pageNumber + 1}
          elementsPerPage={warehouseHistoryResponse.page.pageSize}
          items={warehouseHistoryResponse.warehouseHistory}
          totalPages={warehouseHistoryResponse.page.pageCount}
          onPaginationParamsChange={(pageNumber, elementsPerPage) =>
            handlePaginationChange(pageNumber, elementsPerPage)
          }
        />
      )}
    </>
  );
}
