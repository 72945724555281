import React, { Dispatch, SetStateAction } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faQrcode } from '@fortawesome/free-solid-svg-icons';
import commonStyles from '../mobile-view/Common.module.scss';

export interface MobileListFilterButtonsProps {
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  setShowScanner: Dispatch<SetStateAction<boolean>>;
}

export function MobileListFilterButtons(
  props: Readonly<MobileListFilterButtonsProps>
) {
  return (
    <Row className={'pt-2'}>
      <Col
        xs={1}
        className={'me-3'}>
        <FontAwesomeIcon
          icon={faQrcode}
          className={commonStyles.iconPrimary}
          size={'2x'}
          onClick={() => props.setShowScanner(true)}
        />
      </Col>
      <Col xs={1}>
        <FontAwesomeIcon
          icon={faFilter}
          className={commonStyles.iconPrimary}
          size={'2x'}
          onClick={() => props.setShowFilters(true)}
        />
      </Col>
    </Row>
  );
}
