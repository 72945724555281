import { createContext } from 'react';

export interface SpinnerContextProps {
  showSpinner: () => void;
  hideSpinner: () => void;
}

export const SpinnerContext = createContext<SpinnerContextProps>({
  showSpinner: () => {},
  hideSpinner: () => {},
});
