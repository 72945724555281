import { PageParameters, PageResult } from '../../data/common-model';
import { ProductModel } from '../../data/product-model';
import { usePostFetch } from '../http-client';

export interface GetProductListRequest {
  name?: string;
  stockKeepingUnit?: string;
  category?: string;
  europeanArticleNumber?: string;
  page: PageParameters;
}

export interface GetProductListResponse {
  productList: ProductModel[];
  page: PageResult;
}

export function useGetProductList(request: GetProductListRequest) {
  const { response } = usePostFetch<
    GetProductListRequest,
    GetProductListResponse
  >('/get-product-list', request);
  return { response };
}
