import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  FormGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { productsOperationTypeMappings } from '../../api/operation-type';
import moment from 'moment';
import { GetProductsHistoryRequest } from '../../api/products/get-products-history';

export interface ProductsHistoryFiltersModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  getProductsHistoryRequest: GetProductsHistoryRequest;
  setGetProductsHistoryRequest: (request: GetProductsHistoryRequest) => void;
}

export function ProductsHistoryFiltersModal(
  props: Readonly<ProductsHistoryFiltersModalProps>
) {
  const {
    getProductsHistoryRequest,
    setGetProductsHistoryRequest,
    show,
    setShow,
  } = props;

  const operationTypes = useMemo(() => productsOperationTypeMappings(), []);

  const [stockKeepingUnit, setStockKeepingUnit] = useState(
    getProductsHistoryRequest.stockKeepingUnit ?? ''
  );
  const [operationType, setOperationType] = useState(
    getProductsHistoryRequest.operationType ?? undefined
  );
  const [email, setEmail] = useState(getProductsHistoryRequest.email ?? '');
  const [dateFrom, setDateFrom] = useState(
    getProductsHistoryRequest.dateFrom ?? undefined
  );
  const [dateTo, setDateTo] = useState(
    getProductsHistoryRequest.dateTo ?? undefined
  );

  useEffect(() => {
    setStockKeepingUnit(getProductsHistoryRequest.stockKeepingUnit ?? '');
    setOperationType(getProductsHistoryRequest.operationType ?? undefined);
    setEmail(getProductsHistoryRequest.email ?? '');
    setDateFrom(getProductsHistoryRequest.dateFrom ?? undefined);
    setDateTo(getProductsHistoryRequest.dateTo ?? undefined);
  }, [getProductsHistoryRequest]);
  const onStockKeepingUnitChanged = (value: string) => {
    setStockKeepingUnit(value);
  };

  const onOperationTypeChanged = (value: string) => {
    setOperationType(value !== '' ? value : undefined);
  };

  const onEmailChange = (value: string) => {
    setEmail(value);
  };

  const onDateFromChanged = (value: string) => {
    setDateFrom(new Date(value));
  };

  const onDateToChanged = (value: string) => {
    setDateTo(new Date(value));
  };

  const onSaveClick = () => {
    setGetProductsHistoryRequest({
      stockKeepingUnit: stockKeepingUnit,
      email: email,
      operationType: operationType ?? undefined,
      dateFrom: dateFrom ?? undefined,
      dateTo: dateTo ? new Date(dateTo) : undefined,
      page: {
        ...getProductsHistoryRequest.page,
        pageNumber: 0,
      },
    });
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size={'lg'}>
      <Modal.Header>
        <Modal.Title>Filtruj historię produktów</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className={'pt-2 pb-2'}>
          <Row>
            <Col className='mb-3'>
              <FormGroup>
                <FloatingLabel
                  controlId='stockKeepingUnit'
                  label='SKU'>
                  <Form.Control
                    value={stockKeepingUnit}
                    type='text'
                    placeholder='SKU'
                    onChange={e => onStockKeepingUnitChanged(e.target.value)}
                  />
                </FloatingLabel>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className='mb-3'>
              <FloatingLabel
                controlId='operationType'
                label='Typ akcji'>
                <Form.Select
                  onChange={e => onOperationTypeChanged(e.target.value)}
                  value={operationType}>
                  <option value={''}>Wybierz Typ Akcji...</option>
                  {Array.from(operationTypes.keys()).map(key => {
                    const value = operationTypes.get(key);
                    return (
                      <option
                        key={key}
                        value={key}>
                        {value}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col className={'mb-3'}>
              <FormGroup>
                <FloatingLabel
                  controlId='email'
                  label='Email'>
                  <Form.Control
                    value={email}
                    type='text'
                    placeholder='Email'
                    onChange={e => onEmailChange(e.target.value)}
                  />
                </FloatingLabel>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col
              xs={6}
              className={'mb-3'}>
              <FormGroup>
                <FloatingLabel
                  controlId='dateFrom'
                  label='Data od'>
                  <Form.Control
                    value={
                      dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : ''
                    }
                    type='date'
                    placeholder='Data from'
                    onChange={e => onDateFromChanged(e.target.value)}
                  />
                </FloatingLabel>
              </FormGroup>
            </Col>
            <Col
              xs={6}
              className={'mb-3'}>
              <FormGroup>
                <FloatingLabel
                  controlId='dateTo'
                  label='Data do'>
                  <Form.Control
                    value={dateTo ? moment(dateTo).format('YYYY-MM-DD') : ''}
                    type='date'
                    placeholder='Data do'
                    onChange={e => onDateToChanged(e.target.value)}
                  />
                </FloatingLabel>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'outline-primary'}
          onClick={() => setShow(false)}
          className='me-auto'>
          Anuluj
        </Button>
        <Button
          variant={'primary'}
          onClick={onSaveClick}>
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
