import { GetProductListRequest } from '../../api/products/get-product-list';
import { Row } from 'react-bootstrap';
import React from 'react';
import { ActiveFilterTile } from '../ActiveFilterTile';

export interface ActiveProductFiltersProps {
  productRequest: GetProductListRequest;
  setProductRequest: (request: GetProductListRequest) => void;
}

export function ActiveProductFilters(
  props: Readonly<ActiveProductFiltersProps>
) {
  const { productRequest, setProductRequest } = props;

  return (
    <Row>
      <ActiveFilterTile
        name={'Nazwa'}
        value={productRequest.name}
        onRemove={() =>
          setProductRequest({
            ...productRequest,
            name: undefined,
          })
        }
      />
      <ActiveFilterTile
        name={'SKU'}
        value={productRequest.stockKeepingUnit}
        onRemove={() =>
          setProductRequest({
            ...productRequest,
            stockKeepingUnit: undefined,
          })
        }
      />
      <ActiveFilterTile
        name={'Kategoria'}
        value={productRequest.category}
        onRemove={() =>
          setProductRequest({
            ...productRequest,
            category: undefined,
          })
        }
      />
      <ActiveFilterTile
        name={'EAN'}
        value={productRequest.europeanArticleNumber}
        onRemove={() =>
          setProductRequest({
            ...productRequest,
            europeanArticleNumber: undefined,
          })
        }
      />
    </Row>
  );
}
