import { useAccordionButton } from 'react-bootstrap';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';
import commonStyles from '../mobile-view/Common.module.scss';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

export function AccordionToggle(
  props: Readonly<{
    eventKey: string;
    iconSize?: SizeProp;
    multipleTabs?: {
      activeTab: string;
      setActiveTab: (activeTab: string) => void;
    };
  }>
) {
  const isSelectedActiveTabAsEventKey = () => {
    return (
      props.multipleTabs && props.multipleTabs.activeTab === props.eventKey
    );
  };

  const decoratedOnClick = useAccordionButton(props.eventKey, () => {
    console.log(
      `props = ${JSON.stringify(props)} isToggled = ${isToggled} isSelectedActiveTabAsKeyEvent()=${isSelectedActiveTabAsEventKey()}`
    );
    if (props.multipleTabs) {
      props.multipleTabs.setActiveTab(
        isSelectedActiveTabAsEventKey() ? '' : props.eventKey
      );
      setIsToggled(!isSelectedActiveTabAsEventKey());
    } else {
      setIsToggled(!isToggled);
    }
    console.log(
      `props after = ${JSON.stringify(props)} isToggled = ${isToggled} isSelectedActiveTabAsKeyEvent()=${isSelectedActiveTabAsEventKey()}`
    );
  });

  const [isToggled, setIsToggled] = useState(isSelectedActiveTabAsEventKey());

  return (
    <FontAwesomeIcon
      icon={faCircleChevronDown}
      size={props.iconSize ? props.iconSize : '2x'}
      className={
        `${commonStyles.iconPrimary}` +
        (isToggled &&
        (props.multipleTabs === undefined || isSelectedActiveTabAsEventKey())
          ? ` ${commonStyles.rotateOpen}`
          : ` ${commonStyles.rotateClosed}`)
      }
      onClick={decoratedOnClick}
    />
  );
}
