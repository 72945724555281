import { useState } from 'react';
import {
  GetProductsHistoryRequest,
  useGetProductsHistory,
} from './get-products-history';

const INITIAL_PRODUCTS_HISTORY_RESPONSE = {
  productHistory: [],
  page: {
    pageNumber: 0,
    totalCount: 0,
    pageSize: 0,
    pageCount: 0,
  },
};

export function useProductsHistory() {
  const [getProductsHistoryRequest, setGetProductsHistoryRequest] =
    useState<GetProductsHistoryRequest>({
      page: {
        pageNumber: 0,
        pageSize: 10,
      },
    });

  const { response: getProductsHistoryResponse } = useGetProductsHistory(
    getProductsHistoryRequest
  );

  const productsHistoryResponse =
    getProductsHistoryResponse?.data ?? INITIAL_PRODUCTS_HISTORY_RESPONSE;

  const isLoading = getProductsHistoryResponse?.isLoading ?? false;

  const handlePaginationChange = (
    pageNumber: number,
    productsHistoryRecordsPerPage: number
  ) => {
    setGetProductsHistoryRequest({
      ...getProductsHistoryRequest,
      page: {
        pageNumber: pageNumber - 1,
        pageSize: productsHistoryRecordsPerPage,
      },
    });
  };

  return {
    productsHistoryResponse,
    isLoading,
    getProductsHistoryRequest,
    setGetProductsHistoryRequest,
    handlePaginationChange,
  };
}
