import { usePostAction } from '../http-client';

export interface GenerateProductQrCodesRequest {
  name?: string;
  stockKeepingUnit?: string;
  category?: string;
  europeanArticleNumber?: string;
}

export function useGenerateProductQrCodes() {
  const { callPost } = usePostAction<GenerateProductQrCodesRequest, Blob>(
    '/generate-product-qr-codes',
    true,
    { responseType: 'blob' }
  );

  return { callGenerateProductQrCodes: callPost };
}
