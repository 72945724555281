import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import commonStyles from '../../../../desktop-view/Common.module.scss';
import { BaseRendererType, CustomTableState } from '../../CustomTable';
import { ModuleModel } from '../../../../data/module-model';
import { SubModulesTableProps } from '../../../modules/SubModulesTable';

export function AddSubModulesActionButtonsRenderer(
  props: BaseRendererType
): JSX.Element {
  const tableState: CustomTableState = props.tableState;
  const object = tableState.object;
  const subModulesTableProps: SubModulesTableProps = tableState.externalProps;

  const handleAddElementClick = (module: ModuleModel) => {
    subModulesTableProps.setSubModuleList([
      ...subModulesTableProps.subModuleList,
      {
        id: module.id,
        name: module.name,
        stockKeepingUnit: module.stockKeepingUnit,
        category: module.category,
        variant: module.variant,
        imageId: module.imageId,
        schemaQuantity: 0,
      },
    ]);
  };

  const handleElementRemovalClick = (moduleIdToRemove: string) => {
    subModulesTableProps.setSubModuleList(
      subModulesTableProps.subModuleList.filter(
        module => module.id !== moduleIdToRemove
      )
    );
  };

  const isModuleInList = (moduleId: string) => {
    return subModulesTableProps.subModuleList.find(e => e.id === moduleId);
  };

  return (
    <>
      {!isModuleInList(object.id) && (
        <FontAwesomeIcon
          icon={faPlus}
          size={'xl'}
          className={`${commonStyles.iconPrimary} me-3`}
          onClick={() => handleAddElementClick(object)}
        />
      )}
      {isModuleInList(object.id) && (
        <FontAwesomeIcon
          icon={faMinus}
          size={'xl'}
          className={`${commonStyles.iconRed} me-3`}
          onClick={() => handleElementRemovalClick(object.id)}
        />
      )}
    </>
  );
}
