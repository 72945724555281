import { PageParameters, PageResult } from '../../data/common-model';
import { ProductHistoryModel } from '../../data/history-model';
import { usePostFetch } from '../http-client';

export interface GetProductsHistoryRequest {
  stockKeepingUnit?: string;
  email?: string;
  operationType?: string;
  dateFrom?: Date;
  dateTo?: Date;
  page: PageParameters;
}

export interface GetProductsHistoryResponse {
  productHistory: ProductHistoryModel[];
  page: PageResult;
}

export function useGetProductsHistory(request: GetProductsHistoryRequest) {
  const { response } = usePostFetch<
    GetProductsHistoryRequest,
    GetProductsHistoryResponse
  >('/get-product-history', request);
  return { response };
}
