import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo } from 'react';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { useNavigate } from 'react-router-dom';
import { NavigationPath } from '../../../data/constants';
import {
  CustomColumn,
  CustomTable,
} from '../../../components/table/CustomTable';
import { CenteredSpinner } from '../../../components/CenteredSpinner';
import CustomPagination from '../../../components/CustomPagination';
import { UserFilters } from './UserFilters';
import { useUsers } from '../../../api/users';
import { UserRoleRenderer } from '../../../components/table/renderers/UserRoleRenderer';
import { DateWithDaysRenderer } from '../../../components/table/renderers/DateRenderer';
import { UserListActionButtonsRenderer } from '../../../components/table/renderers/action-buttons/UserListActionButtonsRenderer';

const columns: CustomColumn[] = [
  {
    name: 'Imię',
    field: 'firstName',
    className: 'col-sm-2',
  },
  {
    name: 'Nazwisko',
    field: 'lastName',
    className: 'col-sm-2',
  },
  {
    name: 'E-mail',
    field: 'email',
    className: 'col-sm-2',
  },
  {
    name: 'Rola',
    field: 'role',
    renderer: UserRoleRenderer,
    className: 'col-sm-2',
  },
  {
    name: 'Data dodania',
    field: 'creationTimestamp',
    className: 'col-sm-2',
    renderer: DateWithDaysRenderer,
  },
  {
    name: '',
    field: 'actionButtons',
    className: 'col-sm-2',
    renderer: UserListActionButtonsRenderer,
  },
];

export function UsersList() {
  const navigate = useNavigate();
  const navigateToAddUser = useCallback(() => {
    navigate(NavigationPath.ADD_USER);
  }, [navigate]);
  const {
    userListResponse,
    isLoading,
    userListRequest,
    handlePaginationChange,
    setUserListRequest,
  } = useUsers();

  const isUserListEmpty = useMemo(
    () => userListResponse.usersList.length === 0,
    [userListResponse.usersList]
  );

  return (
    <>
      <Row className={'pt-4 justify-content-end'}></Row>
      <Row className='align-items-sm-center'>
        <Col sm='10'>
          <UserFilters
            userListRequest={userListRequest}
            setUserListRequest={setUserListRequest}
          />
        </Col>
        <Col sm='2'>
          <Button
            variant={'primary'}
            onClick={navigateToAddUser}
            className='w-100'>
            <FontAwesomeIcon icon={faUserPlus} />
            <span className={'m-2'}>Dodaj użytkownika</span>
          </Button>
        </Col>
      </Row>
      {isLoading && <CenteredSpinner />}
      {!isLoading && (
        <CustomTable
          columns={columns}
          data={userListResponse.usersList}
          emptyListMessage='Brak użytkowników.'
          refetch={() => setUserListRequest({ ...userListRequest })}
        />
      )}
      {!isUserListEmpty && (
        <CustomPagination
          pageNumber={userListResponse.page.pageNumber + 1}
          elementsPerPage={userListResponse.page.pageSize}
          items={userListResponse.usersList}
          totalPages={userListResponse.page.pageCount}
          onPaginationParamsChange={(pageNumber, elementsPerPage) =>
            handlePaginationChange(pageNumber, elementsPerPage)
          }
        />
      )}
    </>
  );
}
