import { usePostAction } from '../http-client';

interface DeleteElementRequest {
  id: string;
}

interface DeleteElementResponse {
  id: string;
  deleted: boolean;
}

export function useDeleteElement() {
  const { callPost } = usePostAction<
    DeleteElementRequest,
    DeleteElementResponse
  >('/delete-element');
  return { callDeleteElement: callPost };
}
