import { usePostAction } from '../http-client';

export interface CreateElementRequest {
  name: string;
  stockKeepingUnit: string;
  category: string;
  imageId: string;
  isElementSet: boolean;
  subElementList: SubElement[];
}

interface SubElement {
  id: string;
  schemaQuantity: number;
}

export function useCreateElement() {
  const { callPost } = usePostAction<CreateElementRequest, void>(
    '/create-element'
  );
  return { callCreateElement: callPost };
}
