import { usePostAction } from '../http-client';
import { ItemType } from '../../data/item-model';

interface AddCategoryRequest {
  label: string;
  itemType: ItemType;
}

interface AddCategoryResponse {
  id: string;
  label: string;
  itemType: ItemType;
}

function useAddCategory() {
  const { callPost } = usePostAction<AddCategoryRequest, AddCategoryResponse>(
    '/add-category'
  );
  return { addCategory: callPost };
}

export { useAddCategory };
export type { AddCategoryRequest };
