import { useSetTitle } from '../../context/TitleContext';
import { Tab, Tabs } from 'react-bootstrap';
import { ProductsOverview } from './overview/ProductsOverview';
import { ProductsHistory } from './history/ProductsHistory';

export function Products() {
  useSetTitle('Produkty');
  return (
    <Tabs
      mountOnEnter
      unmountOnExit
      className='border-4 fs-6 pe-0'
      justify>
      <Tab
        eventKey='products'
        title='Produkty'>
        <ProductsOverview />
      </Tab>
      <Tab
        eventKey='history'
        title='Historia'>
        <ProductsHistory />
      </Tab>
    </Tabs>
  );
}
