import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import React, { useCallback, useMemo, useState } from 'react';
import { useSetTitle } from '../../context/TitleContext';
import {
  PreviewElementSetCraftingRequest,
  usePreviewElementSetCrafting,
} from '../../api/elements/preview-element-set-crafting';
import { CenteredSpinner } from '../../components/CenteredSpinner';
import { useCraftElement } from '../../api/elements/craft-element';
import { PreviewCraftingElementSetTable } from '../../components/crafting/PreviewCraftingElementSetTable';
import { PreviewCraftingElementTable } from '../../components/crafting/PreviewCraftingElementTable';

export function CraftElementSet() {
  useSetTitle('Magazyn - Wytwórz komplet elementów');
  const [activeTab, setActiveTab] = useState('elementSets');
  const { elementId } = useParams();
  const [quantity, setQuantity] = useState(0);
  const [elementToScrollId, setElementToScrollId] = useState<
    string | undefined
  >(undefined);
  const request: PreviewElementSetCraftingRequest = useMemo(
    () => ({
      id: elementId!,
      craftQuantity: quantity,
    }),
    [elementId, quantity]
  );

  const { response: previewElementSetCraftingResponse } =
    usePreviewElementSetCrafting(request);

  const onGoToClick = useCallback(
    (id: string) => {
      setActiveTab('elements');
      setElementToScrollId(id);
    },
    [setActiveTab]
  );

  const onGoToEnd = useCallback(() => {
    setElementToScrollId(undefined);
  }, [setElementToScrollId]);

  const isCraftPossible = useMemo(() => {
    return (
      (previewElementSetCraftingResponse.data &&
        previewElementSetCraftingResponse.data.elementSet.craftedQuantity !==
          0) ||
      false
    );
  }, [previewElementSetCraftingResponse.data]);

  return (
    <>
      {previewElementSetCraftingResponse.isLoading && <CenteredSpinner />}
      {previewElementSetCraftingResponse.data && (
        <div className='d-flex flex-column h-75'>
          <Tabs
            className='border-bottom border-4 h4'
            activeKey={activeTab}
            onSelect={k => setActiveTab(k!!)}>
            <Tab
              className={'h-100 pt-5'}
              eventKey='elementSets'
              title='Komplety Elementów'>
              <div className='d-flex flex-column h-100'>
                <PreviewCraftingElementSetTable
                  elementSetList={[
                    previewElementSetCraftingResponse.data.elementSet,
                  ]}
                  isCraftingRoot={true}
                  onQuantityChange={setQuantity}
                  onGoToClick={onGoToClick}
                />
              </div>
            </Tab>
            <Tab
              className={'h-100 pt-5'}
              eventKey='elements'
              title='Elementy'>
              <PreviewCraftingElementTable
                elementList={previewElementSetCraftingResponse.data.elementList}
                elementIdToScroll={elementToScrollId}
                onGoToEnd={onGoToEnd}
              />
            </Tab>
          </Tabs>
          <div className='mt-auto pt-5'>
            <CraftElementButtons
              previewElementCraftingRequest={request}
              isCraftPossible={isCraftPossible}
            />
          </div>
        </div>
      )}
    </>
  );
}

const CraftElementButtons = (props: {
  isCraftPossible: boolean;
  previewElementCraftingRequest: PreviewElementSetCraftingRequest;
}) => {
  const navigate = useNavigate();
  const { callCraftModule } = useCraftElement();

  const handleCreateClick = () => {
    callCraftModule({
      id: props.previewElementCraftingRequest.id,
      craftQuantity: props.previewElementCraftingRequest.craftQuantity,
    }).then(() => {
      navigate(-1);
    });
  };

  return (
    <Row className='justify-content-sm-center'>
      <Col
        sm={3}
        className='d-flex justify-content-center'>
        <Button
          variant='outline-primary'
          onClick={() => {
            navigate(-1);
          }}
          className='w-50 mb-2'>
          <span className='m-2'>Anuluj</span>
        </Button>
      </Col>
      <Col
        sm={3}
        className='d-flex justify-content-center'>
        <Button
          onClick={() => handleCreateClick()}
          disabled={
            !props.isCraftPossible ||
            props.previewElementCraftingRequest.craftQuantity === 0
          }
          variant='primary'
          className='w-50 mb-2'>
          <span className='m-2'>Utwórz</span>
        </Button>
      </Col>
    </Row>
  );
};
