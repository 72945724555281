import { Tab, Tabs } from 'react-bootstrap';
import { WarehouseElements } from './elements/WarehouseElements';
import { useSetTitle } from '../../context/TitleContext';
import { WarehouseHistory } from './history/WarehouseHistory';
import { WarehouseModules } from './modules/WarehouseModules';

export const Warehouse = () => {
  useSetTitle('Magazyn');
  return (
    <Tabs
      mountOnEnter
      unmountOnExit
      className={`border-4 fs-6 pe-0`}
      justify>
      <Tab
        eventKey='elements'
        title='Elementy'>
        <WarehouseElements />
      </Tab>
      <Tab
        eventKey='modules'
        title='Moduły'>
        <WarehouseModules />
      </Tab>
      <Tab
        eventKey='history'
        title='Historia'>
        <WarehouseHistory />
      </Tab>
    </Tabs>
  );
};
