import { useNavigate } from 'react-router-dom';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import commonStyles from '../../../../desktop-view/Common.module.scss';
import { useCallback, useContext, useState } from 'react';
import { RoleFilter } from '../../../RoleFilter';
import { UserRole } from '../../../../api/auth/token-content';
import { DeleteModal } from '../../../DeleteModal';
import { BaseRendererType, CustomTableState } from '../../CustomTable';
import { NavigationPath } from '../../../../data/constants';
import { useDeleteUser } from '../../../../api/users/delete-user';
import { UserContext } from '../../../../context/UserContextProvider';

export function UserListActionButtonsRenderer(
  props: BaseRendererType
): JSX.Element {
  const tableState: CustomTableState = props.tableState;
  const navigate = useNavigate();
  const { callDeleteUser } = useDeleteUser();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [email, setEmail] = useState('');
  const userContext = useContext(UserContext);
  const handleDeleteClick = useCallback(
    (email: string) => {
      setEmail(email);
      setShowDeleteModal(true);
    },
    [setEmail, setShowDeleteModal]
  );

  const handleEditClick = useCallback(
    (userId: string) => {
      navigate(`${NavigationPath.EDIT_USER}/${userId}`);
    },
    [navigate]
  );

  return (
    <>
      <RoleFilter visibleForRoles={[UserRole.SYSTEM_ADMIN]}>
        {userContext.email !== tableState.object.email && (
          <>
            <FontAwesomeIcon
              icon={faPenToSquare}
              size={'xl'}
              className={`${commonStyles.iconPrimary} me-3`}
              onClick={() => {
                handleEditClick(tableState.object.userId);
              }}
            />
            <FontAwesomeIcon
              icon={faTrashCan}
              size={'xl'}
              className={commonStyles.iconRed}
              onClick={() => handleDeleteClick(tableState.object.email)}
            />
          </>
        )}
      </RoleFilter>

      {
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deleteSchemaId={email}
          modalTitle='Usuniecie użytkownika'
          modalContent='Czy na pewno chcesz usunąć tego użytkownika?'
          deleteFunction={async (email: string) => callDeleteUser({ email })}
          refetch={props.refetch}
        />
      }
    </>
  );
}
