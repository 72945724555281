import { debounce } from 'lodash';
import { Col, FloatingLabel, Form, FormGroup, Row } from 'react-bootstrap';
import { Category } from '../../data/category-model';
import { GetElementListRequest } from '../../api/elements/get-element-list';

export interface ElementFiltersProps {
  elementCategories: Category[];
  elementListRequest: GetElementListRequest;
  setElementListRequest: (request: GetElementListRequest) => void;
  showElementSetFilter: boolean;
  formClassName?: string;
}

export function ElementFilters(props: Readonly<ElementFiltersProps>) {
  const { elementCategories, elementListRequest, setElementListRequest } =
    props;

  const onNameChanged = debounce((name: string) => {
    setElementListRequest({
      ...elementListRequest,
      name,
      page: { ...elementListRequest.page, pageNumber: 0 },
    });
  }, 500);

  const onStockKeepingUnitChanged = debounce((stockKeepingUnit: string) => {
    setElementListRequest({
      ...elementListRequest,
      stockKeepingUnit,
      page: {
        ...elementListRequest.page,
        pageNumber: 0,
      },
    });
  }, 500);

  const onCategoryChange = (selectedCategory: string) => {
    const categoryValue =
      selectedCategory === '' ? undefined : selectedCategory;
    setElementListRequest({
      ...elementListRequest,
      category: categoryValue,
      page: {
        ...elementListRequest.page,
        pageNumber: 0,
      },
    });
  };

  const onIsElementSetChange = (isElementSet: string) => {
    const isElementSetValue =
      isElementSet === '' ? undefined : isElementSet === 'true';

    setElementListRequest({
      ...elementListRequest,
      isElementSet: isElementSetValue,
      page: {
        ...elementListRequest.page,
        pageNumber: 0,
      },
    });
  };

  return (
    <Form className={props.formClassName ?? 'pt-5 pb-5'}>
      <Row>
        <Col
          sm={3}
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='name'
              label='Nazwa'>
              <Form.Control
                type='text'
                placeholder='Nazwa'
                onChange={e => onNameChanged(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
        <Col
          sm={3}
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='stockKeepingUnit'
              label='SKU'>
              <Form.Control
                type='text'
                placeholder='Stock Keeping Unit'
                onChange={e => onStockKeepingUnitChanged(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
        <Col
          sm={3}
          className='mb-2'>
          <FloatingLabel
            controlId='category'
            label='Kategoria'>
            <Form.Select onChange={e => onCategoryChange(e.target.value)}>
              <option value={''}>Wybierz kategorię...</option>
              {elementCategories.map(category => {
                return (
                  <option
                    key={category.id}
                    value={category.label}>
                    {category.label}
                  </option>
                );
              })}
            </Form.Select>
          </FloatingLabel>
        </Col>
        {props.showElementSetFilter ? (
          <Col
            sm={3}
            className='mb-2'>
            <FloatingLabel
              controlId='isElementSet'
              label='Komplet'>
              <Form.Select onChange={e => onIsElementSetChange(e.target.value)}>
                <option value=''>Wybierz czy komplet...</option>
                <option value='true'>Tak</option>
                <option value='false'>Nie</option>
              </Form.Select>
            </FloatingLabel>
          </Col>
        ) : undefined}
      </Row>
    </Form>
  );
}
