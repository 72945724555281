import { Col, Row } from 'react-bootstrap';
import CustomPagination from '../../../components/CustomPagination';
import { CenteredSpinner } from '../../../components/CenteredSpinner';
import { ItemType } from '../../../data/item-model';
import { useCategories } from '../../../api/categories';
import {
  CustomColumn,
  CustomTable,
} from '../../../components/table/CustomTable';
import { ImageRenderer } from '../../../components/table/renderers/ImageRenderer';
import { useProducts } from '../../../api/products';
import { ProductFilters } from '../../../components/products/ProductFilters';
import { ProductOverviewActionButtonsRenderer } from '../../../components/table/renderers/action-buttons/ProductOverviewActionButtonsRenderer';

const columns: CustomColumn[] = [
  {
    name: '',
    field: 'imageId',
    className: 'col-sm-1',
    renderer: ImageRenderer,
  },
  {
    name: 'Nazwa',
    field: 'name',
    className: 'col-sm-3',
  },
  {
    name: 'SKU',
    field: 'stockKeepingUnit',
    className: 'col-sm-2',
  },
  {
    name: 'Kategoria',
    field: 'category',
    className: 'col-sm-2',
  },
  {
    name: 'EAN',
    field: 'europeanArticleNumber',
    className: 'col-sm-2',
  },
  {
    name: '',
    field: '',
    className: 'col-sm-2',
    renderer: ProductOverviewActionButtonsRenderer,
  },
];

export default function ProductsOverview() {
  const {
    productListResponse,
    isLoading,
    getProductListRequest,
    setGetProductListRequest,
    handlePaginationChange,
  } = useProducts();

  const { categoryList } = useCategories(ItemType.PRODUCT);

  const isWarehouseElementsListEmpty = () => {
    return productListResponse.productList.length === 0;
  };

  return (
    <>
      <Row className={'align-items-sm-center'}>
        <Col sm={10}>
          <ProductFilters
            productCategories={categoryList}
            productListRequest={getProductListRequest}
            setProductListRequest={setGetProductListRequest}
          />
        </Col>
      </Row>
      {isLoading && <CenteredSpinner />}
      {!isLoading && (
        <CustomTable
          columns={columns}
          data={productListResponse.productList}
          emptyListMessage='Brak produktów w magazynie.'
          refetch={() =>
            setGetProductListRequest({
              ...getProductListRequest,
            })
          }
        />
      )}

      {!isWarehouseElementsListEmpty() && (
        <CustomPagination
          pageNumber={productListResponse.page.pageNumber + 1}
          elementsPerPage={productListResponse.page.pageSize}
          items={productListResponse.productList}
          totalPages={productListResponse.page.pageCount}
          onPaginationParamsChange={(pageNumber, elementsPerPage) =>
            handlePaginationChange(pageNumber, elementsPerPage)
          }
        />
      )}
    </>
  );
}
