import { usePostAction } from '../http-client';

interface CreateProductRequest {
  name: string;
  stockKeepingUnit: string;
  category: string;
  europeanArticleNumber: string;
  imageId: string;
  moduleList: Module[];
}

interface Module {
  id: string;
  schemaQuantity: number;
}

function useCreateProduct() {
  const { callPost } = usePostAction<CreateProductRequest, void>(
    '/create-product'
  );

  return { createProduct: callPost };
}

export { useCreateProduct };
