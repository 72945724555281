import { Col, Modal, Row } from 'react-bootstrap';
import { useCategories } from '../../api/categories';
import { useElements } from '../../api/elements';
import { ItemType } from '../../data/item-model';
import { CenteredSpinner } from '../CenteredSpinner';
import CustomPagination from '../CustomPagination';
import { ElementFilters } from './ElementFilters';
import { SubElementsTableProps } from './SubElementsTable';
import { CustomColumn, CustomTable } from '../table/CustomTable';
import { ImageRenderer } from '../table/renderers/ImageRenderer';
import { AddSubElementsActionButtonsRenderer } from '../table/renderers/action-buttons/AddSubElementsActionButtonsRenderer';

const columns: CustomColumn[] = [
  {
    name: '',
    field: 'imageId',
    className: 'col-sm-2',
    renderer: ImageRenderer,
  },
  {
    name: 'Nazwa',
    field: 'name',
    className: 'col-sm-3',
  },
  {
    name: 'SKU',
    field: 'stockKeepingUnit',
    className: 'col-sm-2',
  },
  {
    name: 'Kategoria',
    field: 'category',
    className: 'col-sm-2',
  },
  {
    name: '',
    field: '',
    className: 'col-sm-3',
    renderer: AddSubElementsActionButtonsRenderer,
  },
];

export interface AddSubElementsProps {
  show: boolean;
  setShow: (show: boolean) => void;
  subElementsTableProps: SubElementsTableProps;
}

const AddSubElementsModal = (props: AddSubElementsProps) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.setShow(false)}
      size={'xl'}>
      <Modal.Header closeButton>
        <Modal.Title>Wybierz elementy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ElementsTable subElementsTableProps={props.subElementsTableProps} />
      </Modal.Body>
    </Modal>
  );
};

function ElementsTable({
  subElementsTableProps,
}: {
  subElementsTableProps: SubElementsTableProps;
}) {
  const {
    elementListResponse,
    isLoading,
    getElementListRequest,
    setGetElementListRequest,
    handlePaginationChange,
  } = useElements();

  const isElementItemType = subElementsTableProps.itemType === ItemType.ELEMENT;

  if (isElementItemType) {
    elementListResponse.elementList = elementListResponse.elementList.filter(
      e =>
        !e.isElementSet && e.id !== subElementsTableProps.modificationElementId
    );
  }

  const { categoryList } = useCategories(ItemType.ELEMENT);

  const isElementsListEmpty = () => {
    return elementListResponse.elementList?.length === 0;
  };

  return (
    <div className='m-3'>
      <Row className={'align-items-sm-center'}>
        <Col sm={10}>
          <ElementFilters
            elementCategories={categoryList}
            setElementListRequest={setGetElementListRequest}
            elementListRequest={getElementListRequest}
            showElementSetFilter={isElementItemType ? false : true}
            formClassName='pb-2'
          />
        </Col>
      </Row>
      {isLoading && <CenteredSpinner />}
      {!isLoading && (
        <CustomTable
          columns={columns}
          data={elementListResponse.elementList}
          emptyListMessage='Brak podstawowych elementów.'
          refetch={() =>
            setGetElementListRequest({
              ...getElementListRequest,
            })
          }
          externalProps={subElementsTableProps}
        />
      )}

      {!isElementsListEmpty() && (
        <CustomPagination
          pageNumber={elementListResponse.page.pageNumber + 1}
          elementsPerPage={elementListResponse.page.pageSize}
          items={elementListResponse.elementList}
          totalPages={elementListResponse.page.pageCount}
          onPaginationParamsChange={(pageNumber, elementsPerPage) =>
            handlePaginationChange(pageNumber, elementsPerPage)
          }
        />
      )}
    </div>
  );
}

export { AddSubElementsModal };
