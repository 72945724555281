import { LocalStorageRepository } from './local-storage-repository';
import {
  AuthorizationServiceConfiguration,
  AuthorizationServiceConfigurationJson,
  FetchRequestor,
} from '@openid/appauth';
import { CONFIG_LOCAL_STORAGE_KEY, OPEN_ID_ISSUER_URL } from './auth-consts';

export class ConfigService {
  private configRepository =
    new LocalStorageRepository<AuthorizationServiceConfigurationJson>(
      CONFIG_LOCAL_STORAGE_KEY
    );

  public clear() {
    this.configRepository.removeValue();
  }
  public async getConfig() {
    let config: AuthorizationServiceConfigurationJson | null =
      this.configRepository.getValue();
    if (config == null) {
      config = (await this.fetchConfigFromIssuer()).toJson();
      this.configRepository.setValue(config);
    }
    return new AuthorizationServiceConfiguration(config);
  }

  private async fetchConfigFromIssuer() {
    return AuthorizationServiceConfiguration.fetchFromIssuer(
      OPEN_ID_ISSUER_URL,
      new FetchRequestor()
    );
  }
}
