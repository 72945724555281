import { Button, Modal } from 'react-bootstrap';

export interface QuantityChangeConfirmationModalProps {
  show: boolean;
  setShow: (showModal: boolean) => void;
  title: string;
  bodyText: string;
  refetch: () => void;
  handleQuantityChange: () => void;
}

export function QuantityChangeConfirmationModal(
  props: Readonly<QuantityChangeConfirmationModalProps>
) {
  return (
    <Modal
      show={props.show}
      onHide={() => props.setShow(false)}
      size={'lg'}>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.bodyText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'outline-primary'}
          onClick={() => props.setShow(false)}
          className='w-25'>
          Nie
        </Button>
        <Button
          variant={'primary'}
          onClick={props.handleQuantityChange}
          className='w-25 ms-3'>
          Tak
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
