import { Nav } from 'react-bootstrap';
import { NavigationPath } from '../../data/constants';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChair,
  faCog,
  faScroll,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import styles from './Sidebar.module.scss';
import commonStyles from '../Common.module.scss';
import { useSelectedPath } from '../../hook/UseSelectedPath';

function Sidebar() {
  const navigate = useNavigate();
  const selectedPath = useSelectedPath();

  return (
    <Nav
      activeKey={selectedPath}
      defaultActiveKey={NavigationPath.WAREHOUSE}
      className={`${commonStyles.positionFixed} flex-column col-sm-2`}
      variant='pills'>
      <Nav.Link
        className={styles.customHover}
        onClick={() => navigate(NavigationPath.WAREHOUSE)}
        eventKey={NavigationPath.WAREHOUSE}>
        <FontAwesomeIcon
          icon={faWarehouse}
          size='lg'
          className='me-3'
          fixedWidth
        />
        <span>Magazyn</span>
      </Nav.Link>
      <Nav.Link
        className={styles.customHover}
        onClick={() => navigate(NavigationPath.PRODUCTS)}
        eventKey={NavigationPath.PRODUCTS}>
        <FontAwesomeIcon
          icon={faChair}
          size='lg'
          className='me-3'
          fixedWidth
        />
        <span>Produkty</span>
      </Nav.Link>
      <Nav.Link
        className={styles.customHover}
        onClick={() => navigate(NavigationPath.SCHEMAS)}
        eventKey={NavigationPath.SCHEMAS}>
        <FontAwesomeIcon
          icon={faScroll}
          size='lg'
          className='me-3'
          fixedWidth
        />
        <span>Schematy</span>
      </Nav.Link>
      <Nav.Link
        className={styles.customHover}
        onClick={() => navigate(NavigationPath.SETTINGS)}
        eventKey={NavigationPath.SETTINGS}>
        <FontAwesomeIcon
          icon={faCog}
          size='lg'
          className='me-3'
          fixedWidth
        />
        <span>Ustawienia</span>
      </Nav.Link>
    </Nav>
  );
}

export { Sidebar };
