import { ChangeEvent, DragEvent, useEffect, useState } from 'react';
import styles from './FileUpload.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Image, Stack } from 'react-bootstrap';
import { useUploadImage } from '../../api/image/upload-image';
import { getImageUrl } from '../../api/image/get-image';
import { CenteredSpinner } from '../CenteredSpinner';

export function FileUpload(props: FileUploadProps) {
  const { onFileUpload } = props;
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileId, setFileId] = useState<string>();
  const { response, callUploadImage } = useUploadImage();
  const borderWidth = 2;

  useEffect(() => {
    if (!response?.isLoading && response?.data) {
      setFileId(response.data.imageId);
      onFileUpload(response.data.imageId);
    }
  }, [onFileUpload, response]);

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);

    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length > 0) {
      callUploadImage(droppedFiles[0]);
    }
  };

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files!.length === 0) return;

    const selectedFile = event.target.files![0];
    callUploadImage(selectedFile);
  };

  const handleFileDelete = () => {
    setFileId(undefined);
    props.onFileDelete();
  };

  useEffect(() => {
    setFileId(props.fileId);
  }, [props.fileId]);

  return (
    <>
      {response?.isLoading && <CenteredSpinner />}
      {!response?.isLoading && fileId != null && (
        <div
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          style={{
            height: props.size,
            width: props.size,
            transform: isDragOver ? 'scale(1.1)' : 'scale(1)',
            transitionDuration: '0.3s',
            borderStyle: 'dashed',
            borderWidth: borderWidth,
            borderColor: 'var(--bs-primary)',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Image
            style={{
              maxWidth: props.size - borderWidth * 2,
              maxHeight: props.size - borderWidth * 2,
              margin: 'auto',
            }}
            src={getImageUrl(fileId)}
            fluid
          />
          <FontAwesomeIcon
            icon={faTrashCan}
            size={'xl'}
            color={'red'}
            className={styles.deleteIcon}
            onClick={() => {
              handleFileDelete();
            }}
          />
        </div>
      )}
      {!response?.isLoading && fileId == null && (
        <div
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          style={{
            height: props.size,
            width: props.size,
            transform: isDragOver ? 'scale(1.1)' : 'scale(1)',
            transitionDuration: '0.3s',
            borderColor: props.isInvalid ? 'red' : '',
          }}
          className={styles.uploadContainer}>
          <Stack
            className={'col-md-6 mx-auto'}
            gap={3}>
            <div></div>
            <div></div>
            <div></div>
            <FontAwesomeIcon
              icon={faImage}
              size={'4x'}
              color={'lightgray'}
            />
            <div style={{ textAlign: 'center', color: 'lightgray' }}>
              Przeciągnij plik tutaj <br />
              lub
              <label
                className={'link-primary'}
                htmlFor='fileInput'>
                wybierz z komputera
              </label>
              <input
                type='file'
                id='fileInput'
                accept='image/*'
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
              />
            </div>
          </Stack>
        </div>
      )}
    </>
  );
}

interface FileUploadProps {
  size: number;
  onFileUpload: (imageId: string) => void;
  onFileDelete: () => void;
  isInvalid: boolean;
  fileId?: string;
}
