import { Row } from 'react-bootstrap';
import React from 'react';
import { ActiveFilterTile } from '../ActiveFilterTile';
import { GetModuleListRequest } from '../../api/modules/get-module-list';

export interface ActiveModuleFiltersProps {
  moduleRequest: GetModuleListRequest;
  setModuleRequest: (request: GetModuleListRequest) => void;
}

export function ActiveModuleFilters(props: Readonly<ActiveModuleFiltersProps>) {
  const { moduleRequest, setModuleRequest } = props;

  return (
    <Row>
      <ActiveFilterTile
        name={'Nazwa'}
        value={moduleRequest.name}
        onRemove={() =>
          setModuleRequest({
            ...moduleRequest,
            name: undefined,
          })
        }
      />
      <ActiveFilterTile
        name={'SKU'}
        value={moduleRequest.stockKeepingUnit}
        onRemove={() =>
          setModuleRequest({
            ...moduleRequest,
            stockKeepingUnit: undefined,
          })
        }
      />
      <ActiveFilterTile
        name={'Kategoria'}
        value={moduleRequest.category}
        onRemove={() =>
          setModuleRequest({
            ...moduleRequest,
            category: undefined,
          })
        }
      />
      <ActiveFilterTile
        name={'Wariant'}
        value={moduleRequest.variant}
        onRemove={() =>
          setModuleRequest({
            ...moduleRequest,
            variant: undefined,
          })
        }
      />
    </Row>
  );
}
