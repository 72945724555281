import { useState } from 'react';
import {
  GetWarehouseHistoryRequest,
  useGetWarehouseHistory,
} from './get-warehouse-history';

const INITIAL_WAREHOUSE_HISTORY_RESPONSE = {
  warehouseHistory: [],
  page: {
    pageNumber: 0,
    totalCount: 0,
    pageSize: 0,
    pageCount: 0,
  },
};

export function useWarehouseHistory() {
  const [getWarehouseHistoryRequest, setGetWarehouseHistoryRequest] =
    useState<GetWarehouseHistoryRequest>({
      page: {
        pageNumber: 0,
        pageSize: 10,
      },
    });

  const { response: getWarehouseHistoryResponse } = useGetWarehouseHistory(
    getWarehouseHistoryRequest
  );

  const warehouseHistoryResponse =
    getWarehouseHistoryResponse?.data ?? INITIAL_WAREHOUSE_HISTORY_RESPONSE;

  const isLoading = getWarehouseHistoryResponse?.isLoading ?? false;

  const handlePaginationChange = (
    pageNumber: number,
    warehouseHistoryRecordsPerPage: number
  ) => {
    setGetWarehouseHistoryRequest({
      ...getWarehouseHistoryRequest,
      page: {
        pageNumber: pageNumber - 1,
        pageSize: warehouseHistoryRecordsPerPage,
      },
    });
  };

  return {
    warehouseHistoryResponse,
    isLoading,
    getWarehouseHistoryRequest,
    setGetWarehouseHistoryRequest,
    handlePaginationChange,
  };
}
