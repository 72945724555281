import { Table } from 'react-bootstrap';
import { EmptyListMessage } from '../EmptyListMessage';
import { useState } from 'react';
import commonStyles from '../../desktop-view/Common.module.scss';

export interface CustomColumn {
  name: string;
  field: string;
  className: string;
  prepareInitialState?: any;
  renderer?: (props: BaseRendererType) => JSX.Element;
}

export interface BaseRendererType {
  field: string;
  refetch: () => void;
  cellStates: { [field: string]: any };
  tableState: CustomTableState;
}

export interface CustomTableParameters<T = any> {
  columns: Array<CustomColumn>;
  data: Array<T>;
  emptyListMessage: string;
  refetch: () => void;
  externalProps?: any;
}

export interface CustomTableState {
  object: any;
  setObject: (object: any) => void;
  externalProps: any;
}

export function CustomHeader({ columns }: { columns: Array<CustomColumn> }) {
  return (
    <thead>
      <tr className={`${commonStyles.tableHeader} row`}>
        {columns.map(col => (
          <th className={col.className}>{col.name}</th>
        ))}
      </tr>
    </thead>
  );
}

export function CustomTable(tableParam: CustomTableParameters) {
  const isEmptyObjectList = () => {
    return tableParam.data.length === 0;
  };

  return (
    <Table>
      <CustomHeader columns={tableParam.columns} />
      {!isEmptyObjectList() && (
        <tbody>
          {tableParam.data?.map(object => {
            return (
              <TableRow
                key={object.id}
                object={object}
                columns={tableParam.columns}
                refetch={tableParam.refetch}
                externalProps={tableParam.externalProps}
              />
            );
          })}
        </tbody>
      )}
      {isEmptyObjectList() && (
        <EmptyListMessage message={tableParam.emptyListMessage} />
      )}
    </Table>
  );
}

function TableRow(
  props: Readonly<{
    object: any;
    columns: Array<CustomColumn>;
    refetch: () => void;
    externalProps?: any;
  }>
) {
  const [element, setElement] = useState(props.object);

  const cellStates: { [field: string]: any } = {};
  props.columns.forEach(col => {
    if (col.prepareInitialState) {
      const initialStateParam = props.object[col.field];
      cellStates[col.field] = initialStateParam
        ? col.prepareInitialState(initialStateParam)
        : col.prepareInitialState();
    }
  });

  return (
    <tr
      key={element.id}
      className={`${commonStyles.tableRow} row align-items-sm-center`}>
      {props.columns.map(col => (
        <td className={col.className}>
          {col.renderer
            ? col.renderer({
                field: col.field,
                refetch: props.refetch,
                cellStates: cellStates,
                tableState: {
                  object: element,
                  setObject: setElement,
                  externalProps: props.externalProps,
                },
              })
            : props.object[col.field]}
        </td>
      ))}
    </tr>
  );
}
