import { NavigationPath, QueryParams } from '../../../data/constants';
import { useDeleteElement } from '../../../api/elements/delete-element';
import { useDeleteModule } from '../../../api/modules/delete-module';
import { useDeleteProduct } from '../../../api/modules/delete-product';

export interface SchemaActionButtonsState {
  callDeleteAction: (request: any) => any;
  editActionNavigationPath: NavigationPath;
  addActionNavigationPath?: NavigationPath;
  addActionQueryParams?: QueryParams;
  deleteModalTitle: string;
  deleteModalBodyText: string;
}

export function useSchemaActionButtonsState(props: {
  callDeleteAction: (request: any) => any;
  editActionNavigationPath: NavigationPath;
  addActionNavigationPath?: NavigationPath;
  addActionQueryParams?: QueryParams;
  modalTitleSuffix: string;
  modalBodyTextExtension: string;
}): SchemaActionButtonsState {
  return {
    callDeleteAction: props.callDeleteAction,
    editActionNavigationPath: props.editActionNavigationPath,
    addActionNavigationPath: props.addActionNavigationPath,
    addActionQueryParams: props.addActionQueryParams,
    deleteModalTitle: `Usunięcie ${props.modalTitleSuffix}`,
    deleteModalBodyText: `Czy na pewno chcesz usunąć ten ${props.modalBodyTextExtension}?`,
  };
}

export function useSchemaElementActionButtonsState(): SchemaActionButtonsState {
  const { callDeleteElement } = useDeleteElement();

  return useSchemaActionButtonsState({
    callDeleteAction: callDeleteElement,
    editActionNavigationPath: NavigationPath.EDIT_ELEMENT_SCHEMA,
    modalTitleSuffix: 'elementu',
    modalBodyTextExtension: 'element',
  });
}

export function useSchemaModuleActionButtonsState(): SchemaActionButtonsState {
  const { callDeleteModule } = useDeleteModule();

  return useSchemaActionButtonsState({
    callDeleteAction: callDeleteModule,
    editActionNavigationPath: NavigationPath.EDIT_MODULE_SCHEMA,
    addActionNavigationPath: NavigationPath.ADD_MODULE_SCHEMA,
    addActionQueryParams: QueryParams.BASE_MODULE_ID,
    modalTitleSuffix: 'modułu',
    modalBodyTextExtension: 'moduł',
  });
}

export function useSchemaProductActionButtonsState(): SchemaActionButtonsState {
  const { callDeleteModule: callDeleteProduct } = useDeleteProduct();

  return useSchemaActionButtonsState({
    callDeleteAction: callDeleteProduct,
    editActionNavigationPath: NavigationPath.EDIT_PRODUCT_SCHEMA,
    modalTitleSuffix: 'produktu',
    modalBodyTextExtension: 'produkt',
  });
}
