import { Tab, Tabs } from 'react-bootstrap';
import ElementSchemas from './elements/ElementSchemas';
import { useSetTitle } from '../../context/TitleContext';
import ModuleSchemas from './modules/ModuleSchemas';
import ProductSchemas from './products/ProductSchemas';

function Schemas() {
  useSetTitle('Schematy');
  return (
    <Tabs
      mountOnEnter
      unmountOnExit
      className='border-bottom border-4 h4'>
      <Tab
        eventKey='elements'
        title='Elementy'>
        <ElementSchemas />
      </Tab>
      <Tab
        eventKey='modules'
        title='Moduły'>
        <ModuleSchemas />
      </Tab>
      <Tab
        eventKey='products'
        title='Produkty'>
        <ProductSchemas />
      </Tab>
    </Tabs>
  );
}

export { Schemas };
