import { useProducts } from '../../../api/products';
import React, { useState } from 'react';
import { useCategories } from '../../../api/categories';
import { ItemType } from '../../../data/item-model';
import { MobileListFilterButtons } from '../../../components/MobileListFilterButtons';
import { ActiveProductFilters } from '../../../components/products/ActiveProductFilters';
import styles from '../../warehouse/Warehouse.module.scss';
import { CenteredSpinner } from '../../../components/CenteredSpinner';
import { Accordion, Col, Image, Row } from 'react-bootstrap';
import { ProductModel } from '../../../data/product-model';
import { getThumbnailUrl } from '../../../api/image/get-image';
import commonStyles from '../../Common.module.scss';
import { ImageZoomModal } from '../../../components/ImageZoomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { EmptyListMessage } from '../../../components/EmptyListMessage';
import { QrCodeScanner } from '../../../components/QrCodeScanner';
import CustomPagination from '../../../components/CustomPagination';
import { ProductFiltersModal } from '../../../components/products/ProductFiltersModal';
import { AccordionToggle } from '../../../components/AccordionToggle';
import { NavigationPath } from '../../../data/constants';
import { useNavigate } from 'react-router-dom';

export function ProductsOverview() {
  const {
    productListResponse,
    isLoading,
    getProductListRequest,
    setGetProductListRequest,
    handlePaginationChange,
  } = useProducts();

  const [showFilters, setShowFilters] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const { categoryList } = useCategories(ItemType.PRODUCT);

  const isProductListEmpty = productListResponse?.productList.length === 0;

  const filterBySKU = (stockKeepingUnit: string) => {
    setShowScanner(false);
    setGetProductListRequest({
      ...getProductListRequest,
      stockKeepingUnit,
    });
  };

  return (
    <>
      {!showScanner && (
        <>
          <MobileListFilterButtons
            setShowFilters={setShowFilters}
            setShowScanner={setShowScanner}
          />
          <ActiveProductFilters
            productRequest={getProductListRequest}
            setProductRequest={setGetProductListRequest}
          />
          {isLoading && (
            <div className={styles.list}>
              <CenteredSpinner />
            </div>
          )}
          {!isLoading && !isProductListEmpty && (
            <div className={styles.list}>
              <Accordion alwaysOpen>
                {productListResponse?.productList.map((product, index) => (
                  <ProductRow
                    key={product.id}
                    product={product}
                    index={index}
                  />
                ))}
              </Accordion>
            </div>
          )}
          {!isLoading && isProductListEmpty && (
            <EmptyListMessage message={'Brak produktów w systemie.'} />
          )}
          {!isProductListEmpty && (
            <CustomPagination
              pageNumber={productListResponse.page.pageNumber + 1}
              elementsPerPage={productListResponse.page.pageSize}
              items={productListResponse.productList}
              totalPages={productListResponse.page.pageCount}
              onPaginationParamsChange={(pageNumber, elementsPerPage) =>
                handlePaginationChange(pageNumber, elementsPerPage)
              }
              disablePageSizeChange
            />
          )}
          <ProductFiltersModal
            show={showFilters}
            setShow={setShowFilters}
            productCategories={categoryList}
            productListRequest={getProductListRequest}
            setProductListRequest={setGetProductListRequest}
          />
        </>
      )}
      {showScanner && (
        <QrCodeScanner
          onClose={() => setShowScanner(false)}
          onDecode={text => filterBySKU(text)}
        />
      )}
    </>
  );
}

interface ProductRowProps {
  product: ProductModel;
  index: number;
}

function ProductRow(props: ProductRowProps) {
  const { product, index } = props;

  const [showImageModal, setShowImageModal] = useState(false);
  const navigate = useNavigate();

  const handleCraftProductClick = (productId: string) => {
    navigate(`${NavigationPath.CRAFT_PRODUCT}/${productId}`);
  };

  return (
    <Row className={'border-bottom border-1 pe-3 pt-3 pb-3'}>
      <Col xs={3}>
        <Image
          src={getThumbnailUrl(product.imageId)}
          fluid
          className={`${commonStyles.thumbnailImage}`}
          onClick={() => setShowImageModal(true)}
        />
        <ImageZoomModal
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
          imageId={product.imageId}
        />
      </Col>
      <Col xs={8}>
        <div>{product.name}</div>
        <div className={commonStyles.secondaryText}>
          {product.stockKeepingUnit}
        </div>
      </Col>
      <Col
        xs={1}
        className={'d-flex justify-content-center'}>
        <AccordionToggle eventKey={index.toString()} />
      </Col>
      <Accordion.Collapse eventKey={index.toString()}>
        <>
          <Row className={'mt-3'}>
            <Col xs={5}>
              <div className={commonStyles.secondaryText}>Nazwa</div>
              <div>{product.name}</div>
            </Col>
            <Col xs={5}>
              <div className={commonStyles.secondaryText}>SKU</div>
              <div>{product.stockKeepingUnit}</div>
            </Col>
          </Row>
          <Row className={'mt-3'}>
            <Col xs={5}>
              <div className={commonStyles.secondaryText}>Kategoria</div>
              <div>{product.category}</div>
            </Col>
            <Col xs={6}>
              <div className={commonStyles.secondaryText}>EAN</div>
              <div>{product.europeanArticleNumber}</div>
            </Col>
            <Col
              xs={1}
              className={'d-flex justify-content-center'}>
              <FontAwesomeIcon
                icon={faPlus}
                size={'2x'}
                className={`${commonStyles.iconPrimary}`}
                onClick={() => handleCraftProductClick(product.id)}
              />
            </Col>
          </Row>
        </>
      </Accordion.Collapse>
    </Row>
  );
}
