import { GetUserListRequest } from '../../../api/users/get-user-list';
import { debounce } from 'lodash';
import React, { useMemo } from 'react';
import { Col, FloatingLabel, Form, FormGroup, Row } from 'react-bootstrap';

export function UserFilters(props: {
  userListRequest: GetUserListRequest;
  setUserListRequest: (request: GetUserListRequest) => void;
}) {
  const { userListRequest, setUserListRequest } = props;

  const onFirstNameChange = useMemo(
    () =>
      debounce((firstName: string) => {
        setUserListRequest({
          ...userListRequest,
          firstName: firstName,
          page: { ...userListRequest.page, pageNumber: 0 },
        });
      }, 500),
    [userListRequest, setUserListRequest]
  );

  const onLastNameChange = useMemo(
    () =>
      debounce((lastName: string) => {
        setUserListRequest({
          ...userListRequest,
          lastName: lastName,
          page: { ...userListRequest.page, pageNumber: 0 },
        });
      }, 500),
    [userListRequest, setUserListRequest]
  );

  const onEmailChange = useMemo(
    () =>
      debounce((email: string) => {
        setUserListRequest({
          ...userListRequest,
          email: email,
          page: { ...userListRequest.page, pageNumber: 0 },
        });
      }, 500),
    [userListRequest, setUserListRequest]
  );

  return (
    <Form className='pt-5 pb-5'>
      <Row>
        <Col
          sm='3'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='firstName'
              label='Imię'>
              <Form.Control
                type='text'
                placeholder='Imię'
                onChange={e => onFirstNameChange(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
        <Col
          sm='3'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='lastName'
              label='Nazwisko'>
              <Form.Control
                type='text'
                placeholder='Nazwisko'
                onChange={e => onLastNameChange(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
        <Col
          sm='3'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='email'
              label='E-Mail'>
              <Form.Control
                type='text'
                placeholder='E-Mail'
                onChange={e => onEmailChange(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}
