import { Col, Row } from 'react-bootstrap';
import CustomPagination from '../../../components/CustomPagination';
import { ElementFilters } from '../../../components/elements/ElementFilters';
import { CenteredSpinner } from '../../../components/CenteredSpinner';
import { ItemType } from '../../../data/item-model';
import { useCategories } from '../../../api/categories';
import { useElements } from '../../../api/elements';
import {
  CustomColumn,
  CustomTable,
} from '../../../components/table/CustomTable';
import { ImageRenderer } from '../../../components/table/renderers/ImageRenderer';
import { ElementSetRenderer } from '../../../components/table/renderers/ElementSetRenderer';
import { WarehouseQuantityRenderer } from '../../../components/table/renderers/WarehouseQuantityRenderer';
import { useWarehouseElementQuantityState } from '../../../components/table/field-states/WarehouseQuantityState';
import { WarehouseElementActionButtonsRenderer } from '../../../components/table/renderers/action-buttons/WarehouseActionButtonsRenderer';

const columns: CustomColumn[] = [
  {
    name: '',
    field: 'imageId',
    className: 'col-sm-1',
    renderer: ImageRenderer,
  },
  {
    name: 'Nazwa',
    field: 'name',
    className: 'col-sm-2',
  },
  {
    name: 'SKU',
    field: 'stockKeepingUnit',
    className: 'col-sm-2',
  },
  {
    name: 'Kategoria',
    field: 'category',
    className: 'col-sm-2',
  },
  {
    name: 'Komplet',
    field: 'isElementSet',
    className: 'col-sm-2',
    renderer: ElementSetRenderer,
  },
  {
    name: 'Ilość',
    field: 'warehouseQuantity',
    className: 'col-sm-1',
    renderer: WarehouseQuantityRenderer,
    prepareInitialState: useWarehouseElementQuantityState,
  },
  {
    name: '',
    field: '',
    className: 'col-sm-2',
    renderer: WarehouseElementActionButtonsRenderer,
  },
];

export default function WarehouseElements() {
  const {
    elementListResponse,
    isLoading,
    getElementListRequest,
    setGetElementListRequest,
    handlePaginationChange,
  } = useElements();

  const { categoryList } = useCategories(ItemType.ELEMENT);

  const isWarehouseElementsListEmpty = () => {
    return elementListResponse.elementList?.length === 0;
  };

  return (
    <>
      <Row className={'align-items-sm-center'}>
        <Col sm={10}>
          <ElementFilters
            elementCategories={categoryList}
            setElementListRequest={setGetElementListRequest}
            elementListRequest={getElementListRequest}
            showElementSetFilter={true}
          />
        </Col>
      </Row>
      {isLoading && <CenteredSpinner />}
      {!isLoading && (
        <CustomTable
          columns={columns}
          data={elementListResponse.elementList}
          emptyListMessage='Brak elementów w magazynie.'
          refetch={() =>
            setGetElementListRequest({
              ...getElementListRequest,
            })
          }
        />
      )}

      {!isWarehouseElementsListEmpty() && (
        <CustomPagination
          pageNumber={elementListResponse.page.pageNumber + 1}
          elementsPerPage={elementListResponse.page.pageSize}
          items={elementListResponse.elementList}
          totalPages={elementListResponse.page.pageCount}
          onPaginationParamsChange={(pageNumber, elementsPerPage) =>
            handlePaginationChange(pageNumber, elementsPerPage)
          }
        />
      )}
    </>
  );
}
