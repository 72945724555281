export class LocalStorageRepository<T> {
  constructor(private key: string) {}

  public getValue(): T | null {
    const saved = localStorage.getItem(this.key);
    return saved ? JSON.parse(saved) : null;
  }

  public setValue(value: T) {
    if (value) {
      localStorage.setItem(this.key, JSON.stringify(value));
    }
  }

  public removeValue() {
    const value = this.getValue();
    localStorage.removeItem(this.key);
    return value;
  }
}
