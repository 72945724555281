import { Col, FloatingLabel, Form, FormGroup, Row } from 'react-bootstrap';
import React, { useMemo } from 'react';
import { debounce } from 'lodash';
import { productsOperationTypeMappings } from '../../api/operation-type';
import { GetProductsHistoryRequest } from '../../api/products/get-products-history';

export interface ProductsHistoryFiltersProps {
  productsHistoryRequest: GetProductsHistoryRequest;
  setProductsHistoryRequest: (request: GetProductsHistoryRequest) => void;
}

export function ProductsHistoryFilters(
  props: Readonly<ProductsHistoryFiltersProps>
) {
  const { setProductsHistoryRequest, productsHistoryRequest } = props;

  const operationTypes = useMemo(() => productsOperationTypeMappings(), []);

  const onStockKeepingUnitChanged = debounce((stockKeepingUnit: string) => {
    setProductsHistoryRequest({
      ...productsHistoryRequest,
      stockKeepingUnit,
      page: {
        ...productsHistoryRequest.page,
        pageNumber: 0,
      },
    });
  }, 500);

  const onEmailChanged = debounce((email: string) => {
    setProductsHistoryRequest({
      ...productsHistoryRequest,
      email,
      page: { ...productsHistoryRequest.page, pageNumber: 0 },
    });
  }, 500);

  const onOperationTypeChanged = debounce((operationType: string) => {
    setProductsHistoryRequest({
      ...productsHistoryRequest,
      operationType: operationType !== '' ? operationType : undefined,
      page: { ...productsHistoryRequest.page, pageNumber: 0 },
    });
  }, 500);

  const onDateFromChanged = debounce((dateFrom: string) => {
    setProductsHistoryRequest({
      ...productsHistoryRequest,
      dateFrom: new Date(dateFrom),
      page: { ...productsHistoryRequest.page, pageNumber: 0 },
    });
  }, 500);

  const onDateToChanged = debounce((dateTo: string) => {
    setProductsHistoryRequest({
      ...productsHistoryRequest,
      dateTo: new Date(dateTo),
      page: { ...productsHistoryRequest.page, pageNumber: 0 },
    });
  }, 500);

  return (
    <Form className='pt-5 pb-5'>
      <Row>
        <Col
          sm='2'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='stockKeepingUnit'
              label='SKU'>
              <Form.Control
                type='text'
                placeholder='Stock Keeping Unit'
                onChange={e => onStockKeepingUnitChanged(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
        <Col
          sm='3'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='email'
              label='E-Mail'>
              <Form.Control
                type='text'
                placeholder='E-Mail'
                onChange={e => onEmailChanged(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
        <Col
          sm='3'
          className='mb-2'>
          <FloatingLabel
            controlId='operationType'
            label='Typ akcji'>
            <Form.Select onChange={e => onOperationTypeChanged(e.target.value)}>
              <option value={''}>Wybierz Typ Akcji...</option>
              {Array.from(operationTypes.keys()).map(key => {
                const value = operationTypes.get(key);
                return (
                  <option
                    key={key}
                    value={key}>
                    {value}
                  </option>
                );
              })}
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Col
          sm='2'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='dateFrom'
              label='Data od'>
              <Form.Control
                type='date'
                placeholder='Data od'
                onChange={e => onDateFromChanged(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
        <Col
          sm='2'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='dateTo'
              label='Data do'>
              <Form.Control
                type='date'
                placeholder='Data do'
                onChange={e => onDateToChanged(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}
