import { useState } from 'react';
import { GetUserListRequest, useGetUserList } from './users/get-user-list';

const INITIAL_USERS_LIST_RESPONSE = {
  usersList: [],
  page: {
    pageNumber: 0,
    totalCount: 0,
    pageSize: 0,
    pageCount: 0,
  },
};

export function useUsers() {
  const [userListRequest, setUserListRequest] = useState<GetUserListRequest>({
    page: {
      pageNumber: 0,
      pageSize: 10,
    },
  });

  const { response: getUserListResponse } = useGetUserList(userListRequest);

  const userListResponse =
    getUserListResponse?.data ?? INITIAL_USERS_LIST_RESPONSE;

  const isLoading = getUserListResponse?.isLoading ?? false;

  const handlePaginationChange = (
    pageNumber: number,
    modulesPerPage: number
  ) => {
    setUserListRequest({
      ...userListRequest,
      page: {
        pageNumber: pageNumber - 1,
        pageSize: modulesPerPage,
      },
    });
  };

  return {
    userListResponse: userListResponse,
    isLoading,
    userListRequest: userListRequest,
    setUserListRequest: setUserListRequest,
    handlePaginationChange,
  };
}
