import { Spinner } from 'react-bootstrap';
import React from 'react';

export const CenteredSpinner = () => {
  return (
    <div className={'d-flex justify-content-sm-center justify-content-center'}>
      <Spinner
        animation={'border'}
        className={'mt-5'}
      />
    </div>
  );
};
