import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Accordion, Col, Form, Image, Row } from 'react-bootstrap';
import styles from '../Crafting.module.scss';
import { debounce } from 'lodash';
import { getThumbnailUrl } from '../../../api/image/get-image';
import commonStyles from '../../../mobile-view/Common.module.scss';
import { ImageZoomModal } from '../../ImageZoomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import {
  ModuleCraftingPreview,
  ModuleSubItemCraftingPreview,
} from '../../../api/elements/preview-module-crafting';
import { AccordionToggle } from '../../AccordionToggle';
import { CraftingMobileItemHeader } from './generic/CraftingMobileItemHeader';
import { useImageModal } from '../../../hook/useImageModal';

export const PreviewCraftingModuleMobileTable = (props: {
  moduleList: ModuleCraftingPreview[];
  onGoToClick: (id: string, activeTab: string) => void;
  onQuantityChange?: (quantity: number) => void;
  isCraftingRoot: boolean;
  elementIdToScroll?: string;
  onGoToEnd?: () => void;
}) => {
  const {
    moduleList,
    onGoToClick,
    onQuantityChange,
    isCraftingRoot,
    elementIdToScroll,
    onGoToEnd,
  } = props;

  const refToScroll = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (elementIdToScroll && refToScroll.current) {
      refToScroll.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [elementIdToScroll]);

  return (
    <>
      {isCraftingRoot && moduleList.length === 1 ? (
        <Accordion alwaysOpen>
          <ModuleCraftingRoot
            modulePreview={moduleList[0]}
            onGoToClick={onGoToClick}
            onQuantityChange={onQuantityChange!!}
          />
        </Accordion>
      ) : (
        moduleList.map(elementSet => {
          if (elementSet.id === elementIdToScroll) {
            return (
              <Accordion alwaysOpen>
                <ModuleRow
                  ref={refToScroll}
                  modulePreview={elementSet}
                  onGoToClick={onGoToClick}
                  onGoToEnd={onGoToEnd}
                />
              </Accordion>
            );
          } else {
            return (
              <Accordion alwaysOpen>
                <ModuleRow
                  modulePreview={elementSet}
                  onGoToClick={onGoToClick}
                />
              </Accordion>
            );
          }
        })
      )}
    </>
  );
};

interface ModuleCraftingRootProps {
  modulePreview: ModuleCraftingPreview;
  onGoToClick: (id: string, activeTab: string) => void;
  onQuantityChange: (quantity: number) => void;
}

const ModuleCraftingRoot = (props: ModuleCraftingRootProps) => {
  const { modulePreview, onGoToClick, onQuantityChange } = props;

  const { showImageModal, setShowImageModal, selectedImage, handleImageClick } =
    useImageModal();
  const [quantity, setQuantity] = useState<number>(
    modulePreview.requiredQuantity
  );
  const setCraftQuantityDebounced = useMemo(() => {
    return debounce(onQuantityChange, 500);
  }, [onQuantityChange]);

  const handleCraftQuantityChange = useCallback(
    (event: any) => {
      const newCraftQuantity =
        event.target.value !== '' && event.target.validity.valid
          ? parseInt(event.target.value)
          : 0;
      setQuantity(newCraftQuantity);
    },
    [setQuantity]
  );

  useEffect(() => {
    setCraftQuantityDebounced(quantity);
  }, [quantity, setCraftQuantityDebounced]);

  return (
    <>
      <Row>
        <CraftingMobileItemHeader />
        <div className={styles.mobileVerticalLineDiv} />
        <Row
          className={`pb-2 px-0 border-bottom border-1 ${styles.mobileCraftingRowContentDiv}`}>
          <Col
            xs={2}
            className={'p-0'}>
            <Image
              src={getThumbnailUrl(modulePreview.imageId)}
              fluid
              className={`${commonStyles.thumbnailSmallImage}`}
              onClick={() => handleImageClick(modulePreview.imageId)}
            />
            <ImageZoomModal
              showImageModal={showImageModal}
              setShowImageModal={setShowImageModal}
              imageId={selectedImage}
            />
          </Col>
          <Col
            xs={3}
            className={'p-0'}>
            <div className={commonStyles.smallBoldText}>
              {modulePreview.name}
            </div>
            <div className={commonStyles.smallText}>
              {modulePreview.stockKeepingUnit}
            </div>
          </Col>
          <Col xs={2}>{modulePreview.oldQuantity}</Col>
          <Col xs={2}>
            <Form.Control
              className={'w-100  p-0'}
              type='number'
              defaultValue={quantity}
              min='0'
              onChange={handleCraftQuantityChange}
            />
          </Col>
          <Col xs={2}>{modulePreview.newQuantity}</Col>
          <Col
            xs={1}
            className={'d-flex justify-content-center'}>
            <AccordionToggle
              eventKey={modulePreview.id}
              iconSize='lg'
            />
          </Col>
          <Accordion.Collapse
            className={'ps-0'}
            eventKey={modulePreview.id}>
            <>
              <Row className={'mt-3'}>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>Nazwa</div>
                  <div>{modulePreview.name}</div>
                </Col>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>SKU</div>
                  <div>{modulePreview.stockKeepingUnit}</div>
                </Col>
              </Row>
              <Row className={'mt-3 pe-2'}>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Kategoria</div>
                  <div>{modulePreview.category}</div>
                </Col>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Komplet</div>
                  <div>Tak</div>
                </Col>
                <Col xs={4}>
                  <div className={commonStyles.secondaryText}>W magazynie</div>
                  <div>{modulePreview.oldQuantity}</div>
                </Col>
              </Row>
            </>
          </Accordion.Collapse>
        </Row>
      </Row>
      {modulePreview.subiItemList.map(it => (
        <Accordion alwaysOpen>
          <SubItemRow
            subItem={it}
            handleGoToButtonClick={onGoToClick}
          />
        </Accordion>
      ))}
    </>
  );
};

const ModuleRow = React.forwardRef<
  HTMLDivElement,
  {
    modulePreview: ModuleCraftingPreview;
    onGoToClick: (id: string, activeTab: string) => void;
    onGoToEnd?: (() => void) | undefined;
  }
>((props, ref) => {
  const { modulePreview, onGoToClick, onGoToEnd } = props;

  const { showImageModal, setShowImageModal } = useImageModal();

  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (ref) {
      setIsHighlighted(true);
      setTimeout(() => {
        setIsHighlighted(false);
        onGoToEnd!!();
      }, 1000); // Remove highlight after 1 second
    }
  }, [ref, onGoToEnd]);

  return (
    <>
      <Row
        ref={ref}
        className={`${isHighlighted ? styles.highlight : ''}`}>
        <CraftingMobileItemHeader />
        <div className={styles.mobileVerticalLineDiv} />
        <Row
          className={`pb-2 px-0 align-items-center border-bottom border-1 ${styles.mobileCraftingRowContentDiv}`}>
          <Col
            xs={2}
            className={'p-0'}>
            <Image
              src={getThumbnailUrl(modulePreview.imageId)}
              fluid
              className={`${commonStyles.thumbnailSmallImage}`}
              onClick={() => setShowImageModal(true)}
            />
            <ImageZoomModal
              showImageModal={showImageModal}
              setShowImageModal={setShowImageModal}
              imageId={modulePreview.imageId}
            />
          </Col>
          <Col
            xs={3}
            className={'p-0'}>
            <div className={commonStyles.smallBoldText}>
              {modulePreview.name}
            </div>
            <div className={commonStyles.smallText}>
              {modulePreview.stockKeepingUnit}
            </div>
          </Col>
          <Col xs={2}>
            {modulePreview.oldQuantity}{' '}
            {modulePreview.craftedQuantity !== 0 && (
              <label className={styles.greenText}>
                +{modulePreview.craftedQuantity}
              </label>
            )}
          </Col>
          <Col xs={2}>{modulePreview.requiredQuantity}</Col>
          <Col xs={2}>
            <div
              className={
                modulePreview.newQuantity >= 0
                  ? styles.greenText
                  : styles.redText
              }>
              {modulePreview.newQuantity}
            </div>
          </Col>
          <Col
            xs={1}
            className={'d-flex justify-content-center'}>
            <AccordionToggle
              eventKey={modulePreview.id}
              iconSize='lg'
            />
          </Col>
          <Accordion.Collapse
            className={'ps-0'}
            eventKey={modulePreview.id}>
            <>
              <Row className={'mt-3'}>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>Nazwa</div>
                  <div>{modulePreview.name}</div>
                </Col>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>SKU</div>
                  <div>{modulePreview.stockKeepingUnit}</div>
                </Col>
              </Row>
              <Row className={'mt-3 pe-2'}>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Kategoria</div>
                  <div>{modulePreview.category}</div>
                </Col>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Komplet</div>
                  <div>Tak</div>
                </Col>
                <Col xs={4}>
                  <div className={commonStyles.secondaryText}>W magazynie</div>
                  <div>{modulePreview.oldQuantity}</div>
                </Col>
                <Col xs={2} />
              </Row>
            </>
          </Accordion.Collapse>
        </Row>
      </Row>
      {modulePreview.subiItemList.map(it => (
        <SubItemRow
          subItem={it}
          handleGoToButtonClick={onGoToClick}
        />
      ))}
    </>
  );
});

const SubItemRow = (props: {
  subItem: ModuleSubItemCraftingPreview;
  handleGoToButtonClick: (id: string, activeTab: string) => void;
}) => {
  const { subItem, handleGoToButtonClick } = props;
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setShowImageModal(true);
  };

  return (
    <>
      <Row>
        <div className={styles.mobileVerticalLineDiv}>
          <div className={`vr ${styles.verticalLine}`}></div>
        </div>
        <div className={styles.mobileCraftingRowContentDiv}>
          <Row
            className={'pt-2 pb-2 align-items-center'}
            key={subItem.stockKeepingUnit}>
            <Col
              xs={2}
              className={'p-0'}>
              <Image
                src={getThumbnailUrl(subItem.imageId)}
                fluid
                className={commonStyles.thumbnailSmallImage}
                onClick={() => handleImageClick(subItem.imageId)}
              />
              <ImageZoomModal
                showImageModal={showImageModal}
                setShowImageModal={setShowImageModal}
                imageId={selectedImage}
              />
            </Col>
            <Col
              xs={3}
              className={'p-0'}>
              <div className={commonStyles.smallBoldText}>{subItem.name}</div>
              <div className={commonStyles.smallText}>
                {subItem.stockKeepingUnit}
              </div>
            </Col>
            <Col xs={2}>
              {subItem.oldQuantity}{' '}
              {subItem.craftedQuantity !== 0 && (
                <label className={styles.greenText}>
                  +{subItem.craftedQuantity}
                </label>
              )}
            </Col>
            <Col xs={2}>{subItem.requiredQuantity}</Col>
            <Col
              xs={2}
              className={
                subItem.newQuantity >= 0 ? styles.greenText : styles.redText
              }>
              {subItem.newQuantity}
            </Col>
            <Col
              xs={1}
              className={'d-flex justify-content-center'}>
              <AccordionToggle
                eventKey={subItem.id}
                iconSize='lg'
              />
            </Col>
            <Accordion.Collapse
              className={'ps-0'}
              eventKey={subItem.id}>
              <>
                <Row className={'mt-3'}>
                  <Col xs={5}>
                    <div className={commonStyles.secondaryText}>Nazwa</div>
                    <div>{subItem.name}</div>
                  </Col>
                  <Col xs={5}>
                    <div className={commonStyles.secondaryText}>SKU</div>
                    <div>{subItem.stockKeepingUnit}</div>
                  </Col>
                </Row>
                <Row className={'mt-3 pe-2'}>
                  <Col xs={3}>
                    <div className={commonStyles.secondaryText}>Kategoria</div>
                    <div>{subItem.category}</div>
                  </Col>
                  <Col xs={3}>
                    <div className={commonStyles.secondaryText}>Komplet</div>
                    <div>{subItem.isElementSet ? 'Tak' : 'Nie'}</div>
                  </Col>
                  <Col xs={4}>
                    <div className={commonStyles.secondaryText}>
                      W magazynie
                    </div>
                    <div>{subItem.oldQuantity}</div>
                  </Col>
                  {handleGoToButtonClick && (
                    <Col xs={2}>
                      <Row className={'justify-content-around'}>
                        <Col xs={2}>
                          <FontAwesomeIcon
                            icon={faArrowCircleRight}
                            size={'2x'}
                            className={`${commonStyles.iconPrimary}`}
                            onClick={() =>
                              handleGoToButtonClick(
                                subItem.id,
                                subItem.isElementSet
                                  ? 'elementSets'
                                  : 'elements'
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </>
            </Accordion.Collapse>
          </Row>
          <Row>
            <Col className={styles.horizontalLine}></Col>
          </Row>
        </div>
      </Row>
    </>
  );
};
