import { PageParameters, PageResult } from '../../data/common-model';
import { usePostFetch } from '../http-client';

export interface GetUserListRequest {
  email?: string;
  firstName?: string;
  lastName?: string;
  page: PageParameters;
}

export interface User {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  creationTimestamp: Date;
}

export interface GetUserListResponse {
  usersList: User[];
  page: PageResult;
}

export function useGetUserList(request: GetUserListRequest) {
  const { response } = usePostFetch<GetUserListRequest, GetUserListResponse>(
    '/get-users-list',
    request
  );
  return { response };
}
