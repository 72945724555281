import { usePostAction } from '../http-client';

interface DeleteUserRequest {
  email: string;
}

export function useDeleteUser() {
  const { callPost } = usePostAction<DeleteUserRequest, void>('/delete-user');
  return { callDeleteUser: callPost };
}
