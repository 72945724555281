import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import React from 'react';

interface SchemaFormModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  title: string;
  body: string;
  backButtonText: string;
  nextButtonText: string;
}

const SchemaFormModal = ({
  show,
  setShow,
  title,
  body,
  backButtonText,
  nextButtonText,
}: SchemaFormModalProps) => {
  const navigate = useNavigate();
  const navigateBack = () => {
    setShow(false);
    navigate(-1);
  };

  const continueHere = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size={'lg'}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{body}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={navigateBack}
          variant='outline-primary'>
          {backButtonText}
        </Button>
        <Button
          onClick={continueHere}
          className='ms-3'
          variant='primary'>
          {nextButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { SchemaFormModal };
