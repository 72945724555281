import { useNavigate } from 'react-router-dom';
import {
  faCopy,
  faPenToSquare,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import commonStyles from '../../../../desktop-view/Common.module.scss';
import { useState } from 'react';
import { RoleFilter } from '../../../RoleFilter';
import { UserRole } from '../../../../api/auth/token-content';
import { DeleteModal } from '../../../DeleteModal';
import { SchemaActionButtonsState } from '../../field-states/SchemaActionButtonsState';
import { BaseRendererType, CustomTableState } from '../../CustomTable';

export function SchemaActionButtonsRenderer(
  props: BaseRendererType
): JSX.Element {
  const tableState: CustomTableState = props.tableState;
  const navigate = useNavigate();
  const schemaActionButtonsState: SchemaActionButtonsState =
    props.cellStates['actionButtons'];
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteElementSchemaId, setDeleteElementSchemaId] = useState('');

  const handleDeleteClick = (elementSchemaId: string) => {
    setDeleteElementSchemaId(elementSchemaId);
    setShowDeleteModal(true);
  };

  const handleEditClick = (elementSchemaId: string) => {
    navigate(
      `${schemaActionButtonsState.editActionNavigationPath}/${elementSchemaId}`
    );
  };

  const handleCopyObject = (objectId: string) => {
    navigate(
      `${schemaActionButtonsState.addActionNavigationPath}?${schemaActionButtonsState.addActionQueryParams}=${objectId}`
    );
  };

  return (
    <>
      {
        <RoleFilter
          visibleForRoles={[UserRole.WAREHOUSE_ADMIN, UserRole.SYSTEM_ADMIN]}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size={'xl'}
            className={`${commonStyles.iconPrimary} me-3`}
            onClick={() => handleEditClick(tableState.object.id)}
          />
          {schemaActionButtonsState.addActionNavigationPath &&
            schemaActionButtonsState.addActionQueryParams && (
              <FontAwesomeIcon
                icon={faCopy}
                size='xl'
                className={`${commonStyles.iconPrimary} me-3`}
                onClick={() => handleCopyObject(tableState.object.id)}
              />
            )}
          <FontAwesomeIcon
            icon={faTrashCan}
            size={'xl'}
            className={commonStyles.iconRed}
            onClick={() => handleDeleteClick(tableState.object.id)}
          />
        </RoleFilter>
      }
      {
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deleteSchemaId={deleteElementSchemaId}
          modalTitle={schemaActionButtonsState.deleteModalTitle}
          modalContent={schemaActionButtonsState.deleteModalBodyText}
          deleteFunction={async (id: string) =>
            schemaActionButtonsState.callDeleteAction({ id })
          }
          refetch={props.refetch}
        />
      }
    </>
  );
}
