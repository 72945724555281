import { usePostFetchWithDebounce } from '../http-client';
import {
  ElementCraftingPreview,
  ElementSetCraftingPreview,
} from './preview-element-set-crafting';

export interface PreviewModuleCraftingRequest {
  id: string;
  craftQuantity: number;
}

export interface PreviewModuleCraftingResponse {
  module: ModuleCraftingPreview;
  elementSetList: ElementSetCraftingPreview[];
  elementList: ElementCraftingPreview[];
}

export interface ModuleCraftingPreview {
  id: string;
  name: string;
  stockKeepingUnit: string;
  category: string;
  variant: string;
  imageId: string;
  oldQuantity: number;
  requiredQuantity: number;
  newQuantity: number;
  craftedQuantity: number;
  subiItemList: ModuleSubItemCraftingPreview[];
}

export interface ModuleSubItemCraftingPreview {
  id: string;
  name: string;
  stockKeepingUnit: string;
  category: string;
  variant: string;
  imageId: string;
  oldQuantity: number;
  requiredQuantity: number;
  craftedQuantity: number;
  newQuantity: number;
  isElementSet: boolean;
}

export function usePreviewModuleCrafting(
  request: PreviewModuleCraftingRequest
) {
  const { response } = usePostFetchWithDebounce<
    PreviewModuleCraftingRequest,
    PreviewModuleCraftingResponse
  >('/preview-module-crafting', request);
  return { response };
}
