import { FloatingLabel, Form, FormGroup } from 'react-bootstrap';
import React from 'react';

interface FormInputProps {
  value: string;
  setValue: (value: string) => void;
  controlId: string;
  label: string;
  errorText: string;
  disabled?: boolean;
  additionalFeedbacks?: React.ReactNode;
}

const FormInput = ({
  value,
  setValue,
  controlId,
  label,
  errorText,
  disabled,
  additionalFeedbacks,
}: FormInputProps) => {
  return (
    <FormGroup className='pb-4'>
      <FloatingLabel
        controlId={controlId}
        label={label}>
        <Form.Control
          disabled={disabled ?? false}
          required
          type='text'
          placeholder={label}
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        <Form.Control.Feedback type='invalid'>
          {errorText}
        </Form.Control.Feedback>
        {additionalFeedbacks}
      </FloatingLabel>
    </FormGroup>
  );
};

export { FormInput };
