import { GetElementListRequest } from '../../api/elements/get-element-list';
import { Row } from 'react-bootstrap';
import React from 'react';
import { ActiveFilterTile } from '../ActiveFilterTile';

export interface ActiveElementFiltersProps {
  elementRequest: GetElementListRequest;
  setElementRequest: (request: GetElementListRequest) => void;
}

export function ActiveElementFilters(
  props: Readonly<ActiveElementFiltersProps>
) {
  const { elementRequest, setElementRequest } = props;

  return (
    <Row>
      <ActiveFilterTile
        name={'Nazwa'}
        value={elementRequest.name}
        onRemove={() =>
          setElementRequest({
            ...elementRequest,
            name: undefined,
          })
        }
      />
      <ActiveFilterTile
        name={'SKU'}
        value={elementRequest.stockKeepingUnit}
        onRemove={() =>
          setElementRequest({
            ...elementRequest,
            stockKeepingUnit: undefined,
          })
        }
      />
      <ActiveFilterTile
        name={'Kategoria'}
        value={elementRequest.category}
        onRemove={() =>
          setElementRequest({
            ...elementRequest,
            category: undefined,
          })
        }
      />
      <ActiveFilterTile
        name={'Czy komplet'}
        value={elementRequest.isElementSet}
        mapFunction={(value: boolean) => (value ? 'Tak' : 'Nie')}
        onRemove={() =>
          setElementRequest({
            ...elementRequest,
            isElementSet: undefined,
          })
        }
      />
    </Row>
  );
}
