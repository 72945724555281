import { useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';

interface TitleContext {
  title: string;
  setTitle: (title: string) => void;
}

function useSetTitle(title: string) {
  const { setTitle } = useOutletContext<TitleContext>();
  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);
}

export { useSetTitle };
export type { TitleContext };
