import { useState } from 'react';
import {
  GetElementListRequest,
  useGetElementList,
} from './elements/get-element-list';

const INITIAL_ELEMENTS_LIST_RESPONSE = {
  elementList: [],
  page: {
    pageNumber: 0,
    totalCount: 0,
    pageSize: 0,
    pageCount: 0,
  },
};

export function useElements() {
  const [getElementListRequest, setGetElementListRequest] =
    useState<GetElementListRequest>({
      page: {
        pageNumber: 0,
        pageSize: 10,
      },
    });

  const { response: getElementListResponse } = useGetElementList(
    getElementListRequest
  );

  const elementListResponse =
    getElementListResponse?.data ?? INITIAL_ELEMENTS_LIST_RESPONSE;

  const isLoading = getElementListResponse?.isLoading ?? false;

  const handlePaginationChange = (
    pageNumber: number,
    elementsPerPage: number
  ) => {
    setGetElementListRequest({
      ...getElementListRequest,
      page: {
        pageNumber: pageNumber - 1,
        pageSize: elementsPerPage,
      },
    });
  };

  return {
    elementListResponse,
    isLoading,
    getElementListRequest,
    setGetElementListRequest,
    handlePaginationChange,
  };
}
