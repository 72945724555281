import { usePostFetch } from '../http-client';
import { UserRole } from '../auth/token-content';

export interface GetUserResponse {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  creationTimestamp: Date;
}
export interface GetUserRequest {
  userId: string;
}

export function useGetUser(request: GetUserRequest) {
  const { response } = usePostFetch<GetUserRequest, GetUserResponse>(
    '/get-user',
    request
  );
  return [response];
}
