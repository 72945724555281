import {
  Button,
  Col,
  FloatingLabel,
  Form,
  FormGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { Category } from '../../data/category-model';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { GetModuleListRequest } from '../../api/modules/get-module-list';
import { Variant } from '../../data/variant-model';

export interface ModuleFiltersModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  moduleCategories: Category[];
  moduleVariants: Variant[];
  moduleListRequest: GetModuleListRequest;
  setModuleListRequest: (request: GetModuleListRequest) => void;
}

export function ModuleFiltersModal(props: Readonly<ModuleFiltersModalProps>) {
  const {
    moduleListRequest,
    setModuleListRequest,
    show,
    setShow,
    moduleCategories,
    moduleVariants,
  } = props;
  const [name, setName] = useState(moduleListRequest.name ?? '');
  const [stockKeepingUnit, setStockKeepingUnit] = useState(
    moduleListRequest.stockKeepingUnit ?? ''
  );
  const [category, setCategory] = useState(moduleListRequest.category ?? '');
  const [variant, setVariant] = useState(moduleListRequest.variant ?? '');

  useEffect(() => {
    setCategory(moduleListRequest.category ?? '');
    setName(moduleListRequest.name ?? '');
    setStockKeepingUnit(moduleListRequest.stockKeepingUnit ?? '');
    setVariant(moduleListRequest.variant ?? '');
  }, [moduleListRequest]);

  const onNameChanged = (name: string) => {
    setName(name);
  };

  const onStockKeepingUnitChanged = (stockKeepingUnit: string) => {
    setStockKeepingUnit(stockKeepingUnit);
  };

  const onCategoryChange = (selectedCategory: string) => {
    setCategory(selectedCategory);
  };

  const onVariantChange = (variant: string) => {
    setVariant(variant);
  };

  const onSaveClick = () => {
    setModuleListRequest({
      name,
      stockKeepingUnit,
      category,
      variant,
      page: {
        ...moduleListRequest.page,
        pageNumber: 0,
      },
    });
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size={'lg'}>
      <Modal.Header>
        <Modal.Title>Filtruj moduły</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className={'pt-2 pb-2'}>
          <Row>
            <Col className='mb-3'>
              <FormGroup>
                <FloatingLabel
                  controlId='name'
                  label='Nazwa'>
                  <Form.Control
                    value={name}
                    type='text'
                    placeholder='Nazwa'
                    onChange={e => onNameChanged(e.target.value)}
                  />
                </FloatingLabel>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className='mb-3'>
              <FormGroup>
                <FloatingLabel
                  controlId='stockKeepingUnit'
                  label='SKU'>
                  <Form.Control
                    value={stockKeepingUnit}
                    type='text'
                    placeholder='Stock Keeping Unit'
                    onChange={e => onStockKeepingUnitChanged(e.target.value)}
                  />
                </FloatingLabel>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className='mb-3'>
              <FloatingLabel
                controlId='category'
                label='Kategoria'>
                <Form.Select
                  onChange={e => onCategoryChange(e.target.value)}
                  value={category}>
                  <option value={''}>Wybierz kategorię...</option>
                  {moduleCategories.map(category => {
                    return (
                      <option
                        key={category.id}
                        value={category.label}>
                        {category.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col className='mb-3'>
              <FloatingLabel
                controlId='variant'
                label='Wariant'>
                <Form.Select
                  onChange={e => onVariantChange(e.target.value)}
                  value={variant}>
                  <option value={''}>Wybierz wariant...</option>
                  {moduleVariants.map(variant => {
                    return (
                      <option
                        key={variant.id}
                        value={variant.label}>
                        {variant.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'outline-primary'}
          onClick={() => setShow(false)}
          className='me-auto'>
          Anuluj
        </Button>
        <Button
          variant={'primary'}
          onClick={onSaveClick}>
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
