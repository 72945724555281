import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SubElementModel } from '../../data/element-model';
import { AddSubElementsModal } from './AddSubElementsModal';
import { ItemType } from '../../data/item-model';
import { CustomColumn, CustomTable } from '../table/CustomTable';
import { ImageRenderer } from '../table/renderers/ImageRenderer';
import { useSchemaQuantityState } from '../table/field-states/SchemaQuantityState';
import { SchemaQuantityRenderer } from '../table/renderers/SchemaQuantityRenderer';
import { SubElementsActionButtonsRenderer } from '../table/renderers/action-buttons/SubElementsActionButtonsRenderer';

const columns: CustomColumn[] = [
  {
    name: '',
    field: 'imageId',
    className: 'col-sm-1',
    renderer: ImageRenderer,
  },
  {
    name: 'Nazwa',
    field: 'name',
    className: 'col-sm-3',
  },
  {
    name: 'SKU',
    field: 'stockKeepingUnit',
    className: 'col-sm-2',
  },
  {
    name: 'Kategoria',
    field: 'category',
    className: 'col-sm-2',
  },
  {
    name: 'Liczba elementów',
    field: 'schemaQuantity',
    className: 'col-sm-2',
    renderer: SchemaQuantityRenderer,
    prepareInitialState: useSchemaQuantityState,
  },
  {
    name: '',
    field: '',
    className: 'col-sm-2',
    renderer: SubElementsActionButtonsRenderer,
  },
];

export interface SubElementsTableProps {
  subElementList: SubElementModel[];
  setSubElementList: (subElementList: SubElementModel[]) => void;
  modificationElementId?: string;
  itemType: ItemType;
}

export interface SubElementsModificationProps {
  updateSubElemQuantity: (elemId: string, quantity: number) => void;
  removeSubElem: (elemIdToRemove: string) => void;
}

export default function SubElementsTable(props: SubElementsTableProps) {
  const [showAddElementsModal, setShowAddElementsModal] = useState(false);

  const removeSubElem = (elemIdToRemove: string) => {
    props.setSubElementList(
      props.subElementList.filter(elem => elem.id !== elemIdToRemove)
    );
  };

  const updateSubElemQuantity = (elemId: string, quantity: number) => {
    const updatedSubElemList = [...props.subElementList];
    const elemToUpdate = updatedSubElemList.find(elem => elem.id === elemId);
    if (elemToUpdate) {
      elemToUpdate.schemaQuantity = quantity;
      props.setSubElementList(updatedSubElemList);
    }
  };

  const subElementsModificationProps: SubElementsModificationProps = {
    updateSubElemQuantity: updateSubElemQuantity,
    removeSubElem: removeSubElem,
  };

  return (
    <>
      <Row className={'align-items-sm-center'}>
        <Col sm={10}>
          <h2>Lista elementów</h2>
        </Col>
        <Col
          sm={2}
          className='d-flex justify-content-end ps-2'>
          <Button
            variant={'primary'}
            onClick={() => {
              setShowAddElementsModal(true);
            }}
            className='w-100'>
            <FontAwesomeIcon icon={faPlus} />
            <span className={'m-2'}>Dodaj elementy</span>
          </Button>
        </Col>
      </Row>

      <CustomTable
        columns={columns}
        data={props.subElementList}
        emptyListMessage='Nie wybrano żadnych elementów.'
        refetch={() => {}}
        externalProps={subElementsModificationProps}
      />

      <AddSubElementsModal
        show={showAddElementsModal}
        setShow={setShowAddElementsModal}
        subElementsTableProps={props}
      />
    </>
  );
}
