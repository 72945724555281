import { useState } from 'react';
import { Col, Form, Pagination, Row } from 'react-bootstrap';

export default function CustomPagination(
  props: Readonly<CustomPaginationProps>
) {
  const changePage = (pageNumber: number) => {
    if (pageNumber === currentPage) return;
    setCurrentPage(pageNumber);
    props.onPaginationParamsChange(pageNumber, elementsPerPage);
  };

  const changeElementsPerPage = (elementsPerPage: string) => {
    setElementsPerPage(Number(elementsPerPage));
    props.onPaginationParamsChange(1, Number(elementsPerPage));
  };

  const [currentPage, setCurrentPage] = useState(props.pageNumber);
  const [elementsPerPage, setElementsPerPage] = useState(props.elementsPerPage);

  const createPaginationItem = (i: number) => {
    return (
      <Pagination.Item
        key={i}
        active={i === currentPage}
        className={'p-1'}
        onClick={() => changePage(i)}>
        {i}
      </Pagination.Item>
    );
  };

  const createPaginationItemList = () => {
    const paginationItems = [];

    const visibleScopeItemsfromCurrentPage = 2;
    if (currentPage > visibleScopeItemsfromCurrentPage + 1)
      paginationItems.push(createPaginationItem(1));
    if (currentPage > visibleScopeItemsfromCurrentPage + 2)
      paginationItems.push(<Pagination.Ellipsis className={'p-1'} />);

    const startingScopePage = Math.max(
      currentPage - visibleScopeItemsfromCurrentPage,
      1
    );
    const endingScopePage = Math.min(
      currentPage + visibleScopeItemsfromCurrentPage,
      props.totalPages
    );
    for (let i = startingScopePage; i <= endingScopePage; i++) {
      paginationItems.push(createPaginationItem(i));
    }

    if (currentPage < props.totalPages - (visibleScopeItemsfromCurrentPage + 1))
      paginationItems.push(<Pagination.Ellipsis className={'p-1'} />);
    if (currentPage < props.totalPages - visibleScopeItemsfromCurrentPage)
      paginationItems.push(createPaginationItem(props.totalPages));

    return paginationItems;
  };

  return (
    <Row className='justify-content-between mt-3'>
      {!props.disablePageSizeChange && <Col sm='1'></Col>}
      <Col
        sm={!props.disablePageSizeChange ? '10' : '12'}
        className='d-flex justify-content-center'>
        <Pagination className='mt-3'>
          <Pagination.Prev
            onClick={() => changePage(currentPage - 1)}
            className={'p-1'}
            disabled={currentPage === 1}
          />
          {createPaginationItemList()}
          <Pagination.Next
            onClick={() => changePage(currentPage + 1)}
            className={'p-1'}
            disabled={currentPage === props.totalPages}
          />
        </Pagination>
      </Col>
      {!props.disablePageSizeChange && (
        <Col
          sm='1'
          className='d-flex justify-content-end align-items-center'>
          <Form.Select
            className='w-75'
            style={{ minWidth: 75 }}
            value={elementsPerPage}
            onChange={e => changeElementsPerPage(e.target.value)}>
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='50'>50</option>
          </Form.Select>
        </Col>
      )}
    </Row>
  );
}

interface CustomPaginationProps {
  pageNumber: number;
  elementsPerPage: number;
  totalPages: number;
  items: any[];
  onPaginationParamsChange: (
    pageNumber: number,
    elementsPerPage: number
  ) => void;
  disablePageSizeChange?: boolean;
}
