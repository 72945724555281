import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Image, Row } from 'react-bootstrap';
import styles from './Crafting.module.scss';
import { debounce } from 'lodash';
import { getThumbnailUrl } from '../../api/image/get-image';
import commonStyles from '../../desktop-view/Common.module.scss';
import { ImageZoomModal } from '../ImageZoomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import {
  ProductCraftingPreview,
  ProductSubItemCraftingPreview,
} from '../../api/elements/preview-product-crafting';
import { useImageModal } from '../../hook/useImageModal';

export const PreviewCraftingProductTable = (props: {
  productList: ProductCraftingPreview[];
  onGoToClick: (id: string, activeTab: string) => void;
  onQuantityChange?: (quantity: number) => void;
  isCraftingRoot: boolean;
}) => {
  const { productList, onGoToClick, onQuantityChange, isCraftingRoot } = props;

  return (
    <>
      <TableHeader />
      {isCraftingRoot && productList.length === 1 ? (
        <ProductCraftingRoot
          productPreview={productList[0]}
          onGoToClick={onGoToClick}
          onQuantityChange={onQuantityChange!!}
        />
      ) : (
        productList.map(product => (
          <ProductRow
            productPreview={product}
            onGoToClick={onGoToClick}
          />
        ))
      )}
    </>
  );
};

const TableHeader = () => {
  return (
    <Row className={'border-bottom border-3 pb-2'}>
      <Col
        sm={'auto'}
        className={'h-100 d-flex justify-content-end'}>
        <div className={`vr ${styles.invisibleVerticalLine}`}></div>
      </Col>
      <Col></Col>
      <Col>Nazwa</Col>
      <Col>SKU</Col>
      <Col>Kategoria</Col>
      <Col>Wariant</Col>
      <Col>EAN</Col>
      <Col>Ilość posiadana</Col>
      <Col>Ilość potrzebna</Col>
      <Col>Ilość końcowa</Col>
      <Col></Col>
    </Row>
  );
};

const ProductCraftingRoot = (props: {
  productPreview: ProductCraftingPreview;
  onGoToClick: (id: string, activeTab: string) => void;
  onQuantityChange: (quantity: number) => void;
}) => {
  const { productPreview, onGoToClick, onQuantityChange } = props;

  const { showImageModal, setShowImageModal, selectedImage, handleImageClick } =
    useImageModal();
  const [quantity, setQuantity] = useState<number>(
    productPreview.requiredQuantity
  );
  const setCraftQuantityDebounced = useMemo(() => {
    return debounce(onQuantityChange, 500);
  }, [onQuantityChange]);

  const handleCraftQuantityChange = useCallback(
    (event: any) => {
      const newCraftQuantity =
        event.target.value !== '' && event.target.validity.valid
          ? parseInt(event.target.value)
          : 0;
      setQuantity(newCraftQuantity);
    },
    [setQuantity]
  );

  useEffect(() => {
    setCraftQuantityDebounced(quantity);
  }, [quantity, setCraftQuantityDebounced]);

  return (
    <>
      <Row className={'align-items-center pt-2 pb-2 border-bottom border-1'}>
        <Col
          sm={'auto'}
          className={'h-100 d-flex justify-content-end'}>
          <div className={`vr ${styles.invisibleVerticalLine}`}></div>
        </Col>
        <Col className={'d-flex justify-content-center'}>
          <Image
            src={getThumbnailUrl(productPreview.imageId)}
            fluid
            className={commonStyles.zoomableImage}
            onClick={() => handleImageClick(productPreview.imageId)}
            style={{ width: 50, height: 50 }}
          />
        </Col>
        <Col>{productPreview.name}</Col>
        <Col>{productPreview.stockKeepingUnit}</Col>
        <Col>{productPreview.category}</Col>
        <Col>{productPreview.variant}</Col>
        <Col>{productPreview.europeanArticleNumber}</Col>
        <Col>{'-'}</Col>
        <Col>
          <Form.Control
            className='w-100'
            type='number'
            defaultValue={quantity}
            min='0'
            onChange={handleCraftQuantityChange}
          />
        </Col>
        <Col>{'-'}</Col>
        <Col></Col>

        <ImageZoomModal
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
          imageId={selectedImage}
        />
      </Row>
      {productPreview.subiItemList.map(it => (
        <SubItemRow
          subItem={it}
          handleGoToButtonClick={onGoToClick}
        />
      ))}
    </>
  );
};

const ProductRow = (props: {
  productPreview: ProductCraftingPreview;
  onGoToClick: (id: string, activeTab: string) => void;
}) => {
  const { productPreview, onGoToClick } = props;

  const { showImageModal, setShowImageModal, selectedImage, handleImageClick } =
    useImageModal();

  return (
    <>
      <Row className={'align-items-center pt-2 pb-2 border-bottom border-1'}>
        <Col
          sm={'auto'}
          className={'h-100 d-flex justify-content-end'}>
          <div className={`vr ${styles.invisibleVerticalLine}`}></div>
        </Col>
        <Col className={'d-flex justify-content-center'}>
          <Image
            src={getThumbnailUrl(productPreview.imageId)}
            fluid
            className={commonStyles.zoomableImage}
            onClick={() => handleImageClick(productPreview.imageId)}
            style={{ width: 50, height: 50 }}
          />
        </Col>
        <Col>{productPreview.name}</Col>
        <Col>{productPreview.stockKeepingUnit}</Col>
        <Col>{productPreview.category}</Col>
        <Col>{productPreview.variant}</Col>
        <Col>{productPreview.europeanArticleNumber}</Col>
        <Col>
          {productPreview.oldQuantity}{' '}
          {productPreview.craftedQuantity !== 0 && (
            <label className={styles.greenText}>
              +{productPreview.craftedQuantity}
            </label>
          )}
        </Col>
        <Col>{productPreview.requiredQuantity}</Col>
        <Col>
          <td>{productPreview.newQuantity}</td>
        </Col>
        <Col></Col>

        <ImageZoomModal
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
          imageId={selectedImage}
        />
      </Row>
      {productPreview.subiItemList.map(it => (
        <SubItemRow
          subItem={it}
          handleGoToButtonClick={onGoToClick}
        />
      ))}
    </>
  );
};

const SubItemRow = (props: {
  subItem: ProductSubItemCraftingPreview;
  handleGoToButtonClick: (id: string, activeTab: string) => void;
}) => {
  const { subItem, handleGoToButtonClick } = props;
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setShowImageModal(true);
  };

  return (
    <>
      <Row className={'align-items-center'}>
        <Col
          sm={'auto'}
          className={'h-100 d-flex justify-content-end'}>
          <div className={`vr ${styles.verticalLine}`}></div>
        </Col>
        <Col className={'d-flex justify-content-center pt-2 pb-2'}>
          <Image
            src={getThumbnailUrl(subItem.imageId)}
            fluid
            className={`${commonStyles.zoomableImage}`}
            onClick={() => handleImageClick(subItem.imageId)}
            style={{ width: 50, height: 50 }}
          />
        </Col>

        <Col>{subItem.name}</Col>
        <Col>{subItem.stockKeepingUnit}</Col>
        <Col>{subItem.category}</Col>
        <Col>{subItem.variant}</Col>
        <Col>{subItem.europeanArticleNumber}</Col>
        <Col>
          {subItem.oldQuantity}{' '}
          {subItem.craftedQuantity !== 0 && (
            <label className={styles.greenText}>
              +{subItem.craftedQuantity}
            </label>
          )}
        </Col>
        <Col>{subItem.requiredQuantity}</Col>
        <Col
          className={
            subItem.newQuantity >= 0 ? styles.greenText : styles.redText
          }>
          {subItem.newQuantity}
        </Col>
        <Col>
          <FontAwesomeIcon
            icon={faArrowCircleRight}
            size={'xl'}
            className={`${commonStyles.iconPrimary} me-3`}
            onClick={() => handleGoToButtonClick(subItem.id, 'modules')}
          />
        </Col>
        <ImageZoomModal
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
          imageId={selectedImage}
        />
      </Row>
      <Row className={'align-items-center'}>
        <Col
          sm={'auto'}
          className={'h-100 d-flex justify-content-end'}>
          <div className={`vr ${styles.verticalLine}`}></div>
        </Col>
        <Col className={styles.horizontalLine}></Col>
      </Row>
    </>
  );
};
