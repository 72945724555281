import { usePostAction } from '../http-client';

interface DeleteModuleRequest {
  id: string;
}

interface DeleteModuleResponse {
  id: string;
  deleted: boolean;
}

export function useDeleteModule() {
  const { callPost } = usePostAction<DeleteModuleRequest, DeleteModuleResponse>(
    '/delete-module'
  );
  return { callDeleteModule: callPost };
}
