import { createContext, ReactNode } from 'react';
import { UserRole } from '../api/auth/token-content';
import { UserInfo } from '../api/auth/user-info';

export const UserContext = createContext<UserInfo>({
  lastName: 'Brak',
  firstName: 'Brak',
  email: 'brak@naskom.dev',
  role: UserRole.BLANK,
});

interface UserContextProviderProps {
  userInfo: UserInfo;
  children: ReactNode;
}

export function UserContextProvider(props: UserContextProviderProps) {
  return (
    <UserContext.Provider value={props.userInfo}>
      {props.children}
    </UserContext.Provider>
  );
}
