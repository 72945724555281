import { createContext, ReactNode, useState } from 'react';
import { ApiError } from '../data/error-model';

interface ErrorContextProps {
  show: boolean;
  setShow: (show: boolean) => void;
  error?: ApiError;
  setError: (error: ApiError) => void;
  setErrorAndShow: (error: ApiError) => void;
}

const ErrorContext = createContext<ErrorContextProps>({
  show: false,
  setShow: () => {},
  error: undefined,
  setError: () => {},
  setErrorAndShow: () => {},
});

interface ErrorContextProviderProps {
  errorContext: ErrorContextProps;
  children: ReactNode;
}

function ErrorContextProvider(props: ErrorContextProviderProps) {
  return (
    <ErrorContext.Provider value={props.errorContext}>
      {props.children}
    </ErrorContext.Provider>
  );
}

function useInitErrorContext(): ErrorContextProps {
  const [show, setShow] = useState(false);
  const [error, setError] = useState<ApiError | undefined>();
  const setErrorAndShow = (error: ApiError) => {
    setShow(true);
    setError(error);
  };
  return {
    show,
    setShow,
    error,
    setError,
    setErrorAndShow,
  };
}

export { ErrorContext, ErrorContextProvider, useInitErrorContext };
