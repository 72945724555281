import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import commonStyles from '../../../../desktop-view/Common.module.scss';
import { ElementModel } from '../../../../data/element-model';
import { SubElementsTableProps } from '../../../elements/SubElementsTable';
import { BaseRendererType, CustomTableState } from '../../CustomTable';

export function AddSubElementsActionButtonsRenderer(
  props: BaseRendererType
): JSX.Element {
  const tableState: CustomTableState = props.tableState;
  const object = tableState.object;
  const subElementsTableProps: SubElementsTableProps = tableState.externalProps;

  const handleAddElementClick = (element: ElementModel) => {
    subElementsTableProps.setSubElementList([
      ...subElementsTableProps.subElementList,
      {
        id: element.id,
        name: element.name,
        stockKeepingUnit: element.stockKeepingUnit,
        category: element.category,
        imageId: element.imageId,
        schemaQuantity: 0,
      },
    ]);
  };

  const handleElementRemovalClick = (elemIdToRemove: string) => {
    subElementsTableProps.setSubElementList(
      subElementsTableProps.subElementList.filter(
        elem => elem.id !== elemIdToRemove
      )
    );
  };

  const isElementInList = (elemId: string) => {
    return subElementsTableProps.subElementList.find(e => e.id === elemId);
  };

  return (
    <>
      {!isElementInList(object.id) && (
        <>
          <FontAwesomeIcon
            icon={faPlus}
            size={'xl'}
            className={`${commonStyles.iconPrimary} me-3`}
            onClick={() => handleAddElementClick(object)}
          />
        </>
      )}
      {isElementInList(object.id) && (
        <>
          <FontAwesomeIcon
            icon={faMinus}
            size={'xl'}
            className={`${commonStyles.iconRed} me-3`}
            onClick={() => handleElementRemovalClick(object.id)}
          />
        </>
      )}
    </>
  );
}
