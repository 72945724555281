import { PageParameters, PageResult } from '../../data/common-model';
import { usePostFetch } from '../http-client';
import { ElementModel } from '../../data/element-model';

export interface GetElementListRequest {
  name?: string;
  stockKeepingUnit?: string;
  category?: string;
  isElementSet?: boolean;
  page: PageParameters;
}

export interface GetElementListResponse {
  elementList: ElementModel[];
  page: PageResult;
}

export function useGetElementList(request: GetElementListRequest) {
  const { response } = usePostFetch<
    GetElementListRequest,
    GetElementListResponse
  >('/get-element-list', request);
  return { response };
}
