import React, { Dispatch, SetStateAction } from 'react';
import { Image, Modal } from 'react-bootstrap';
import { getImageUrl } from '../api/image/get-image';

export interface ImageZoomModalProps {
  showImageModal: boolean;
  setShowImageModal: Dispatch<SetStateAction<boolean>>;
  imageId: string;
}

export function ImageZoomModal(props: Readonly<ImageZoomModalProps>) {
  return (
    <Modal
      show={props.showImageModal}
      onHide={() => props.setShowImageModal(false)}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className='d-flex justify-content-center'>
        <Image
          src={getImageUrl(props.imageId)}
          fluid
        />
      </Modal.Body>
    </Modal>
  );
}
