import { PageParameters, PageResult } from '../../data/common-model';
import { WarehouseHistoryModel } from '../../data/history-model';
import { usePostFetch } from '../http-client';

export interface GetWarehouseHistoryRequest {
  stockKeepingUnit?: string;
  email?: string;
  operationType?: string;
  dateFrom?: Date;
  dateTo?: Date;
  page: PageParameters;
}

export interface GetWarehouseHistoryResponse {
  warehouseHistory: WarehouseHistoryModel[];
  page: PageResult;
}

export function useGetWarehouseHistory(request: GetWarehouseHistoryRequest) {
  const { response } = usePostFetch<
    GetWarehouseHistoryRequest,
    GetWarehouseHistoryResponse
  >('/get-warehouse-history', request);
  return { response };
}
