import { BaseRendererType } from '../CustomTable';

export function DateRenderer(
  props: BaseRendererType,
  formatter: Intl.DateTimeFormat
): JSX.Element {
  const object = props.tableState.object;
  const date = new Date(object[props.field]);
  const formattedDate = formatter.format(date);

  return <p className='mb-0'>{formattedDate}</p>;
}

export function DateWithDaysRenderer(props: BaseRendererType): JSX.Element {
  const formatter = new Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return DateRenderer(props, formatter);
}

export function DateWithMinutesRenderer(props: BaseRendererType): JSX.Element {
  const formatter = new Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  return DateRenderer(props, formatter);
}
