import { InputProps, Option } from 'react-bootstrap-typeahead/types/types';
import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { EmptyLabel } from './EmptyLabel';
import { NewSelectionPrefix } from './NewSelectionPrefix';
import { RenderAutocompleteItem } from './RenderAutocompleteItem';
import { RenderAutocompleteInput } from './RenderAutocompleteInput';

interface AutocompleteProps {
  inputId: string;
  placeholder: string;
  addNewLabel: string;
  emptyLabel: string;
  value: string;
  setValue: (value: string) => void;
  addNewValue: (value: string) => Promise<void>;
  values: AutocompleteOption[];
  refreshValues: () => void;
  deleteValue: (id: string) => Promise<void>;
}

interface AutocompleteOption {
  id: string;
  customOption?: boolean;
  label: string;
  canBeDeleted: boolean;
}

const Autocomplete = ({
  inputId,
  placeholder,
  addNewLabel,
  emptyLabel,
  value,
  setValue,
  addNewValue,
  values,
  refreshValues,
  deleteValue,
}: AutocompleteProps) => {
  const [selected, setSelected] = useState<AutocompleteOption[]>([]);
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    const selectedValue = values.find(x => x.label === value);
    if (selectedValue) {
      setSelected([selectedValue]);
    } else {
      setSelected([]);
    }
  }, [values, value]);

  const selectValue = (option?: AutocompleteOption) => {
    if (option) {
      setValue(option.label);
      setSelected([option]);
    } else {
      setValue('');
      setSelected([]);
    }
  };

  const onValueSelected = async (options: Option[]) => {
    const option = options[0] as AutocompleteOption;
    if (option) {
      const isCustom = option.customOption;
      if (isCustom) {
        await addNewValue(option.label);
        selectValue(option);
        refreshValues();
      } else {
        selectValue(option);
      }
    } else {
      selectValue();
    }
  };

  const inputProps: InputProps = {
    id: inputId,
    required: true,
    onInvalid: () => {
      setIsInvalid(true);
    },
  };

  return (
    <Typeahead
      id={inputId}
      isInvalid={isInvalid}
      inputProps={inputProps}
      allowNew={true}
      newSelectionPrefix={<NewSelectionPrefix prefix={addNewLabel} />}
      emptyLabel={<EmptyLabel label={emptyLabel} />}
      onChange={options => onValueSelected(options)}
      onInputChange={() => setIsInvalid(false)}
      placeholder={placeholder}
      selected={selected}
      options={values}
      renderMenuItemChildren={RenderAutocompleteItem({
        deleteValue,
        refreshValues,
      })}
      renderInput={RenderAutocompleteInput({
        controlId: inputId,
        label: placeholder,
      })}
    />
  );
};

export type { AutocompleteProps, AutocompleteOption };
export { Autocomplete };
