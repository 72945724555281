import { QuantityChangeConfirmationModal } from '../../QuantityChangeConfirmationModal';
import { Form } from 'react-bootstrap';
import { WarehouseQuantityState } from '../field-states/WarehouseQuantityState';
import { BaseRendererType, CustomTableState } from '../CustomTable';

export function WarehouseQuantityRenderer(
  props: BaseRendererType
): JSX.Element {
  const tableState: CustomTableState = props.tableState;
  const warehouseQuantity: number = tableState.object[props.field];
  const warehouseState: WarehouseQuantityState = props.cellStates[props.field];

  const handleWarehouseElementModification = () => {
    warehouseState
      .callChangeElementQuantity({
        id: tableState.object.id,
        newQuantity: warehouseState.quantity,
        oldQuantity: warehouseQuantity,
      })
      .then((response: any) => {
        if (!response.error) {
          tableState.setObject({
            ...tableState.object,
            warehouseQuantity: warehouseState.quantity,
            isEditMode: false,
          });
          props.refetch();
        }
      });
  };

  const handleQuantityChange = (event: any) => {
    warehouseState.setQuantity(
      event.target.value !== '' && event.target.validity.valid
        ? parseInt(event.target.value)
        : 0
    );
  };

  const isEditMode = () => {
    return tableState.object.isEditMode;
  };

  return (
    <>
      {isEditMode() ? (
        <Form.Control
          type='number'
          defaultValue={warehouseState.quantity ?? 0}
          min={'0'}
          onChange={handleQuantityChange}
        />
      ) : (
        warehouseState.quantity ?? 0
      )}
      <QuantityChangeConfirmationModal
        show={warehouseState.showQuantityConfirmationModal}
        setShow={warehouseState.setShowQuantityConfirmationModal}
        title={warehouseState.quantityModalTitle}
        bodyText={warehouseState.quantityModalBodyText}
        refetch={props.refetch}
        handleQuantityChange={handleWarehouseElementModification}
      />
    </>
  );
}
