import { Category } from '../../data/category-model';
import { GetModuleListRequest } from '../../api/modules/get-module-list';
import { Col, FloatingLabel, Form, FormGroup, Row } from 'react-bootstrap';
import React from 'react';
import { debounce } from 'lodash';
import { Variant } from '../../data/variant-model';

export interface ModuleFiltersProps {
  moduleCategories: Category[];
  moduleVariants: Variant[];
  moduleListRequest: GetModuleListRequest;
  setModuleListRequest: (request: GetModuleListRequest) => void;
}

export function ModuleFilters(props: Readonly<ModuleFiltersProps>) {
  const {
    moduleCategories,
    moduleVariants,
    moduleListRequest,
    setModuleListRequest,
  } = props;

  const onNameChanged = debounce((name: string) => {
    setModuleListRequest({
      ...moduleListRequest,
      name,
      page: { ...moduleListRequest.page, pageNumber: 0 },
    });
  }, 500);

  const onStockKeepingUnitChanged = debounce((stockKeepingUnit: string) => {
    setModuleListRequest({
      ...moduleListRequest,
      stockKeepingUnit,
      page: {
        ...moduleListRequest.page,
        pageNumber: 0,
      },
    });
  }, 500);

  const onCategoryChange = (selectedCategory: string) => {
    const categoryValue =
      selectedCategory === '' ? undefined : selectedCategory;
    setModuleListRequest({
      ...moduleListRequest,
      category: categoryValue,
      page: {
        ...moduleListRequest.page,
        pageNumber: 0,
      },
    });
  };

  const onVariantChange = (selectedVariant: string) => {
    const variantValue = selectedVariant === '' ? undefined : selectedVariant;
    setModuleListRequest({
      ...moduleListRequest,
      variant: variantValue,
      page: {
        ...moduleListRequest.page,
        pageNumber: 0,
      },
    });
  };

  return (
    <Form className='pt-5 pb-5'>
      <Row>
        <Col
          sm='3'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='name'
              label='Nazwa'>
              <Form.Control
                type='text'
                placeholder='Nazwa'
                onChange={e => onNameChanged(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
        <Col
          sm='3'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='stockKeepingUnit'
              label='SKU'>
              <Form.Control
                type='text'
                placeholder='Stock Keeping Unit'
                onChange={e => onStockKeepingUnitChanged(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
        <Col
          sm='3'
          className='mb-2'>
          <FloatingLabel
            controlId='category'
            label='Kategoria'>
            <Form.Select onChange={e => onCategoryChange(e.target.value)}>
              <option value={''}>Wybierz kategorię...</option>
              {moduleCategories.map(category => {
                return (
                  <option
                    key={category.id}
                    value={category.label}>
                    {category.label}
                  </option>
                );
              })}
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Col
          sm='3'
          className='mb-2'>
          <FloatingLabel
            controlId='variant'
            label='Wariant'>
            <Form.Select onChange={e => onVariantChange(e.target.value)}>
              <option value={''}>Wybierz wariant...</option>
              {moduleVariants.map(variant => {
                return (
                  <option
                    key={variant.id}
                    value={variant.label}>
                    {variant.label}
                  </option>
                );
              })}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
    </Form>
  );
}
