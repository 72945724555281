import React, { useState } from 'react';
import {
  faPenToSquare,
  faPlus,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Col, Form, Image, Row } from 'react-bootstrap';
import commonStyles from '../../Common.module.scss';
import { useElements } from '../../../api/elements';
import { useCategories } from '../../../api/categories';
import { ItemType } from '../../../data/item-model';
import { EmptyListMessage } from '../../../components/EmptyListMessage';
import { CenteredSpinner } from '../../../components/CenteredSpinner';
import { ElementModel } from '../../../data/element-model';
import { getThumbnailUrl } from '../../../api/image/get-image';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { useWarehouseElementQuantityState } from '../../../components/table/field-states/WarehouseQuantityState';
import { QuantityChangeConfirmationModal } from '../../../components/QuantityChangeConfirmationModal';
import CustomPagination from '../../../components/CustomPagination';
import { ElementFiltersModal } from '../../../components/elements/ElementFiltersModal';
import { ImageZoomModal } from '../../../components/ImageZoomModal';
import { ActiveElementFilters } from '../../../components/elements/ActiveElementFilters';
import { MobileListFilterButtons } from '../../../components/MobileListFilterButtons';
import { QrCodeScanner } from '../../../components/QrCodeScanner';
import styles from '../Warehouse.module.scss';
import { NavigationPath } from '../../../data/constants';
import { useNavigate } from 'react-router-dom';
import { AccordionToggle } from '../../../components/AccordionToggle';

export function WarehouseElements() {
  const {
    elementListResponse,
    isLoading,
    getElementListRequest,
    setGetElementListRequest,
    handlePaginationChange,
  } = useElements();

  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const { categoryList } = useCategories(ItemType.ELEMENT);

  const isWarehouseElementsListEmpty = () => {
    return elementListResponse.elementList?.length === 0;
  };

  const filterBySKU = (stockKeepingUnit: string) => {
    setShowScanner(false);
    setGetElementListRequest({
      ...getElementListRequest,
      stockKeepingUnit,
    });
  };

  return (
    <>
      {!showScanner && (
        <>
          <MobileListFilterButtons
            setShowFilters={setShowFilters}
            setShowScanner={setShowScanner}
          />
          <ActiveElementFilters
            elementRequest={getElementListRequest}
            setElementRequest={setGetElementListRequest}
          />
          {isLoading && (
            <div className={styles.list}>
              <CenteredSpinner />
            </div>
          )}
          {!isLoading && !isWarehouseElementsListEmpty() && (
            <div className={styles.list}>
              <Accordion
                alwaysOpen
                defaultActiveKey={activeKey}>
                {elementListResponse.elementList.map((element, index) => {
                  return (
                    <WarehouseElementRow
                      element={element}
                      index={index}
                      key={element.stockKeepingUnit}
                      refetch={() =>
                        setGetElementListRequest({ ...getElementListRequest })
                      }
                      setActiveKey={setActiveKey}
                    />
                  );
                })}
              </Accordion>
            </div>
          )}
          {!isLoading && isWarehouseElementsListEmpty() && (
            <EmptyListMessage message={'Brak elementów w magazynie.'} />
          )}
          {!isWarehouseElementsListEmpty() && (
            <CustomPagination
              pageNumber={elementListResponse.page.pageNumber + 1}
              elementsPerPage={elementListResponse.page.pageSize}
              items={elementListResponse.elementList}
              totalPages={elementListResponse.page.pageCount}
              onPaginationParamsChange={(pageNumber, elementsPerPage) =>
                handlePaginationChange(pageNumber, elementsPerPage)
              }
              disablePageSizeChange
            />
          )}
          <ElementFiltersModal
            show={showFilters}
            setShow={setShowFilters}
            elementCategories={categoryList}
            elementListRequest={getElementListRequest}
            setElementListRequest={setGetElementListRequest}
          />
        </>
      )}
      {showScanner && (
        <QrCodeScanner
          onClose={() => setShowScanner(false)}
          onDecode={text => filterBySKU(text)}
        />
      )}
    </>
  );
}

interface WarehouseElementRowProps {
  element: ElementModel;
  index: number;
  refetch: () => void;
  setActiveKey: (key: string) => void;
}

function WarehouseElementRow(props: WarehouseElementRowProps) {
  const { element, index, refetch, setActiveKey } = props;
  const [newElementQuantity, setNewElementQuantity] = useState(
    element.warehouseQuantity.toString()
  );
  const [editMode, setEditMode] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const navigate = useNavigate();

  const {
    showQuantityConfirmationModal,
    setShowQuantityConfirmationModal,
    callChangeElementQuantity,
  } = useWarehouseElementQuantityState(parseInt(newElementQuantity));

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleConfirmationClick = () => {
    setShowQuantityConfirmationModal(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setNewElementQuantity(element.warehouseQuantity.toString());
  };

  const handleCraftElementClick = (elementId: string) => {
    navigate(`${NavigationPath.CRAFT_ELEMENT}/${elementId}`);
  };

  const handleQuantityChange = (event: any) => {
    setNewElementQuantity(
      event.target.validity.valid ? event.target.value : '0'
    );
  };

  const handleWarehouseElementModification = () => {
    callChangeElementQuantity({
      id: element.id,
      newQuantity: parseInt(newElementQuantity),
      oldQuantity: element.warehouseQuantity,
    }).then((response: any) => {
      if (!response.error) {
        setEditMode(false);
        setShowQuantityConfirmationModal(false);
        refetch();
        setActiveKey(index.toString());
      }
    });
  };

  return (
    <>
      <Row
        className={'border-bottom border-1 pe-3 pt-3 pb-3'}
        key={element.stockKeepingUnit}>
        <Col xs={3}>
          <Image
            src={getThumbnailUrl(element.imageId)}
            fluid
            className={`${commonStyles.thumbnailImage}`}
            onClick={() => setShowImageModal(true)}
          />
          <ImageZoomModal
            showImageModal={showImageModal}
            setShowImageModal={setShowImageModal}
            imageId={element.imageId}
          />
        </Col>
        <Col xs={5}>
          <div>{element.name}</div>
          <div className={commonStyles.secondaryText}>
            {element.stockKeepingUnit}
          </div>
        </Col>
        <Col xs={3}>
          <div>Ilość</div>
          <div className={commonStyles.secondaryText}>
            {element.warehouseQuantity}
          </div>
        </Col>
        <Col
          xs={1}
          className={'d-flex justify-content-center'}>
          <AccordionToggle eventKey={index.toString()} />
        </Col>
        <Accordion.Collapse eventKey={index.toString()}>
          <>
            <Row className={'mt-3'}>
              <Col xs={5}>
                <div className={commonStyles.secondaryText}>Nazwa</div>
                <div>{element.name}</div>
              </Col>
              <Col xs={5}>
                <div className={commonStyles.secondaryText}>SKU</div>
                <div>{element.stockKeepingUnit}</div>
              </Col>
            </Row>
            <Row className={'mt-3 pe-2'}>
              <Col xs={3}>
                <div className={commonStyles.secondaryText}>Kategoria</div>
                <div>{element.category}</div>
              </Col>
              <Col xs={3}>
                <div className={commonStyles.secondaryText}>Komplet</div>
                <div>{element.isElementSet ? 'Tak' : 'Nie'}</div>
              </Col>
              <Col xs={3}>
                <div className={commonStyles.secondaryText}>Ilość</div>
                <div>
                  {!editMode && element.warehouseQuantity}
                  {editMode && (
                    <Form.Control
                      type='number'
                      defaultValue={newElementQuantity ?? 0}
                      min='0'
                      onChange={handleQuantityChange}
                    />
                  )}
                </div>
              </Col>
              <Col xs={3}>
                {editMode && (
                  <Row className={'justify-content-around'}>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        size={'2x'}
                        className={`${commonStyles.iconGreen}`}
                        onClick={() => handleConfirmationClick()}
                      />
                    </Col>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        icon={faXmark}
                        size={'2x'}
                        className={`${commonStyles.iconRed}`}
                        onClick={() => handleCancelClick()}
                      />
                    </Col>
                  </Row>
                )}
                {!editMode && element.isElementSet && (
                  <Row className={'justify-content-around'}>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        icon={faPlus}
                        size={'2x'}
                        className={`${commonStyles.iconPrimary}`}
                        onClick={() => handleCraftElementClick(element.id)}
                      />
                    </Col>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        size={'2x'}
                        className={`${commonStyles.iconPrimary}`}
                        onClick={() => handleEditClick()}
                      />
                    </Col>
                  </Row>
                )}
                {!editMode && !element.isElementSet && (
                  <Row className={'justify-content-end pe-2'}>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        size={'2x'}
                        className={`${commonStyles.iconPrimary}`}
                        onClick={() => handleEditClick()}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </>
        </Accordion.Collapse>
      </Row>
      <QuantityChangeConfirmationModal
        show={showQuantityConfirmationModal}
        setShow={setShowQuantityConfirmationModal}
        title={'Zaktualizowanie ilości elementów'}
        bodyText={`Czy na pewno chcesz ustawić stan magazynu dla elementu 
          ${element.name} na ilość ${newElementQuantity}?`}
        refetch={refetch}
        handleQuantityChange={handleWarehouseElementModification}
      />
    </>
  );
}
