import { Col, Row } from 'react-bootstrap';
import { ModuleFilters } from '../../../components/modules/ModuleFilters';
import { ItemType } from '../../../data/item-model';
import { useModules } from '../../../api/modules';
import { useCategories } from '../../../api/categories';
import { CenteredSpinner } from '../../../components/CenteredSpinner';
import CustomPagination from '../../../components/CustomPagination';
import { useVariants } from '../../../api/variants';
import {
  CustomColumn,
  CustomTable,
} from '../../../components/table/CustomTable';
import { ImageRenderer } from '../../../components/table/renderers/ImageRenderer';
import { WarehouseModuleActionButtonsRenderer } from '../../../components/table/renderers/action-buttons/WarehouseActionButtonsRenderer';
import { WarehouseQuantityRenderer } from '../../../components/table/renderers/WarehouseQuantityRenderer';
import { useWarehouseModuleQuantityState } from '../../../components/table/field-states/WarehouseQuantityState';

const columns: CustomColumn[] = [
  {
    name: '',
    field: 'imageId',
    className: 'col-sm-1',
    renderer: ImageRenderer,
  },
  {
    name: 'Nazwa',
    field: 'name',
    className: 'col-sm-2',
  },
  {
    name: 'SKU',
    field: 'stockKeepingUnit',
    className: 'col-sm-2',
  },
  {
    name: 'Kategoria',
    field: 'category',
    className: 'col-sm-2',
  },
  {
    name: 'Wariant',
    field: 'variant',
    className: 'col-sm-2',
  },
  {
    name: 'Ilość',
    field: 'warehouseQuantity',
    className: 'col-sm-1',
    renderer: WarehouseQuantityRenderer,
    prepareInitialState: useWarehouseModuleQuantityState,
  },
  {
    name: '',
    field: '',
    className: 'col-sm-2',
    renderer: WarehouseModuleActionButtonsRenderer,
  },
];

export default function WarehouseModules() {
  const {
    moduleListResponse,
    isLoading,
    getModuleListRequest,
    handlePaginationChange,
    setGetModuleListRequest,
  } = useModules();

  const { categoryList } = useCategories(ItemType.MODULE);
  const { variantList } = useVariants();

  const isModuleListEmpty = moduleListResponse.moduleList.length === 0;

  return (
    <>
      <Row className='align-items-sm-center'>
        <Col sm='10'>
          <ModuleFilters
            moduleCategories={categoryList}
            moduleVariants={variantList}
            moduleListRequest={getModuleListRequest}
            setModuleListRequest={setGetModuleListRequest}
          />
        </Col>
      </Row>
      {isLoading && <CenteredSpinner />}
      {!isLoading && (
        <CustomTable
          columns={columns}
          data={moduleListResponse.moduleList}
          emptyListMessage='Brak schematów modułów.'
          refetch={() => setGetModuleListRequest({ ...getModuleListRequest })}
        />
      )}
      {!isModuleListEmpty && (
        <CustomPagination
          pageNumber={moduleListResponse.page.pageNumber + 1}
          elementsPerPage={moduleListResponse.page.pageSize}
          items={moduleListResponse.moduleList}
          totalPages={moduleListResponse.page.pageCount}
          onPaginationParamsChange={(pageNumber, elementsPerPage) =>
            handlePaginationChange(pageNumber, elementsPerPage)
          }
        />
      )}
    </>
  );
}
