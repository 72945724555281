import { createBrowserRouter, Navigate } from 'react-router-dom';
import { NavigationPath } from '../data/constants';
import React from 'react';
import { SignIn } from '../universal-view/sign-in/SignIn';
import { RootPage } from '../mobile-view/root-page/RootPage';
import { Warehouse } from '../mobile-view/warehouse/Warehouse';
import { Products } from '../mobile-view/products/Products';
import { CraftElementSet } from '../mobile-view/crafting/CraftElementSet';
import { CraftModule } from '../mobile-view/crafting/CraftModule';
import { CraftProduct } from '../mobile-view/crafting/CraftProduct';

export const mobileRouter = createBrowserRouter([
  {
    path: NavigationPath.SIGN_IN,
    element: <SignIn />,
  },
  {
    path: NavigationPath.ROOT,
    element: <RootPage />,
    children: [
      {
        path: NavigationPath.ROOT,
        element: (
          <Navigate
            to={NavigationPath.WAREHOUSE}
            replace
          />
        ),
      },
      {
        path: NavigationPath.WAREHOUSE,
        element: <Warehouse />,
      },
      {
        path: NavigationPath.PRODUCTS,
        element: <Products />,
      },
      {
        path: '*',
        element: <Navigate to={NavigationPath.WAREHOUSE} />,
      },
      {
        path: `${NavigationPath.CRAFT_ELEMENT}/:elementId`,
        element: <CraftElementSet />,
      },
      {
        path: `${NavigationPath.CRAFT_MODULE}/:moduleId`,
        element: <CraftModule />,
      },
      {
        path: `${NavigationPath.CRAFT_PRODUCT}/:productId`,
        element: <CraftProduct />,
      },
    ],
  },
]);
