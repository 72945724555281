import { usePostAction } from '../http-client';

export interface UpdateModuleRequest {
  id: string;
  name: string;
  stockKeepingUnit: string;
  category: string;
  variant: string;
  imageId: string;
  elements: Element[];
}

interface Element {
  id: string;
  schemaQuantity: number;
}

export function useUpdateModule() {
  const { callPost } = usePostAction<UpdateModuleRequest, void>(
    '/update-module'
  );
  return { updateModule: callPost };
}
