import { RouterProvider } from 'react-router-dom';
import React from 'react';
import { clarityApi } from '../api/clarity/clarity';
import { desktopRouter } from './desktop-router';
import { mobileRouter } from './mobile-router';
import { LoadingOverlay } from '../components/loading-overlay/LoadingOverlay';

function initClarity(): void {
  if (process.env.REACT_APP_CLARITY_ENABLED === 'true') {
    clarityApi.init(process.env.REACT_APP_CLARITY_PROJECT_ID!);
  }
}

function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
}

function App() {
  initClarity();

  return (
    <LoadingOverlay>
      {isMobileDevice() ? (
        <RouterProvider router={mobileRouter} />
      ) : (
        <RouterProvider router={desktopRouter} />
      )}
    </LoadingOverlay>
  );
}

export default App;
