import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { SpinnerContext } from '../../context/SpinnerContext';
import styles from './LoadingOverlay.module.scss';
import { debounce } from 'lodash';

export const LoadingOverlay = (props: { children: ReactNode }) => {
  const [isShowSpinner, setShowSpinner] = useState(false);
  useMemo(() => debounce(setShowSpinner, 0), [setShowSpinner]);
  const showSpinner = useCallback(() => setShowSpinner(true), [setShowSpinner]);
  const hideSpinner = useCallback(
    () => setShowSpinner(false),
    [setShowSpinner]
  );
  const spinnerProps = useMemo(
    () => ({
      showSpinner: showSpinner,
      hideSpinner: hideSpinner,
    }),
    [showSpinner, hideSpinner]
  );
  return (
    <>
      <SpinnerContext.Provider value={spinnerProps}>
        {props.children}
      </SpinnerContext.Provider>
      {isShowSpinner && (
        <div className={styles.overlay}>
          <div className={styles.spinner}>
            <img
              src='/logo192.png'
              alt='logo'
            />
          </div>
        </div>
      )}
    </>
  );
};
