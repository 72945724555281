import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiError, apiSuccess } from '../../../api/http-client';
import { useSetTitle } from '../../../context/TitleContext';
import { SchemaFormModal } from '../../../components/form/SchemaFormModal';
import {
  GetSingleModuleRequest,
  useGetSingleModule,
} from '../../../api/modules/get-single-module';
import { useUpdateModule } from '../../../api/modules/update-module';
import {
  ModuleSchemaForm,
  ModuleSchemaFormModel,
} from '../../../components/modules/ModuleSchemaForm';
import { CenteredSpinner } from '../../../components/CenteredSpinner';

const EditModuleSchema = () => {
  useSetTitle('Schematy - Edytuj moduł');
  const { moduleSchemaId } = useParams();
  const { updateModule } = useUpdateModule();

  const [request] = useState<GetSingleModuleRequest>({ id: moduleSchemaId! });
  const { response } = useGetSingleModule(request);
  const module = response?.data;
  const [showAfterSubmitModal, setShowAfterSubmitModal] = useState(false);

  const handleSubmit = async (form: ModuleSchemaFormModel) => {
    return updateModule({
      id: module?.id!!,
      name: form.name,
      stockKeepingUnit: form.stockKeepingUnit,
      category: form.category,
      variant: form.variant,
      imageId: form.imageId,
      elements: form.elementList.map(it => ({
        id: it.id,
        schemaQuantity: it.schemaQuantity,
      })),
    })
      .then(response => {
        setShowAfterSubmitModal(true);
        return apiSuccess(response);
      })
      .catch(e => {
        return apiError(e);
      });
  };

  return module ? (
    <>
      <ModuleSchemaForm
        onSubmit={handleSubmit}
        moduleSchema={module}
        editMode={true}
      />
      <SchemaFormModal
        show={showAfterSubmitModal}
        setShow={setShowAfterSubmitModal}
        title={'Edytowano schemat modułu'}
        body={
          'Udało się edytować schemat modułu! Wróć do listy lub dokonaj kolejnych zmian.'
        }
        backButtonText={'Wróć do listy'}
        nextButtonText={'Dokonaj kolejnych zmian'}
      />
    </>
  ) : (
    <CenteredSpinner />
  );
};

export { EditModuleSchema };
