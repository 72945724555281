import { CustomColumn, CustomTable } from '../table/CustomTable';
import { ImageRenderer } from '../table/renderers/ImageRenderer';
import { SubModulesTableProps } from './SubModulesTable';
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useModules } from '../../api/modules';
import { ItemType } from '../../data/item-model';
import { useCategories } from '../../api/categories';
import { useVariants } from '../../api/variants';
import { ModuleFilters } from './ModuleFilters';
import { CenteredSpinner } from '../CenteredSpinner';
import CustomPagination from '../CustomPagination';
import { AddSubModulesActionButtonsRenderer } from '../table/renderers/action-buttons/AddSubModulesActionButtonsRenderer';

const columns: CustomColumn[] = [
  {
    name: '',
    field: 'imageId',
    className: 'col-sm-2',
    renderer: ImageRenderer,
  },
  {
    name: 'Nazwa',
    field: 'name',
    className: 'col-sm-2',
  },
  {
    name: 'SKU',
    field: 'stockKeepingUnit',
    className: 'col-sm-2',
  },
  {
    name: 'Kategoria',
    field: 'category',
    className: 'col-sm-2',
  },
  {
    name: 'Wariant',
    field: 'variant',
    className: 'col-sm-2',
  },
  {
    name: '',
    field: '',
    className: 'col-sm-2',
    renderer: AddSubModulesActionButtonsRenderer,
  },
];

export interface AddSubModulesProps {
  show: boolean;
  setShow: (show: boolean) => void;
  subModulesTableProps: SubModulesTableProps;
}

export const AddSubModulesModal = (props: AddSubModulesProps) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.setShow(false)}
      size={'xl'}>
      <Modal.Header closeButton>
        <Modal.Title>Wybierz moduły</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModulesTable subModulesTableProps={props.subModulesTableProps} />
      </Modal.Body>
    </Modal>
  );
};

function ModulesTable({
  subModulesTableProps,
}: Readonly<{ subModulesTableProps: SubModulesTableProps }>) {
  const {
    moduleListResponse,
    isLoading,
    getModuleListRequest,
    handlePaginationChange,
    setGetModuleListRequest,
  } = useModules();

  const { categoryList } = useCategories(ItemType.MODULE);
  const { variantList } = useVariants();

  const isModuleListEmpty = () => {
    return moduleListResponse.moduleList.length === 0;
  };

  return (
    <div className={'m-3'}>
      <Row className={'align-items-sm-center'}>
        <Col sm={10}>
          <ModuleFilters
            moduleCategories={categoryList}
            moduleVariants={variantList}
            moduleListRequest={getModuleListRequest}
            setModuleListRequest={setGetModuleListRequest}
          />
        </Col>
      </Row>
      {isLoading && <CenteredSpinner />}
      {!isLoading && (
        <CustomTable
          columns={columns}
          data={moduleListResponse.moduleList}
          emptyListMessage='Nie znaleziono żadnych modułów.'
          refetch={() => {
            setGetModuleListRequest({ ...getModuleListRequest });
          }}
          externalProps={subModulesTableProps}
        />
      )}

      {!isModuleListEmpty() && (
        <CustomPagination
          pageNumber={moduleListResponse.page.pageNumber + 1}
          elementsPerPage={moduleListResponse.page.pageSize}
          totalPages={moduleListResponse.page.pageCount}
          items={moduleListResponse.moduleList}
          onPaginationParamsChange={(pageNumber, elementsPerPage) => {
            handlePaginationChange(pageNumber, elementsPerPage);
          }}
        />
      )}
    </div>
  );
}
