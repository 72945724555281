import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { CenteredSpinner } from '../../../components/CenteredSpinner';
import CustomPagination from '../../../components/CustomPagination';
import { useCategories } from '../../../api/categories';
import { ItemType } from '../../../data/item-model';
import { NavigationPath } from '../../../data/constants';
import { useNavigate } from 'react-router-dom';
import { RoleFilter } from '../../../components/RoleFilter';
import { UserRole } from '../../../api/auth/token-content';
import {
  CustomColumn,
  CustomTable,
} from '../../../components/table/CustomTable';
import { SchemaActionButtonsRenderer } from '../../../components/table/renderers/action-buttons/SchemaActionButtonsRenderer';
import { ImageRenderer } from '../../../components/table/renderers/ImageRenderer';
import { useSchemaProductActionButtonsState } from '../../../components/table/field-states/SchemaActionButtonsState';
import { useProducts } from '../../../api/products';
import { ProductFilters } from '../../../components/products/ProductFilters';
import download from 'downloadjs';
import {
  GenerateProductQrCodesRequest,
  useGenerateProductQrCodes,
} from '../../../api/products/generate-product-qr-codes';

const columns: CustomColumn[] = [
  {
    name: '',
    field: 'imageId',
    className: 'col-sm-1',
    renderer: ImageRenderer,
  },
  {
    name: 'Nazwa',
    field: 'name',
    className: 'col-sm-3',
  },
  {
    name: 'SKU',
    field: 'stockKeepingUnit',
    className: 'col-sm-2',
  },
  {
    name: 'Kategoria',
    field: 'category',
    className: 'col-sm-2',
  },
  {
    name: 'EAN',
    field: 'europeanArticleNumber',
    className: 'col-sm-2',
  },
  {
    name: '',
    field: 'actionButtons',
    className: 'col-sm-2',
    renderer: SchemaActionButtonsRenderer,
    prepareInitialState: useSchemaProductActionButtonsState,
  },
];

export default function ProductSchemas() {
  const navigate = useNavigate();
  const {
    productListResponse,
    isLoading,
    getProductListRequest,
    handlePaginationChange,
    setGetProductListRequest,
  } = useProducts();

  const { callGenerateProductQrCodes } = useGenerateProductQrCodes();

  const { categoryList } = useCategories(ItemType.PRODUCT);
  const navigateToAddProductSchema = () => {
    navigate(NavigationPath.ADD_PRODUCT_SCHEMA);
  };

  const isProductListEmpty = productListResponse.productList.length === 0;

  const generateQRCodes = () => {
    const generateProductQrCodesRequest: GenerateProductQrCodesRequest = {
      name: getProductListRequest.name,
      stockKeepingUnit: getProductListRequest.stockKeepingUnit,
      category: getProductListRequest.category,
      europeanArticleNumber: getProductListRequest.europeanArticleNumber,
    };

    callGenerateProductQrCodes(generateProductQrCodesRequest).then(response => {
      if (!response.error && response.data) {
        download(response.data, 'kody-qr-produkty.zip');
      }
    });
  };

  return (
    <>
      <Row className='align-items-sm-center'>
        <Col sm='10'>
          <ProductFilters
            productCategories={categoryList}
            productListRequest={getProductListRequest}
            setProductListRequest={setGetProductListRequest}
          />
        </Col>
        <RoleFilter
          visibleForRoles={[UserRole.WAREHOUSE_ADMIN, UserRole.SYSTEM_ADMIN]}>
          <Col sm='2'>
            <Row className={'mt-2'}>
              <Col>
                <Button
                  variant='primary'
                  onClick={navigateToAddProductSchema}
                  className={'w-100'}>
                  <FontAwesomeIcon icon={faPlus} />
                  <span className='m-2'>Dodaj produkt</span>
                </Button>
              </Col>
            </Row>
            <Row className={'mt-2'}>
              <Col>
                <Button
                  variant={'outline-primary'}
                  onClick={generateQRCodes}
                  className='w-100'>
                  <FontAwesomeIcon icon={faQrcode} />
                  <span className={'m-2'}>Generuj kody QR</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </RoleFilter>
      </Row>
      {isLoading && <CenteredSpinner />}
      {!isLoading && (
        <CustomTable
          columns={columns}
          data={productListResponse.productList}
          emptyListMessage='Brak schematów produktów.'
          refetch={() => setGetProductListRequest({ ...getProductListRequest })}
        />
      )}
      {!isProductListEmpty && (
        <CustomPagination
          pageNumber={productListResponse.page.pageNumber + 1}
          elementsPerPage={productListResponse.page.pageSize}
          items={productListResponse.productList}
          totalPages={productListResponse.page.pageCount}
          onPaginationParamsChange={(pageNumber, elementsPerPage) =>
            handlePaginationChange(pageNumber, elementsPerPage)
          }
        />
      )}
    </>
  );
}
