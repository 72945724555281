import { usePostFetch } from '../http-client';
import { ProductModel } from '../../data/product-model';

export interface GetSingleProductRequest {
  id: string;
}

export function useGetSingleProduct(request: GetSingleProductRequest) {
  const { response } = usePostFetch<GetSingleProductRequest, ProductModel>(
    '/get-single-product',
    request
  );

  return { response };
}
