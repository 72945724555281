import { Tab, Tabs } from 'react-bootstrap';
import { MyAccount } from './my-account/MyAccount';
import { useSetTitle } from '../../context/TitleContext';
import { UsersList } from './users-list/UsersList';
import { UserRole } from '../../api/auth/token-content';
import { RoleFilter } from '../../components/RoleFilter';

export function Settings() {
  useSetTitle('Ustawienia');
  return (
    <>
      <RoleFilter visibleForRoles={[UserRole.SYSTEM_ADMIN]}>
        <Tabs
          mountOnEnter
          unmountOnExit
          className='border-bottom border-4 h4'>
          <Tab
            eventKey='myAccount'
            title='Moje konto'>
            <MyAccount />
          </Tab>
          <Tab
            eventKey='users'
            title='Użytkownicy'>
            <UsersList />
          </Tab>
        </Tabs>
      </RoleFilter>
      <RoleFilter
        visibleForRoles={[UserRole.WAREHOUSE_ADMIN, UserRole.EMPLOYEE]}>
        <Tabs className='border-bottom border-4 h4'>
          <Tab
            eventKey='myAccount'
            title='Moje konto'>
            <MyAccount />
          </Tab>
        </Tabs>
      </RoleFilter>
    </>
  );
}
