import { usePostAction } from '../http-client';

export interface CraftModuleRequest {
  id: string;
  craftQuantity: number;
}

export function useCraftModule() {
  const { callPost } = usePostAction<CraftModuleRequest, void>('/craft-module');
  return { callCraftModule: callPost };
}
