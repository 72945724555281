import { Form } from 'react-bootstrap';
import { WarehouseQuantityState } from '../field-states/WarehouseQuantityState';
import { BaseRendererType, CustomTableState } from '../CustomTable';

export function SchemaQuantityRenderer(props: BaseRendererType): JSX.Element {
  const tableState: CustomTableState = props.tableState;
  const schemaState: WarehouseQuantityState = props.cellStates[props.field];

  const handleQuantityChange = (event: any) => {
    schemaState.setQuantity(
      event.target.value !== '' && event.target.validity.valid
        ? parseInt(event.target.value)
        : 0
    );
  };

  const isEditMode = () => {
    return tableState.object.isEditMode;
  };

  return (
    <>
      {isEditMode() ? (
        <Form.Control
          type='number'
          defaultValue={schemaState.quantity ?? 0}
          min='0'
          onChange={handleQuantityChange}
        />
      ) : (
        schemaState.quantity ?? 0
      )}
    </>
  );
}
