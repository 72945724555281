import { useState } from 'react';
import {
  GetProductListRequest,
  useGetProductList,
} from './products/get-product-list';

const INITIAL_PRODUCT_LIST_RESPONSE = {
  productList: [],
  page: {
    pageNumber: 0,
    totalCount: 0,
    pageSize: 0,
    pageCount: 0,
  },
};

export function useProducts() {
  const [getProductListRequest, setGetProductListRequest] =
    useState<GetProductListRequest>({
      page: {
        pageNumber: 0,
        pageSize: 10,
      },
    });

  const { response: getProductListResponse } = useGetProductList(
    getProductListRequest
  );

  const productListResponse =
    getProductListResponse?.data ?? INITIAL_PRODUCT_LIST_RESPONSE;

  const isLoading = getProductListResponse?.isLoading ?? false;

  const handlePaginationChange = (
    pageNumber: number,
    productsPerPage: number
  ) => {
    setGetProductListRequest({
      ...getProductListRequest,
      page: {
        pageNumber: pageNumber - 1,
        pageSize: productsPerPage,
      },
    });
  };

  return {
    productListResponse,
    isLoading,
    getProductListRequest,
    setGetProductListRequest,
    handlePaginationChange,
  };
}
