import { clarityInit } from './clarity-init/clarity-init';

//https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api
class ClarityApi {
  private get clarity(): (...args: any[]) => void {
    return (window as any).clarity;
  }

  public init(projectId: string) {
    if (!this.hasStarted()) {
      clarityInit(projectId);
    }
  }

  public consent(): void {
    if (this.hasStarted()) {
      this.clarity('consent');
    } else {
      console.warn('Clarity not started');
    }
  }

  public identify(
    userId: string,
    customSessionId: string = '',
    customPageId: string = '',
    friendlyName: string = ''
  ) {
    if (this.hasStarted()) {
      this.clarity(
        'identify',
        userId,
        customSessionId,
        customPageId,
        friendlyName
      );
    } else {
      console.warn('Clarity not started');
    }
  }

  public setTag(key: string, value: string): void {
    if (this.hasStarted()) {
      this.clarity('set', key, value);
    } else {
      console.warn('Clarity not started');
    }
  }

  public riseEvent(eventName: string): void {
    if (this.hasStarted()) {
      this.clarity('event', eventName);
    } else {
      console.warn('Clarity not started');
    }
  }

  public increaseRecordingPriority(reason: string): void {
    if (this.hasStarted()) {
      this.clarity('upgrade', reason);
    } else {
      console.warn('Clarity not started');
    }
  }

  private hasStarted() {
    return typeof this.clarity === 'function';
  }
}

export const clarityApi: ClarityApi = new ClarityApi();
