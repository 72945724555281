import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface ActiveFilterTileProps {
  name: string;
  value: any;
  mapFunction?: (value: any) => string;
  onRemove: () => void;
}

export function ActiveFilterTile(props: ActiveFilterTileProps) {
  const { name, value, mapFunction, onRemove } = props;
  return value !== undefined && value !== null && value !== '' ? (
    <Col
      xs={6}
      className={'pt-2 p-2 text-break'}>
      <div className={'border border-1 border-primary p-1'}>
        <span className={'text-primary'}>{name}: </span>
        <span className={'fw-bold text-primary'}>
          {mapFunction ? mapFunction(value) : value}
        </span>
        <span className={'float-end align-middle'}>
          {' '}
          <FontAwesomeIcon
            icon={faXmark}
            onClick={onRemove}
          />{' '}
        </span>
      </div>
    </Col>
  ) : null;
}
