import React, { useContext } from 'react';
import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../../context/UserContextProvider';
import { roleMappings } from '../../../api/auth/token-content';
import { authProvider } from '../../../api/auth/auth';

export function MyAccount() {
  const user = useContext(UserContext);

  return (
    <>
      <Row className='justify-content-between pt-4 pb-4'>
        <Col sm='2'>
          <h4>Dane konta</h4>
        </Col>
        <Col
          sm='2'
          className='ps-2'>
          <Button
            variant='primary'
            onClick={authProvider.navigateToPasswordUpdate}
            className='w-100'>
            <FontAwesomeIcon icon={faPenToSquare} />
            <span className='m-3'>Zmień hasło</span>
          </Button>
        </Col>
      </Row>
      <Row className='justify-content-sm-center align-items-sm-center mt-5'>
        <Col sm='5'>
          <Form className='w-100'>
            <MyAccountFormGroup
              label='Login (e-mail)'
              value={user.email}
            />
            <MyAccountFormGroup
              label='Imię'
              value={user.firstName}
            />
            <MyAccountFormGroup
              label='Nazwisko'
              value={user.lastName}
            />
            <MyAccountFormGroup
              label='Rola'
              value={roleMappings().get(user.role)}
            />
          </Form>
        </Col>
      </Row>
    </>
  );
}

interface MyAccountFormGroupProps {
  label: string;
  value: string;
}

const MyAccountFormGroup = (props: MyAccountFormGroupProps) => {
  return (
    <FormGroup
      as={Row}
      className='mb-3'>
      <Form.Label
        column
        sm={4}
        className='text-end'>
        {props.label}
      </Form.Label>
      <Col sm='8'>
        <Form.Control
          disabled={true}
          type='text'
          value={props.value}
        />
      </Col>
    </FormGroup>
  );
};
