import { UserRole } from '../api/auth/token-content';
import { UserContext } from '../context/UserContextProvider';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

interface ProtectedViewProps {
  visibleForRoles: UserRole[];
  children: React.ReactNode;
}

export const ProtectedView = ({
  visibleForRoles,
  children,
}: ProtectedViewProps) => {
  const user = useContext(UserContext);

  return (
    <>
      {visibleForRoles.includes(user.role) && children}
      {!visibleForRoles.includes(user.role) && <UnauthorizedView />}
    </>
  );
};

function UnauthorizedView() {
  return (
    <Row>
      <Col
        sm={12}
        className={'d-flex justify-content-center'}>
        <FontAwesomeIcon
          size={'3x'}
          icon={faBan}
          style={{ color: 'lightgray' }}
        />
      </Col>
      <Col
        sm={12}
        className={'d-flex justify-content-center'}>
        <span
          className={'h3'}
          style={{ color: 'lightgray' }}>
          Nie masz uprawnień do wyświetlenia tej strony.
        </span>
      </Col>
    </Row>
  );
}
