import { RenderMenuItemChildren } from 'react-bootstrap-typeahead/types/components/TypeaheadMenu/TypeaheadMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { AutocompleteOption } from './Autocomplete';

interface RenderAutocompleteItemProps {
  deleteValue: (id: string) => Promise<void>;
  refreshValues: () => void;
}

const RenderAutocompleteItem = ({
  deleteValue,
  refreshValues,
}: RenderAutocompleteItemProps): RenderMenuItemChildren => {
  return (option, menuProps, idx) => {
    const { id, label, canBeDeleted } = option as AutocompleteOption;
    const deleteIconColor = canBeDeleted ? 'red' : 'lightgray';
    const onClickDelete = async () => {
      if (canBeDeleted) {
        await deleteValue(id);
        refreshValues();
      }
    };
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <span>{label}</span>
        <FontAwesomeIcon
          icon={faTrashCan}
          color={deleteIconColor}
          onClick={() => onClickDelete()}
        />
      </div>
    );
  };
};

export { RenderAutocompleteItem };
