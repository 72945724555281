import { OperationType } from '../data/history-model';

export function warehouseOperationTypeMappings() {
  const operationTypeMapping = new Map();
  operationTypeMapping.set(OperationType.SCHEMA_CREATED, 'Utworzenie schematu');
  operationTypeMapping.set(OperationType.ITEM_CRAFTED, 'Wytworzenie');
  operationTypeMapping.set(OperationType.QUANTITY_MODIFIED, 'Zmiana stanu');
  return operationTypeMapping;
}

export function productsOperationTypeMappings() {
  const operationTypeMapping = new Map();
  operationTypeMapping.set(OperationType.SCHEMA_CREATED, 'Utworzenie schematu');
  operationTypeMapping.set(OperationType.ITEM_CRAFTED, 'Wytworzenie');
  return operationTypeMapping;
}
