import { Category } from '../../data/category-model';
import { Col, FloatingLabel, Form, FormGroup, Row } from 'react-bootstrap';
import React from 'react';
import { debounce } from 'lodash';
import { GetProductListRequest } from '../../api/products/get-product-list';

export interface ProductFiltersProps {
  productCategories: Category[];
  productListRequest: GetProductListRequest;
  setProductListRequest: (request: GetProductListRequest) => void;
}

export function ProductFilters(props: Readonly<ProductFiltersProps>) {
  const { productCategories, productListRequest, setProductListRequest } =
    props;

  const onNameChanged = debounce((name: string) => {
    setProductListRequest({
      ...productListRequest,
      name,
      page: { ...productListRequest.page, pageNumber: 0 },
    });
  }, 500);

  const onStockKeepingUnitChanged = debounce((stockKeepingUnit: string) => {
    setProductListRequest({
      ...productListRequest,
      stockKeepingUnit,
      page: {
        ...productListRequest.page,
        pageNumber: 0,
      },
    });
  }, 500);

  const onCategoryChange = (selectedCategory: string) => {
    const categoryValue =
      selectedCategory === '' ? undefined : selectedCategory;
    setProductListRequest({
      ...productListRequest,
      category: categoryValue,
      page: {
        ...productListRequest.page,
        pageNumber: 0,
      },
    });
  };

  const onEuropeanArticleNumberChanged = debounce(
    (europeanArticleNumber: string) => {
      setProductListRequest({
        ...productListRequest,
        europeanArticleNumber,
        page: {
          ...productListRequest.page,
          pageNumber: 0,
        },
      });
    },
    500
  );

  return (
    <Form className='pt-5 pb-5'>
      <Row>
        <Col
          sm='3'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='name'
              label='Nazwa'>
              <Form.Control
                type='text'
                placeholder='Nazwa'
                onChange={e => onNameChanged(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
        <Col
          sm='3'
          className='mb-2'>
          <FormGroup>
            <FloatingLabel
              controlId='stockKeepingUnit'
              label='SKU'>
              <Form.Control
                type='text'
                placeholder='Stock Keeping Unit'
                onChange={e => onStockKeepingUnitChanged(e.target.value)}
              />
            </FloatingLabel>
          </FormGroup>
        </Col>
        <Col
          sm='3'
          className='mb-2'>
          <FloatingLabel
            controlId='category'
            label='Kategoria'>
            <Form.Select onChange={e => onCategoryChange(e.target.value)}>
              <option value={''}>Wybierz kategorię...</option>
              {productCategories.map(category => {
                return (
                  <option
                    key={category.id}
                    value={category.label}>
                    {category.label}
                  </option>
                );
              })}
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Col
          sm='3'
          className='mb-2'>
          <FloatingLabel
            controlId='europeanArticleNumber'
            label='EAN'>
            <Form.Control
              type='text'
              placeholder='European Article Number'
              onChange={e => onEuropeanArticleNumberChanged(e.target.value)}
            />
          </FloatingLabel>
        </Col>
      </Row>
    </Form>
  );
}
