import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Category } from '../../data/category-model';
import { GetProductListRequest } from '../../api/products/get-product-list';
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  FormGroup,
  Modal,
  Row,
} from 'react-bootstrap';

export interface ProductFiltersModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  productCategories: Category[];
  productListRequest: GetProductListRequest;
  setProductListRequest: (request: GetProductListRequest) => void;
}

export function ProductFiltersModal(props: Readonly<ProductFiltersModalProps>) {
  const {
    productListRequest,
    setProductListRequest,
    show,
    setShow,
    productCategories,
  } = props;

  const [name, setName] = useState(productListRequest.name ?? '');
  const [stockKeepingUnit, setStockKeepingUnit] = useState(
    productListRequest.stockKeepingUnit ?? ''
  );
  const [category, setCategory] = useState(productListRequest.category ?? '');
  const [europeanArticleNumber, setEuropeanArticleNumber] = useState(
    productListRequest.europeanArticleNumber ?? ''
  );

  useEffect(() => {
    setCategory(productListRequest.category ?? '');
    setName(productListRequest.name ?? '');
    setStockKeepingUnit(productListRequest.stockKeepingUnit ?? '');
    setEuropeanArticleNumber(productListRequest.europeanArticleNumber ?? '');
  }, [productListRequest]);

  const onNameChanged = (name: string) => {
    setName(name);
  };

  const onStockKeepingUnitChanged = (stockKeepingUnit: string) => {
    setStockKeepingUnit(stockKeepingUnit);
  };

  const onCategoryChange = (selectedCategory: string) => {
    setCategory(selectedCategory);
  };

  const onEuropeanArticleNumberChange = (ean: string) => {
    setEuropeanArticleNumber(ean);
  };

  const onSaveClick = () => {
    setProductListRequest({
      name,
      stockKeepingUnit,
      category,
      europeanArticleNumber,
      page: {
        ...productListRequest.page,
        pageNumber: 0,
      },
    });
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size={'lg'}>
      <Modal.Header>
        <Modal.Title>Filtruj produkty</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className={'pt-2 pb-2'}>
          <Row>
            <Col className='mb-3'>
              <FormGroup>
                <FloatingLabel
                  controlId='name'
                  label='Nazwa'>
                  <Form.Control
                    value={name}
                    type='text'
                    placeholder='Nazwa'
                    onChange={e => onNameChanged(e.target.value)}
                  />
                </FloatingLabel>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className='mb-3'>
              <FormGroup>
                <FloatingLabel
                  controlId='stockKeepingUnit'
                  label='SKU'>
                  <Form.Control
                    value={stockKeepingUnit}
                    type='text'
                    placeholder='Stock Keeping Unit'
                    onChange={e => onStockKeepingUnitChanged(e.target.value)}
                  />
                </FloatingLabel>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className='mb-3'>
              <FormGroup>
                <FloatingLabel
                  controlId='europeanArticleNumber'
                  label='EAN'>
                  <Form.Control
                    value={europeanArticleNumber}
                    type='text'
                    placeholder='European Article Number'
                    onChange={e =>
                      onEuropeanArticleNumberChange(e.target.value)
                    }
                  />
                </FloatingLabel>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className='mb-3'>
              <FloatingLabel
                controlId='category'
                label='Kategoria'>
                <Form.Select
                  onChange={e => onCategoryChange(e.target.value)}
                  value={category}>
                  <option value={''}>Wybierz kategorię...</option>
                  {productCategories.map(category => {
                    return (
                      <option
                        key={category.id}
                        value={category.label}>
                        {category.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'outline-primary'}
          onClick={() => setShow(false)}
          className='me-auto'>
          Anuluj
        </Button>
        <Button
          variant={'primary'}
          onClick={onSaveClick}>
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
