import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import React, { useMemo, useState } from 'react';
import { useSetTitle } from '../../context/TitleContext';
import styles from './Crafting.module.scss';
import {
  PreviewElementSetCraftingRequest,
  usePreviewElementSetCrafting,
} from '../../api/elements/preview-element-set-crafting';
import { CenteredSpinner } from '../../components/CenteredSpinner';
import { useCraftElement } from '../../api/elements/craft-element';
import { PreviewCraftingElementSetMobileTable } from '../../components/crafting/mobile/PreviewCraftingElementSetMobileTable';

export function CraftElementSet() {
  useSetTitle('Wytwórz komplet elementów');
  const { elementId } = useParams();
  const [quantity, setQuantity] = useState(0);
  const request: PreviewElementSetCraftingRequest = useMemo(
    () => ({
      id: elementId!,
      craftQuantity: quantity,
    }),
    [elementId, quantity]
  );

  const { response: previewElementSetCraftingResponse } =
    usePreviewElementSetCrafting(request);

  const isCraftPossible = useMemo(() => {
    return (
      (previewElementSetCraftingResponse.data &&
        previewElementSetCraftingResponse.data.elementSet.craftedQuantity !==
          0) ||
      false
    );
  }, [previewElementSetCraftingResponse.data]);

  return (
    <>
      {previewElementSetCraftingResponse.isLoading && <CenteredSpinner />}
      {previewElementSetCraftingResponse.data && (
        <>
          <div className={`${styles.list} d-flex flex-column`}>
            <PreviewCraftingElementSetMobileTable
              elementSetList={[
                previewElementSetCraftingResponse.data.elementSet,
              ]}
              isCraftingRoot={true}
              onQuantityChange={setQuantity}
            />
          </div>

          <div className='m-auto'>
            <CraftElementButtons
              previewElementCraftingRequest={request}
              isCraftPossible={isCraftPossible}
            />
          </div>
        </>
      )}
    </>
  );
}

const CraftElementButtons = (props: {
  isCraftPossible: boolean;
  previewElementCraftingRequest: PreviewElementSetCraftingRequest;
}) => {
  const navigate = useNavigate();
  const { callCraftModule } = useCraftElement();

  const handleCreateClick = () => {
    callCraftModule({
      id: props.previewElementCraftingRequest.id,
      craftQuantity: props.previewElementCraftingRequest.craftQuantity,
    }).then(() => {
      navigate(-1);
    });
  };

  return (
    <Row className='justify-content-sm-center'>
      <Col className='mt-3 d-flex text-center'>
        <Button
          variant='outline-primary'
          onClick={() => {
            navigate(-1);
          }}>
          Anuluj
        </Button>
      </Col>
      <Col className='mt-3 d-flex text-center justify-content-end'>
        <Button
          onClick={() => handleCreateClick()}
          disabled={
            !props.isCraftPossible ||
            props.previewElementCraftingRequest.craftQuantity === 0
          }
          variant='primary'>
          Utwórz
        </Button>
      </Col>
    </Row>
  );
};
