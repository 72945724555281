import { Image } from 'react-bootstrap';
import { useState } from 'react';
import { getThumbnailUrl } from '../../../api/image/get-image';
import { ImageZoomModal } from '../../ImageZoomModal';
import commonStyles from '../../../desktop-view/Common.module.scss';
import { BaseRendererType } from '../CustomTable';

export function ImageRenderer(props: BaseRendererType): JSX.Element {
  const object = props.tableState.object;

  const [selectedImage, setSelectedImage] = useState('');
  const [showImageModal, setShowImageModal] = useState(false);

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setShowImageModal(true);
  };

  const imageId = object[props.field];
  return (
    <>
      <Image
        src={getThumbnailUrl(imageId)}
        fluid
        className={`${commonStyles.zoomableImage} ${commonStyles.thumbnailImage}`}
        onClick={() => handleImageClick(imageId)}
      />
      <ImageZoomModal
        showImageModal={showImageModal}
        setShowImageModal={setShowImageModal}
        imageId={selectedImage}
      />
    </>
  );
}
