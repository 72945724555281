import React, { useMemo, useState } from 'react';
import { ItemType } from '../../data/item-model';
import {
  Autocomplete,
  AutocompleteOption,
} from '../form/autocomplete/Autocomplete';
import { useAddCategory } from '../../api/category/add-category';
import {
  GetCategoryListRequest,
  GetCategoryListResponse,
  useGetCategoryList,
} from '../../api/category/get-catategory-list';
import { useDeleteCategory } from '../../api/category/delete-category';

interface CategoryListSelectProps {
  category: string;
  itemType: ItemType;
  setCategory: (value: string) => void;
}

function mapToAutocompleteOptions(
  response?: GetCategoryListResponse
): AutocompleteOption[] {
  if (response) {
    return response.categoryList.map(category => {
      return { ...category, canBeDeleted: !category.isUsed };
    });
  } else {
    return [];
  }
}

const CategoryListSelect = ({
  category,
  setCategory,
  itemType,
}: CategoryListSelectProps) => {
  const initialRequest: GetCategoryListRequest = { itemType };
  const { addCategory } = useAddCategory();
  const { deleteCategory } = useDeleteCategory();
  const [request, setRequest] = useState(initialRequest);
  const { getCategoryListResponse } = useGetCategoryList(request);
  const categoryList = useMemo(
    () => mapToAutocompleteOptions(getCategoryListResponse.data),
    [getCategoryListResponse]
  );
  const addNewValue = async (value: string) => {
    await addCategory({ label: value, itemType: itemType });
  };
  const refreshValues = () => {
    setRequest({ ...request });
  };
  const deleteValue = async (id: string) => {
    await deleteCategory({ id });
  };

  return (
    <Autocomplete
      inputId={'category'}
      placeholder={'Kategoria'}
      addNewLabel={'Dodaj nową kategorię'}
      emptyLabel={'Wpisz nazwę kategorii'}
      value={category}
      setValue={setCategory}
      addNewValue={addNewValue}
      values={categoryList}
      refreshValues={refreshValues}
      deleteValue={deleteValue}
    />
  );
};

export { CategoryListSelect };
