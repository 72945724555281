import {
  Button,
  Col,
  FloatingLabel,
  Form,
  FormGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { Category } from '../../data/category-model';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { GetElementListRequest } from '../../api/elements/get-element-list';

export interface ElementFiltersModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  elementCategories: Category[];
  elementListRequest: GetElementListRequest;
  setElementListRequest: (request: GetElementListRequest) => void;
}

export function ElementFiltersModal(props: Readonly<ElementFiltersModalProps>) {
  const {
    elementListRequest,
    setElementListRequest,
    show,
    setShow,
    elementCategories,
  } = props;
  const [name, setName] = useState(elementListRequest.name ?? '');
  const [stockKeepingUnit, setStockKeepingUnit] = useState(
    elementListRequest.stockKeepingUnit ?? ''
  );
  const [category, setCategory] = useState(elementListRequest.category ?? '');
  const [isElementSet, setIsElementSet] = useState(
    elementListRequest.isElementSet ?? undefined
  );

  useEffect(() => {
    setCategory(elementListRequest.category ?? '')
    setName(elementListRequest.name ?? '');
    setStockKeepingUnit(elementListRequest.stockKeepingUnit ?? '');
    setIsElementSet(elementListRequest.isElementSet ?? undefined);
  }, [elementListRequest]);
  
  const onNameChanged = (name: string) => {
    setName(name);
  };

  const onStockKeepingUnitChanged = (stockKeepingUnit: string) => {
    setStockKeepingUnit(stockKeepingUnit);
  };

  const onCategoryChange = (selectedCategory: string) => {
    setCategory(selectedCategory);
  };

  const onIsElementSetChange = (isElementSet: string) => {
    const isElementSetValue =
      isElementSet === '' ? undefined : isElementSet === 'true';
    setIsElementSet(isElementSetValue);
  };

  const onSaveClick = () => {
    setElementListRequest({
      name,
      stockKeepingUnit,
      category,
      isElementSet,
      page: {
        ...elementListRequest.page,
        pageNumber: 0,
      },
    });
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size={'lg'}>
      <Modal.Header>
        <Modal.Title>Filtruj elementy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className={'pt-2 pb-2'}>
          <Row>
            <Col className='mb-3'>
              <FormGroup>
                <FloatingLabel
                  controlId='name'
                  label='Nazwa'>
                  <Form.Control
                    value={name}
                    type='text'
                    placeholder='Nazwa'
                    onChange={e => onNameChanged(e.target.value)}
                  />
                </FloatingLabel>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className='mb-3'>
              <FormGroup>
                <FloatingLabel
                  controlId='stockKeepingUnit'
                  label='SKU'>
                  <Form.Control
                    value={stockKeepingUnit}
                    type='text'
                    placeholder='Stock Keeping Unit'
                    onChange={e => onStockKeepingUnitChanged(e.target.value)}
                  />
                </FloatingLabel>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className='mb-3'>
              <FloatingLabel
                controlId='category'
                label='Kategoria'>
                <Form.Select
                  onChange={e => onCategoryChange(e.target.value)}
                  value={category}>
                  <option value={''}>Wybierz kategorię...</option>
                  {elementCategories.map(category => {
                    return (
                      <option
                        key={category.id}
                        value={category.label}>
                        {category.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col className='mb-3'>
              <FloatingLabel
                controlId='isElementSet'
                label='Komplet'>
                <Form.Select
                  onChange={e => onIsElementSetChange(e.target.value)}
                  value={String(isElementSet)}>
                  <option value=''>Wybierz czy komplet...</option>
                  <option value='true'>Tak</option>
                  <option value='false'>Nie</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'outline-primary'}
          onClick={() => setShow(false)}
          className='me-auto'>
          Anuluj
        </Button>
        <Button
          variant={'primary'}
          onClick={onSaveClick}>
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
