import { PageParameters, PageResult } from '../../data/common-model';
import { ModuleModel } from '../../data/module-model';
import { usePostFetch } from '../http-client';

export interface GetModuleListRequest {
  name?: string;
  stockKeepingUnit?: string;
  category?: string;
  variant?: string;
  page: PageParameters;
}

export interface GetModuleListResponse {
  moduleList: ModuleModel[];
  page: PageResult;
}

export function useGetModuleList(request: GetModuleListRequest) {
  const { response } = usePostFetch<
    GetModuleListRequest,
    GetModuleListResponse
  >('/get-module-list', request);
  return { response };
}
