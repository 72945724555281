import { usePostAction } from '../http-client';

export interface ChangeElementQuantityRequest {
  id: string;
  oldQuantity: number;
  newQuantity: number;
}

export function useChangeElementQuantity() {
  const { callPost } = usePostAction<ChangeElementQuantityRequest, void>(
    '/change-element-quantity'
  );
  return { callChangeElementQuantity: callPost };
}
