import React from 'react';
import styles from './Topbar.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { LoginCard } from '../login-card/LoginCard';

interface TopBarProps {
  title: string;
}

function TopBar({ title }: TopBarProps) {
  return (
    <>
      <Container fluid>
        <Row className='justify-content-between align-items-center'>
          <Col
            sm={'auto'}
            className='p-0'>
            <h1 className={styles.pageTitle}>{title}</h1>
          </Col>
          <Col
            sm={'auto'}
            className='p-0'>
            <LoginCard />
          </Col>
        </Row>
      </Container>
      <div></div>
    </>
  );
}

export { TopBar };
export type { TopBarProps };
