import { Button, Col, Form, Row } from 'react-bootstrap';
import { FileUpload } from '../file-upload/FileUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormInput } from '../form/FormInput';
import { ItemType } from '../../data/item-model';
import { ElementSchemaModel, SubElementModel } from '../../data/element-model';
import { ApiResult } from '../../api/http-client';
import { CategoryListSelect } from '../category/CategoryListSelect';
import SubElementsTable from './SubElementsTable';

interface ElementSchemaFormProps {
  elementSchema?: ElementSchemaModel;
  onSubmit: (
    elementSchemaForm: ElementSchemaFormModel
  ) => Promise<ApiResult<any>>;
  editMode: boolean;
}

export interface ElementSchemaFormModel {
  name: string;
  stockKeepingUnit: string;
  category: string;
  imageId: string;
  isElementSet: boolean;
  subElementList: SubElementModel[];
}

export const ElementSchemaForm = (props: ElementSchemaFormProps) => {
  const [name, setName] = useState(props.elementSchema?.name ?? '');
  const [stockKeepingUnit, setStockKeepingUnit] = useState(
    props.elementSchema?.stockKeepingUnit ?? ''
  );
  const [category, setCategory] = useState(props.elementSchema?.category ?? '');
  const [isElementSet, setIsElementSet] = useState(
    props.elementSchema?.isElementSet ?? false
  );
  const [imageId, setImageId] = useState<string | undefined>(
    props.elementSchema?.imageId
  );
  const [imageInvalid, setImageInvalid] = useState(false);
  const [validated, setValidated] = useState(false);
  const [subElementList, setSubElementList] = useState(
    props.elementSchema?.subElementList ?? []
  );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    setImageInvalid(imageId === null || imageId === undefined);
    if (!form.checkValidity() || imageId === null || imageId === undefined) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    props.onSubmit({
      name: name,
      stockKeepingUnit: stockKeepingUnit,
      category: category,
      imageId: imageId,
      isElementSet: isElementSet,
      subElementList: isElementSet ? subElementList : [],
    });
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}>
      <Row className={'justify-content-sm-center align-items-sm-center'}>
        <Col sm={5}>
          <FormInput
            value={name}
            setValue={setName}
            controlId={'name'}
            label={'Nazwa'}
            errorText={'Nazwa jest wymagana'}
          />
          <FormInput
            value={stockKeepingUnit}
            setValue={setStockKeepingUnit}
            controlId={'stockKeepingUnit'}
            label={'Stock Keeping Unit'}
            errorText={'SKU jest wymagany'}
            disabled={props.editMode}
          />
          <CategorySelect
            category={category}
            setCategory={setCategory}
          />
          <ElementSchemaElementSetCheckbox
            isElementSet={isElementSet}
            setIsElementSet={setIsElementSet}
            disabled={props.editMode}
          />
          <div className='d-flex justify-content-center pb-5'>
            <FileUpload
              size={300}
              onFileUpload={setImageId}
              isInvalid={imageInvalid}
              fileId={imageId}
              onFileDelete={() => setImageId(undefined)}
            />
          </div>
        </Col>
      </Row>

      {isElementSet && (
        <SubElementsTable
          subElementList={subElementList}
          setSubElementList={setSubElementList}
          modificationElementId={props.elementSchema?.id}
          itemType={ItemType.ELEMENT}
        />
      )}
      <ElementSchemaFormButtons />
    </Form>
  );
};

interface CategorySelectProps {
  category: string;
  setCategory: (value: string) => void;
}

const CategorySelect = ({ category, setCategory }: CategorySelectProps) => {
  return (
    <Form.Group
      className='pb-4'
      controlId='category'>
      <CategoryListSelect
        category={category}
        itemType={ItemType.ELEMENT}
        setCategory={setCategory}
      />
      <Form.Control.Feedback type='invalid'>
        Kategoria jest wymagana
      </Form.Control.Feedback>
    </Form.Group>
  );
};

interface ElementSchemaElementSetCheckboxProps {
  isElementSet: boolean;
  setIsElementSet: (isElementSet: boolean) => void;
  disabled: boolean;
}

const ElementSchemaElementSetCheckbox = (
  checkboxProps: ElementSchemaElementSetCheckboxProps
) => {
  return (
    <Form.Group
      className='pb-5'
      controlId='isElementSet'>
      <Form.Check
        disabled={checkboxProps.disabled}
        label='Czy komplet?'
        type='checkbox'
        defaultChecked={checkboxProps.isElementSet}
        onChange={e => checkboxProps.setIsElementSet(e.target.checked)}
      />
    </Form.Group>
  );
};

const ElementSchemaFormButtons = () => {
  const navigate = useNavigate();

  return (
    <Row
      className='pt-2 justify-content-sm-center align-items-sm-center'
      style={{ backgroundColor: 'white', position: 'sticky', bottom: '0' }}>
      <Col sm={3} />
      <Col sm={2}>
        <Button
          variant='outline-primary'
          onClick={() => {
            navigate(-1);
          }}
          className='w-100 mb-2'>
          <FontAwesomeIcon icon={faXmark} />
          <span className='m-2'>Anuluj</span>
        </Button>
      </Col>
      <Col sm={2} />
      <Col sm={2}>
        <Button
          variant='primary'
          className='w-100 mb-2'
          type='submit'>
          <FontAwesomeIcon icon={faCheck} />
          <span className='m-2'>Zapisz</span>
        </Button>
      </Col>
      <Col sm={3} />
    </Row>
  );
};
