import { usePostAction } from '../http-client';

interface CreateUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
}

function useCreateUser() {
  const { callPost } = usePostAction<CreateUserRequest, void>('/create-user');

  return { createUser: callPost };
}

export { useCreateUser };
