import { useNavigate, useParams } from 'react-router-dom';
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import React, { useCallback, useMemo, useState } from 'react';
import { useSetTitle } from '../../context/TitleContext';
import styles from './Crafting.module.scss';
import { CenteredSpinner } from '../../components/CenteredSpinner';
import { PreviewCraftingElementSetMobileTable } from '../../components/crafting/mobile/PreviewCraftingElementSetMobileTable';
import commonStyles from '../Common.module.scss';
import { PreviewCraftingElementMobileTable } from '../../components/crafting/mobile/PreviewCraftingElementMobileTable';
import { PreviewCraftingModuleMobileTable } from '../../components/crafting/mobile/PreviewCraftingModuleMobileTable';
import {
  PreviewProductCraftingRequest,
  usePreviewProductCrafting,
} from '../../api/elements/preview-product-crafting';
import { useCraftProduct } from '../../api/elements/craft-product';
import { PreviewCraftingProductMobileTable } from '../../components/crafting/mobile/PreviewCraftingProductMobileTable';
import { AccordionToggle } from '../../components/AccordionToggle';

export function CraftProduct() {
  useSetTitle('Wytwórz produkt');
  const [activeTab, setActiveTab] = useState('products');
  const { productId } = useParams();
  const [quantity, setQuantity] = useState(0);
  const [elementToScrollId, setElementToScrollId] = useState<
    string | undefined
  >(undefined);
  const request: PreviewProductCraftingRequest = useMemo(
    () => ({
      id: productId!,
      craftQuantity: quantity,
    }),
    [productId, quantity]
  );
  const { response: previewProductCraftingResponse } =
    usePreviewProductCrafting(request);

  const onGoToElementSetsClick = useCallback(
    (id: string) => {
      setActiveTab('elements');
      setElementToScrollId(id);
    },
    [setActiveTab]
  );

  const onGoToActiveTabClick = useCallback(
    (id: string, activeTab: string) => {
      setActiveTab(activeTab);
      setElementToScrollId(id);
    },
    [setActiveTab]
  );

  const onGoToEnd = useCallback(() => {
    setElementToScrollId(undefined);
  }, [setElementToScrollId]);

  const isCraftPossible = useMemo(() => {
    return (
      (previewProductCraftingResponse.data &&
        previewProductCraftingResponse.data.product.craftedQuantity !== 0) ||
      false
    );
  }, [previewProductCraftingResponse.data]);

  const multipleTabs = {
    activeTab: activeTab,
    setActiveTab: setActiveTab,
  };

  return (
    <>
      {previewProductCraftingResponse.isLoading && <CenteredSpinner />}
      {previewProductCraftingResponse.data && (
        <>
          <div className={`${styles.list} d-flex flex-column`}>
            <Accordion
              alwaysOpen
              activeKey={activeTab}
              className={'pt-2 pb-2'}>
              <Row>
                <Col xs={11}>
                  <div className={commonStyles.dropdownTitleText}>Produkty</div>
                </Col>
                <Col
                  xs={1}
                  className={'d-flex justify-content-center ps-1'}>
                  <AccordionToggle
                    eventKey={'products'}
                    iconSize='xl'
                    multipleTabs={multipleTabs}
                  />
                </Col>
              </Row>
              <Accordion.Collapse
                className={'ps-1 pe-3'}
                eventKey={'products'}>
                <PreviewCraftingProductMobileTable
                  productList={[previewProductCraftingResponse.data.product]}
                  isCraftingRoot={true}
                  onQuantityChange={setQuantity}
                  onGoToClick={onGoToActiveTabClick}
                />
              </Accordion.Collapse>
            </Accordion>
            <div className={styles.listHorizontaLine} />
            <Accordion
              alwaysOpen
              activeKey={activeTab}
              className={'pt-3 pb-2'}>
              <Row>
                <Col xs={11}>
                  <div className={commonStyles.dropdownTitleText}>Moduły</div>
                </Col>
                <Col
                  xs={1}
                  className={'d-flex justify-content-center ps-1'}>
                  <AccordionToggle
                    eventKey={'modules'}
                    iconSize='xl'
                    multipleTabs={multipleTabs}
                  />
                </Col>
              </Row>
              <Accordion.Collapse
                className={'ps-1 pe-3'}
                eventKey={'modules'}>
                <PreviewCraftingModuleMobileTable
                  moduleList={previewProductCraftingResponse.data.moduleList}
                  isCraftingRoot={true}
                  onQuantityChange={setQuantity}
                  onGoToClick={onGoToActiveTabClick}
                  elementIdToScroll={elementToScrollId}
                  onGoToEnd={onGoToEnd}
                />
              </Accordion.Collapse>
            </Accordion>
            <div className={styles.listHorizontaLine} />
            {previewProductCraftingResponse.data.elementSetList.length > 0 && (
              <>
                <Accordion
                  className={`pt-3 pb-2`}
                  activeKey={activeTab}>
                  <Row>
                    <Col xs={11}>
                      <div className={commonStyles.dropdownTitleText}>
                        Komplety elementów
                      </div>
                    </Col>
                    <Col
                      xs={1}
                      className={'d-flex justify-content-center ps-1'}>
                      <AccordionToggle
                        eventKey={'elementSets'}
                        iconSize='xl'
                        multipleTabs={multipleTabs}
                      />
                    </Col>
                  </Row>
                  <Accordion.Collapse
                    className={'ps-1 pe-3'}
                    eventKey={'elementSets'}>
                    <PreviewCraftingElementSetMobileTable
                      elementSetList={
                        previewProductCraftingResponse.data.elementSetList
                      }
                      isCraftingRoot={false}
                      onGoToClick={onGoToElementSetsClick}
                      elementIdToScroll={elementToScrollId}
                      onGoToEnd={onGoToEnd}
                    />
                  </Accordion.Collapse>
                </Accordion>
                <div className={styles.listHorizontaLine} />
              </>
            )}
            <Accordion
              className={'pt-3 pb-2'}
              activeKey={activeTab}>
              <Row>
                <Col xs={11}>
                  <div className={commonStyles.dropdownTitleText}>Elementy</div>
                </Col>
                <Col
                  xs={1}
                  className={'d-flex justify-content-center ps-1'}>
                  <AccordionToggle
                    eventKey={'elements'}
                    iconSize='xl'
                    multipleTabs={multipleTabs}
                  />
                </Col>
              </Row>
              <Accordion.Collapse
                className={'ps-1 pe-3'}
                eventKey={'elements'}>
                <PreviewCraftingElementMobileTable
                  elementList={previewProductCraftingResponse.data.elementList}
                  elementIdToScroll={elementToScrollId}
                  onGoToEnd={onGoToEnd}
                />
              </Accordion.Collapse>
            </Accordion>
          </div>
          <div className='m-auto'>
            <CraftModuleButtons
              previewProductCraftingRequest={request}
              isCraftPossible={isCraftPossible}
            />
          </div>
        </>
      )}
    </>
  );
}

const CraftModuleButtons = (props: {
  isCraftPossible: boolean;
  previewProductCraftingRequest: PreviewProductCraftingRequest;
}) => {
  const navigate = useNavigate();
  const { callCraftProduct } = useCraftProduct();

  const handleCreateClick = () => {
    callCraftProduct({
      id: props.previewProductCraftingRequest.id,
      craftQuantity: props.previewProductCraftingRequest.craftQuantity,
    }).then(() => {
      navigate(-1);
    });
  };

  return (
    <Row className='justify-content-sm-center'>
      <Col className='mt-3 d-flex text-center'>
        <Button
          variant='outline-primary'
          onClick={() => {
            navigate(-1);
          }}>
          Anuluj
        </Button>
      </Col>
      <Col className='mt-3 d-flex text-center justify-content-end'>
        <Button
          onClick={() => handleCreateClick()}
          disabled={
            !props.isCraftPossible ||
            props.previewProductCraftingRequest.craftQuantity === 0
          }
          variant='primary'>
          Utwórz
        </Button>
      </Col>
    </Row>
  );
};
