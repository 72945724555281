import { GetWarehouseHistoryRequest } from '../../api/history/get-warehouse-history';
import React, { useMemo } from 'react';
import { Row } from 'react-bootstrap';
import { ActiveFilterTile } from '../ActiveFilterTile';
import { warehouseOperationTypeMappings } from '../../api/operation-type';
import moment from 'moment';

export interface ActiveWarehouseHistoryFiltersProps {
  getWarehouseHistoryRequest: GetWarehouseHistoryRequest;
  setGetWarehouseHistoryRequest: (request: GetWarehouseHistoryRequest) => void;
}

export function ActiveWarehouseHistoryFilters(
  props: Readonly<ActiveWarehouseHistoryFiltersProps>
) {
  const { getWarehouseHistoryRequest, setGetWarehouseHistoryRequest } = props;
  const operationTypeMapping = useMemo(
    () => warehouseOperationTypeMappings(),
    []
  );

  return (
    <Row>
      <ActiveFilterTile
        name={'SKU'}
        value={getWarehouseHistoryRequest.stockKeepingUnit}
        onRemove={() =>
          setGetWarehouseHistoryRequest({
            ...getWarehouseHistoryRequest,
            stockKeepingUnit: undefined,
          })
        }
      />
      <ActiveFilterTile
        name={'Email'}
        value={getWarehouseHistoryRequest.email}
        onRemove={() =>
          setGetWarehouseHistoryRequest({
            ...getWarehouseHistoryRequest,
            email: undefined,
          })
        }
      />
      <ActiveFilterTile
        name={'Typ operacji'}
        value={getWarehouseHistoryRequest.operationType}
        onRemove={() =>
          setGetWarehouseHistoryRequest({
            ...getWarehouseHistoryRequest,
            operationType: undefined,
          })
        }
        mapFunction={value => operationTypeMapping.get(value)}
      />
      <ActiveFilterTile
        name={'Data od'}
        value={getWarehouseHistoryRequest.dateFrom}
        onRemove={() =>
          setGetWarehouseHistoryRequest({
            ...getWarehouseHistoryRequest,
            dateFrom: undefined,
          })
        }
        mapFunction={value => moment(value).format('DD.MM.YYYY')}
      />
      <ActiveFilterTile
        name={'Data do'}
        value={getWarehouseHistoryRequest.dateTo}
        onRemove={() =>
          setGetWarehouseHistoryRequest({
            ...getWarehouseHistoryRequest,
            dateTo: undefined,
          })
        }
        mapFunction={value => moment(value).format('DD.MM.YYYY')}
      />
    </Row>
  );
}
