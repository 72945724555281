import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { authProvider } from './auth/auth';
import { ApiError } from '../data/error-model';
import { ErrorContext } from '../context/ErrorContext';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { SpinnerContext, SpinnerContextProps } from '../context/SpinnerContext';

export const authHttpClient = authProvider.decorateWithAuth(
  axios.create({ baseURL: '/api' })
);

interface ApiResult<T> {
  data?: T;
  error?: ApiError;
  isLoading: boolean;
}

function mapError(error: AxiosError<ApiError>): ApiError {
  const response = error.response?.data;
  return {
    id: response?.id ?? '',
    code: response?.code ?? 'UNKNOWN',
    message: response?.message ?? 'Wystąpił nieoczekiwany błąd',
  };
}

function apiError<T>(error: AxiosError<ApiError>): ApiResult<T> {
  return {
    error: mapError(error),
    isLoading: false,
  };
}

function apiSuccess<T>(data: T): ApiResult<T> {
  return { data, isLoading: false };
}

function apiLoading<T>(): ApiResult<T> {
  return { isLoading: true };
}

function usePostAction<REQ, RES>(
  path: string,
  handleError: boolean = true,
  config: AxiosRequestConfig = {},
  useGlobalSpinner: boolean = true
) {
  const { setErrorAndShow } = useContext(ErrorContext);
  const spinnerContext = useContext<SpinnerContextProps>(SpinnerContext);

  const callPost = useCallback(
    async (request: REQ) => {
      if (useGlobalSpinner) {
        spinnerContext.showSpinner();
      }
      return authHttpClient
        .post<RES>(path, request, config)
        .then(response => {
          if (useGlobalSpinner) {
            spinnerContext.hideSpinner();
          }
          return apiSuccess(response.data);
        })
        .catch(e => {
          const error = mapError(e);
          if (handleError) {
            setErrorAndShow(error);
          }
          spinnerContext.hideSpinner();
          return apiError<RES>(e);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path, handleError]
  );
  return { callPost };
}

function usePostFetch<REQ, RES>(path: string, request?: REQ) {
  const { callPost } = usePostAction<REQ, RES>(path, true, {}, false);
  const [response, setResponse] = useState<ApiResult<RES>>(apiLoading());
  useEffect(() => {
    setResponse(apiLoading());
    if (request) {
      callPost(request).then(it => {
        setResponse(it);
      });
    }
  }, [request, callPost, setResponse]);
  return { response };
}

function usePostFetchWithDebounce<REQ, RES>(path: string, request?: REQ) {
  const { callPost } = usePostAction<REQ, RES>(path, true, {}, false);
  const [response, setResponse] = useState<ApiResult<RES>>(apiLoading());
  const debounceSetResponse = useMemo(
    () => debounce(setResponse, 200),
    [setResponse]
  );
  useEffect(() => {
    debounceSetResponse(apiLoading());
    if (request) {
      callPost(request).then(it => {
        debounceSetResponse(it);
      });
    }
  }, [request, callPost, debounceSetResponse]);
  return { response };
}

export {
  apiError,
  apiSuccess,
  apiLoading,
  usePostAction,
  usePostFetch,
  usePostFetchWithDebounce,
};
export type { ApiResult };
