import { usePostFetchWithDebounce } from '../http-client';
import {
  ElementCraftingPreview,
  ElementSetCraftingPreview,
} from './preview-element-set-crafting';
import { ModuleCraftingPreview } from './preview-module-crafting';

export interface PreviewProductCraftingRequest {
  id: string;
  craftQuantity: number;
}

export interface PreviewProductCraftingResponse {
  product: ProductCraftingPreview;
  moduleList: ModuleCraftingPreview[];
  elementSetList: ElementSetCraftingPreview[];
  elementList: ElementCraftingPreview[];
}

export interface ProductCraftingPreview {
  id: string;
  name: string;
  stockKeepingUnit: string;
  category: string;
  variant: string;
  imageId: string;
  oldQuantity: number;
  requiredQuantity: number;
  newQuantity: number;
  craftedQuantity: number;
  europeanArticleNumber: string;
  subiItemList: ProductSubItemCraftingPreview[];
}

export interface ProductSubItemCraftingPreview {
  id: string;
  imageId: string;
  craftedQuantity: number;
  name: string;
  oldQuantity: number;
  newQuantity: number;
  category: string;
  stockKeepingUnit: string;
  requiredQuantity: number;
  europeanArticleNumber: string;
  variant: string;
}

export function usePreviewProductCrafting(
  request: PreviewProductCraftingRequest
) {
  const { response } = usePostFetchWithDebounce<
    PreviewProductCraftingRequest,
    PreviewProductCraftingResponse
  >('/preview-product-crafting', request);
  return { response };
}
