import { useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import commonStyles from '../../../../desktop-view/Common.module.scss';
import { BaseRendererType, CustomTableState } from '../../CustomTable';
import { NavigationPath } from '../../../../data/constants';

export function ProductOverviewActionButtonsRenderer(
  props: BaseRendererType
): JSX.Element {
  const tableState: CustomTableState = props.tableState;
  const navigate = useNavigate();

  const handleCraftElementClick = (object: any) => {
    navigate(`${NavigationPath.CRAFT_PRODUCT}/${object.id}`);
  };

  return (
    <>
      {
        <FontAwesomeIcon
          icon={faPlus}
          size='xl'
          className={`${commonStyles.iconPrimary} me-3`}
          onClick={() => handleCraftElementClick(tableState.object)}
        />
      }
    </>
  );
}
