import { SubModuleModel } from '../../data/module-model';
import { ApiResult } from '../../api/http-client';
import { ProductSchemaModel } from '../../data/product-model';
import React, { FormEvent, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormInput } from '../form/FormInput';
import { CategoryListSelect } from '../category/CategoryListSelect';
import { ItemType } from '../../data/item-model';
import { FileUpload } from '../file-upload/FileUpload';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import SubModulesTable from '../modules/SubModulesTable';

interface ProductSchemaFormProps {
  productSchema?: ProductSchemaModel;
  onSubmit: (
    productSchemaForm: ProductSchemaFormModel
  ) => Promise<ApiResult<any>>;
  editMode: boolean;
}

interface ProductSchemaFormModel {
  name: string;
  stockKeepingUnit: string;
  category: string;
  europeanArticleNumber: string;
  imageId: string;
  moduleList: SubModuleModel[];
}

const ProductSchemaForm = ({
  productSchema,
  onSubmit,
  editMode,
}: ProductSchemaFormProps) => {
  const [name, setName] = useState(productSchema?.name ?? '');
  const [stockKeepingUnit, setStockKeepingUnit] = useState(
    productSchema?.stockKeepingUnit ?? ''
  );
  const [category, setCategory] = useState(productSchema?.category ?? '');
  const [europeanArticleNumber, setEuropeanArticleNumber] = useState(
    productSchema?.europeanArticleNumber ?? ''
  );
  const [imageId, setImageId] = useState<string | undefined>(
    productSchema?.imageId
  );
  const [imageInvalid, setImageInvalid] = useState(false);
  const [validated, setValidated] = useState(false);
  const [moduleList, setModuleList] = useState(productSchema?.moduleList ?? []);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    setImageInvalid(imageId === null || imageId === undefined);
    if (!form.checkValidity() || imageId === null || imageId === undefined) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    onSubmit({
      name: name,
      stockKeepingUnit: stockKeepingUnit,
      category: category,
      europeanArticleNumber: europeanArticleNumber,
      imageId: imageId,
      moduleList: moduleList,
    });
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}>
      <Row className={'justify-content-sm-center align-items-sm-center'}>
        <Col sm={5}>
          <FormInput
            value={name}
            setValue={setName}
            controlId={'name'}
            label={'Nazwa'}
            errorText={'Nazwa jest wymagana'}
          />
          <FormInput
            value={stockKeepingUnit}
            setValue={setStockKeepingUnit}
            controlId={'stockKeepingUnit'}
            label={'Stock Keeping Unit'}
            errorText={'SKU jest wymagany'}
            disabled={editMode}
          />
          <CategorySelect
            category={category}
            setCategory={setCategory}
          />
          <FormInput
            value={europeanArticleNumber}
            setValue={setEuropeanArticleNumber}
            controlId={'europeanArticleNumber'}
            label={'European Article Number'}
            errorText={'EAN jest wymagany'}
          />
          <div className='d-flex justify-content-center pb-5'>
            <FileUpload
              size={300}
              onFileUpload={setImageId}
              isInvalid={imageInvalid}
              fileId={imageId}
              onFileDelete={() => setImageId(undefined)}
            />
          </div>
        </Col>
      </Row>
      <SubModulesTable
        subModuleList={moduleList}
        setSubModuleList={setModuleList}
        modificationModuleId={productSchema?.id}
        itemType={ItemType.PRODUCT}
      />
      <Buttons />
    </Form>
  );
};

interface CategorySelectProps {
  category: string;
  setCategory: (value: string) => void;
}

const CategorySelect = ({ category, setCategory }: CategorySelectProps) => {
  return (
    <Form.Group
      className='pb-4'
      controlId='category'>
      <CategoryListSelect
        category={category}
        itemType={ItemType.PRODUCT}
        setCategory={setCategory}
      />
      <Form.Control.Feedback type='invalid'>
        Kategoria jest wymagana
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const Buttons = () => {
  const navigate = useNavigate();

  return (
    <Row
      className='pt-2 justify-content-sm-center align-items-sm-center'
      style={{ backgroundColor: 'white', position: 'sticky', bottom: '0' }}>
      <Col sm={3} />
      <Col sm={2}>
        <Button
          variant='outline-primary'
          onClick={() => {
            navigate(-1);
          }}
          className='w-100 mb-2'>
          <FontAwesomeIcon icon={faXmark} />
          <span className='m-2'>Anuluj</span>
        </Button>
      </Col>
      <Col sm={2} />
      <Col sm={2}>
        <Button
          variant='primary'
          className='w-100 mb-2'
          type='submit'>
          <FontAwesomeIcon icon={faCheck} />
          <span className='m-2'>Zapisz</span>
        </Button>
      </Col>
      <Col sm={3} />
    </Row>
  );
};

export { ProductSchemaForm };
export type { ProductSchemaFormModel };
