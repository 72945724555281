import { usePostAction } from '../http-client';

export interface CraftElementRequest {
  id: string;
  craftQuantity: number;
}

export function useCraftElement() {
  const { callPost } = usePostAction<CraftElementRequest, void>(
    '/craft-element'
  );
  return { callCraftModule: callPost };
}
