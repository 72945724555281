import {
  AuthorizationRequest,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenRequest,
} from '@openid/appauth';
import { CLIENT_ID, REDIRECT_URI } from './auth-consts';

export class RequestPreparer {
  prepareTokenRequestByRefreshToken(refreshToken: string): TokenRequest {
    return new TokenRequest({
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URI,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      refresh_token: refreshToken,
    });
  }

  prepareTokenRequestByCode(code: string, codeVerifier: string): TokenRequest {
    return new TokenRequest({
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URI,
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code: code,
      extras: {
        code_verifier: codeVerifier,
      },
    });
  }

  prepareAuthorizationRequest() {
    return new AuthorizationRequest({
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URI,
      scope: 'openid',
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      extras: {
        response_mode: 'fragment',
      },
    });
  }
}
