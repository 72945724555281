import { Col, Container, Image, Row } from 'react-bootstrap';
import { Sidebar } from '../sidebar/Sidebar';
import { TopBar } from '../topbar/Topbar';
import { Navigate, Outlet } from 'react-router-dom';
import React, { useState } from 'react';
import styles from './RootPage.module.scss';
import { NavigationPath } from '../../data/constants';
import { authProvider } from '../../api/auth/auth';
import { TitleContext } from '../../context/TitleContext';
import { UserContextProvider } from '../../context/UserContextProvider';
import { clarityApi } from '../../api/clarity/clarity';
import { UserInfo } from '../../api/auth/user-info';
import { ErrorToast } from '../../components/ErrorToast';
import {
  ErrorContextProvider,
  useInitErrorContext,
} from '../../context/ErrorContext';

function RootPageContent() {
  const [title, setTitle] = useState('');
  const titleContext: TitleContext = { title, setTitle };
  return (
    <React.StrictMode>
      <Container
        fluid
        className='h-100'>
        <div className={styles.header}>
          <Row>
            <Col
              sm={2}
              className={`${styles.rightBorder} ps-4 pe-4 pt-2 pb-2`}>
              <Image
                src='/brent.svg'
                fluid
                className='mt-3'
              />
            </Col>
            <Col
              sm={10}
              className={`${styles.topBarColumn} ps-4 pe-4 pt-2 pb-2`}>
              <TopBar title={title} />
            </Col>
          </Row>
          <Row>
            <Col
              sm={2}
              className={`${styles.rightBorder} ps-4 pe-4 pt-2 pb-2`}>
              <hr className='solid' />
            </Col>
            <Col
              sm={10}
              className='ps-4 pe-4 pt-2 pb-2'>
              <hr className='solid' />
            </Col>
          </Row>
        </div>
        <Row>
          <Col
            sm={2}
            className={`${styles.rightBorder} p-0`}>
            <Sidebar />
          </Col>
          <Col
            sm={10}
            className='p-4'>
            <Outlet context={titleContext} />
          </Col>
        </Row>
        <ErrorToast />
      </Container>
    </React.StrictMode>
  );
}

function putUserInfoIntoClarity(userInfo: UserInfo) {
  clarityApi.identify(
    userInfo.email,
    undefined,
    undefined,
    `${userInfo.firstName} ${userInfo.lastName}`
  );
  clarityApi.setTag('email', userInfo.email);
  clarityApi.setTag('role', userInfo.role);
  clarityApi.increaseRecordingPriority('');
}

function RootPage() {
  const errorContextProps = useInitErrorContext();
  if (!authProvider.isLoggedIn()) {
    authProvider.setInitialURL(window.location.pathname);
    return <Navigate to={NavigationPath.SIGN_IN} />;
  } else {
    const userInfo = authProvider.getUserInfo();
    putUserInfoIntoClarity(userInfo);
    return (
      <ErrorContextProvider errorContext={errorContextProps}>
        <UserContextProvider userInfo={userInfo}>
          <RootPageContent />
        </UserContextProvider>
      </ErrorContextProvider>
    );
  }
}

export { RootPage };
