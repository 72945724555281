import {
  apiError,
  apiLoading,
  ApiResult,
  apiSuccess,
  authHttpClient,
} from '../http-client';
import { useState } from 'react';

export interface UploadImageResponse {
  imageId: string;
}

export function useUploadImage() {
  const [response, setResponse] = useState<ApiResult<UploadImageResponse>>();

  const callUploadImage = async (image: File) => {
    setResponse(apiLoading());
    authHttpClient
      .post<UploadImageResponse>(
        '/upload-image',
        { image },
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      )
      .then(response => {
        setResponse(apiSuccess(response.data));
      })
      .catch(e => setResponse(apiError(e)));
  };

  return { response, callUploadImage };
}
