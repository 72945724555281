import { useState } from 'react';
import {
  GetModuleListRequest,
  useGetModuleList,
} from './modules/get-module-list';

const INITIAL_MODULE_LIST_RESPONSE = {
  moduleList: [],
  page: {
    pageNumber: 0,
    totalCount: 0,
    pageSize: 0,
    pageCount: 0,
  },
};

export function useModules() {
  const [getModuleListRequest, setGetModuleListRequest] =
    useState<GetModuleListRequest>({
      page: {
        pageNumber: 0,
        pageSize: 10,
      },
    });

  const { response: getModuleListResponse } =
    useGetModuleList(getModuleListRequest);

  const moduleListResponse =
    getModuleListResponse?.data ?? INITIAL_MODULE_LIST_RESPONSE;

  const isLoading = getModuleListResponse?.isLoading ?? false;

  const handlePaginationChange = (
    pageNumber: number,
    modulesPerPage: number
  ) => {
    setGetModuleListRequest({
      ...getModuleListRequest,
      page: {
        pageNumber: pageNumber - 1,
        pageSize: modulesPerPage,
      },
    });
  };

  return {
    moduleListResponse,
    isLoading,
    getModuleListRequest,
    setGetModuleListRequest,
    handlePaginationChange,
  };
}
