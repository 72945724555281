import { Tab, Tabs } from 'react-bootstrap';
import WarehouseElements from './elements/WarehouseElements';
import { useSetTitle } from '../../context/TitleContext';
import WarehouseModules from './modules/WarehouseModules';
import WarehouseHistory from './history/WarehouseHistory';
import { UserRole } from '../../api/auth/token-content';
import { RoleFilter } from '../../components/RoleFilter';

function Warehouse() {
  useSetTitle('Magazyn');
  return (
    <>
      <RoleFilter visibleForRoles={[UserRole.EMPLOYEE]}>
        <Tabs
          mountOnEnter
          unmountOnExit
          className='border-bottom border-4 h4'>
          <Tab
            eventKey='elements'
            title='Elementy'>
            <WarehouseElements />
          </Tab>
          <Tab
            eventKey='modules'
            title='Moduły'>
            <WarehouseModules />
          </Tab>
        </Tabs>
      </RoleFilter>
      <RoleFilter
        visibleForRoles={[UserRole.SYSTEM_ADMIN, UserRole.WAREHOUSE_ADMIN]}>
        <Tabs
          mountOnEnter
          unmountOnExit
          className='border-bottom border-4 h4'>
          <Tab
            eventKey='elements'
            title='Elementy'>
            <WarehouseElements />
          </Tab>
          <Tab
            eventKey='modules'
            title='Moduły'>
            <WarehouseModules />
          </Tab>
          <Tab
            eventKey='history'
            title='Historia'>
            <WarehouseHistory />
          </Tab>
        </Tabs>
      </RoleFilter>
    </>
  );
}

export { Warehouse };
