import { QrScanner } from '@yudiel/react-qr-scanner';
import { Button, Col, Row } from 'react-bootstrap';
import React from 'react';

interface QrCodeScannerProps {
  onClose: () => void;
  onDecode: (text: string) => void;
}

export function QrCodeScanner(props: QrCodeScannerProps) {
  const { onClose, onDecode } = props;

  return (
    <>
      <QrScanner
        onDecode={result => onDecode(result)}
        scanDelay={1000}
      />
      <Row className={'mt-3'}>
        <Col className={'text-center'}>
          <Button onClick={onClose}>Zamknij skaner</Button>
        </Col>
      </Row>
    </>
  );
}
