import { CustomColumn, CustomTable } from '../table/CustomTable';
import { ImageRenderer } from '../table/renderers/ImageRenderer';
import { SchemaQuantityRenderer } from '../table/renderers/SchemaQuantityRenderer';
import { useSchemaQuantityState } from '../table/field-states/SchemaQuantityState';
import { ItemType } from '../../data/item-model';
import { SubModuleModel } from '../../data/module-model';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { AddSubModulesModal } from './AddSubModulesModal';
import { SubModulesActionButtonsRenderer } from '../table/renderers/action-buttons/SubModulesActionButtonsRenderer';

const columns: CustomColumn[] = [
  {
    name: '',
    field: 'imageId',
    className: 'col-sm-1',
    renderer: ImageRenderer,
  },
  {
    name: 'Nazwa',
    field: 'name',
    className: 'col-sm-2',
  },
  {
    name: 'SKU',
    field: 'stockKeepingUnit',
    className: 'col-sm-2',
  },
  {
    name: 'Kategoria',
    field: 'category',
    className: 'col-sm-2',
  },
  {
    name: 'Wariant',
    field: 'variant',
    className: 'col-sm-2',
  },
  {
    name: 'Liczba elementów',
    field: 'schemaQuantity',
    className: 'col-sm-2',
    renderer: SchemaQuantityRenderer,
    prepareInitialState: useSchemaQuantityState,
  },
  {
    name: '',
    field: '',
    className: 'col-sm-1',
    renderer: SubModulesActionButtonsRenderer,
  },
];

export interface SubModulesTableProps {
  subModuleList: SubModuleModel[];
  setSubModuleList: (subModuleList: SubModuleModel[]) => void;
  modificationModuleId?: string;
  itemType: ItemType;
}

export interface SubModulesModificationProps {
  updateSubModuleQuantity: (moduleId: string, quantity: number) => void;
  removeSubModule: (moduleIdToRemove: string) => void;
}

export default function SubModulesTable(props: SubModulesTableProps) {
  const [showAddModulesModal, setShowAddModulesModal] = useState(false);

  const removeSubModule = (moduleIdToRemove: string) => {
    props.setSubModuleList(
      props.subModuleList.filter(module => module.id !== moduleIdToRemove)
    );
  };

  const updateSubModuleQuantity = (moduleId: string, quantity: number) => {
    const updatedSubModuleList = [...props.subModuleList];
    const moduleToUpdate = updatedSubModuleList.find(
      module => module.id === moduleId
    );
    if (moduleToUpdate) {
      moduleToUpdate.schemaQuantity = quantity;
      props.setSubModuleList(updatedSubModuleList);
    }
  };

  const subModulesModificationProps: SubModulesModificationProps = {
    updateSubModuleQuantity: updateSubModuleQuantity,
    removeSubModule: removeSubModule,
  };

  return (
    <>
      <Row className={'align-items-sm-center'}>
        <Col sm={10}>
          <h2>Lista modułów</h2>
        </Col>
        <Col
          sm={2}
          className='d-flex justify-content-end ps-2'>
          <Button
            variant={'primary'}
            onClick={() => {
              setShowAddModulesModal(true);
            }}
            className='w-100'>
            <FontAwesomeIcon icon={faPlus} />
            <span className={'m-2'}>Dodaj moduły</span>
          </Button>
        </Col>
      </Row>

      <CustomTable
        columns={columns}
        data={props.subModuleList}
        emptyListMessage={'Nie wybrano żadnych modułów'}
        refetch={() => {}}
        externalProps={subModulesModificationProps}
      />

      <AddSubModulesModal
        show={showAddModulesModal}
        setShow={setShowAddModulesModal}
        subModulesTableProps={props}
      />
    </>
  );
}
