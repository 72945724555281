import { usePostAction } from '../http-client';

interface DeleteProducteRequest {
  id: string;
}

interface DeleteProductResponse {
  id: string;
  deleted: boolean;
}

export function useDeleteProduct() {
  const { callPost } = usePostAction<
    DeleteProducteRequest,
    DeleteProductResponse
  >('/delete-product');
  return { callDeleteModule: callPost };
}
