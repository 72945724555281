import React, { useMemo, useState } from 'react';
import { MobileListFilterButtons } from '../../../components/MobileListFilterButtons';
import { QrCodeScanner } from '../../../components/QrCodeScanner';
import { ActiveWarehouseHistoryFilters } from '../../../components/history/ActiveWarehouseHistoryFilters';
import { useWarehouseHistory } from '../../../api/history/warehouse-history';
import { CenteredSpinner } from '../../../components/CenteredSpinner';
import { EmptyListMessage } from '../../../components/EmptyListMessage';
import { Accordion, Col, Row, Table } from 'react-bootstrap';
import commonStyles from '../../../mobile-view/Common.module.scss';
import { WarehouseHistoryModel } from '../../../data/history-model';
import { warehouseOperationTypeMappings } from '../../../api/operation-type';
import { WarehouseHistoryFiltersModal } from '../../../components/history/WarehouseHistoryFiltersModal';
import CustomPagination from '../../../components/CustomPagination';
import moment from 'moment';
import styles from '../Warehouse.module.scss';
import { AccordionToggle } from '../../../components/AccordionToggle';

export function WarehouseHistory() {
  const {
    warehouseHistoryResponse,
    isLoading,
    getWarehouseHistoryRequest,
    setGetWarehouseHistoryRequest,
    handlePaginationChange,
  } = useWarehouseHistory();

  const [showFilters, setShowFilters] = useState(false);
  const [showScanner, setShowScanner] = useState(false);

  const filterBySKU = (stockKeepingUnit: string) => {
    setShowScanner(false);
    setGetWarehouseHistoryRequest({
      ...getWarehouseHistoryRequest,
      stockKeepingUnit: stockKeepingUnit,
    });
  };

  const isWarehouseHistoryEmpty =
    warehouseHistoryResponse.warehouseHistory.length === 0;

  return (
    <>
      {!showScanner && (
        <>
          <MobileListFilterButtons
            setShowFilters={setShowFilters}
            setShowScanner={setShowScanner}
          />
          <ActiveWarehouseHistoryFilters
            getWarehouseHistoryRequest={getWarehouseHistoryRequest}
            setGetWarehouseHistoryRequest={setGetWarehouseHistoryRequest}
          />
          {isLoading && (
            <div className={styles.list}>
              <CenteredSpinner />
            </div>
          )}

          {!isLoading && !isWarehouseHistoryEmpty && (
            <div className={styles.list}>
              <Table>
                <thead>
                  <tr className={`${commonStyles.tableHeader} row`}>
                    <th className={'col-3 text-center'}>SKU</th>
                    <th className={'col-4 text-center'}>Typ Akcji</th>
                    <th className={'col-3 text-center'}>Data</th>
                    <th className={'col-2'}></th>
                  </tr>
                </thead>
                <tbody>
                  <Accordion alwaysOpen>
                    {warehouseHistoryResponse.warehouseHistory.map(
                      (history, index) => (
                        <TableRow
                          key={history.creationTimestamp.toString()}
                          historyEntry={history}
                          eventKey={index.toString()}
                        />
                      )
                    )}
                  </Accordion>
                </tbody>
              </Table>
            </div>
          )}
          {!isLoading && isWarehouseHistoryEmpty && (
            <EmptyListMessage message={'Brak wpisów w historii magazynu.'} />
          )}
          {!isWarehouseHistoryEmpty && (
            <div className={commonStyles.stickyBottom}>
              <CustomPagination
                pageNumber={warehouseHistoryResponse.page.pageNumber + 1}
                elementsPerPage={warehouseHistoryResponse.page.pageSize}
                items={warehouseHistoryResponse.warehouseHistory}
                totalPages={warehouseHistoryResponse.page.pageCount}
                onPaginationParamsChange={(pageNumber, elementsPerPage) =>
                  handlePaginationChange(pageNumber, elementsPerPage)
                }
                disablePageSizeChange
              />
            </div>
          )}
          <WarehouseHistoryFiltersModal
            show={showFilters}
            setShow={setShowFilters}
            getWarehouseHistoryRequest={getWarehouseHistoryRequest}
            setGetWarehouseHistoryRequest={setGetWarehouseHistoryRequest}
          />
        </>
      )}
      {showScanner && (
        <QrCodeScanner
          onClose={() => setShowScanner(false)}
          onDecode={text => filterBySKU(text)}
        />
      )}
    </>
  );
}

interface TableRowProps {
  eventKey: string;
  historyEntry: WarehouseHistoryModel;
}

function TableRow(props: Readonly<TableRowProps>) {
  const { historyEntry, eventKey } = props;
  const operationTypeMapping = useMemo(
    () => warehouseOperationTypeMappings(),
    []
  );

  return (
    <tr className={'row border-bottom'}>
      <td className={'col-3 text-break'}>{historyEntry.stockKeepingUnit}</td>
      <td className={'col-4'}>
        {operationTypeMapping.get(historyEntry.operationType)}
      </td>
      <td className={'col-3'}>
        {moment(historyEntry.creationTimestamp).format('DD.MM.YYYY, HH:mm')}
      </td>
      <td className={'col-2 d-flex justify-content-end'}>
        <AccordionToggle eventKey={eventKey} />
      </td>
      <Accordion.Collapse eventKey={eventKey}>
        <>
          <Row className={'mt-3 text-break'}>
            <Col xs={3}>
              <div className={commonStyles.secondaryText}>Imię</div>
              <div>{historyEntry.firstName}</div>
            </Col>
            <Col xs={4}>
              <div className={commonStyles.secondaryText}>Nazwisko</div>
              <div>{historyEntry.lastName}</div>
            </Col>
            <Col xs={5}>
              <div className={commonStyles.secondaryText}>Email</div>
              <div>{historyEntry.email}</div>
            </Col>
          </Row>
          <Row className={'mt-3 text-break'}>
            <Col xs={5}>
              <div className={commonStyles.secondaryText}>
                Ilość przed zmianą
              </div>
              <div>{historyEntry.oldQuantity}</div>
            </Col>
            <Col xs={5}>
              <div className={commonStyles.secondaryText}>Ilość po zmianie</div>
              <div>{historyEntry.newQuantity}</div>
            </Col>
          </Row>
        </>
      </Accordion.Collapse>
    </tr>
  );
}
