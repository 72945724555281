import { UserRole } from '../api/auth/token-content';
import { UserContext } from '../context/UserContextProvider';
import { useContext } from 'react';

interface RoleFilterProps {
  visibleForRoles: UserRole[];
  children: React.ReactNode;
}

export const RoleFilter = ({ visibleForRoles, children }: RoleFilterProps) => {
  const user = useContext(UserContext);

  return <>{visibleForRoles.includes(user.role) && children}</>;
};
