import { Row, Col } from 'react-bootstrap';
import styles from '../../Crafting.module.scss';
import commonStyles from '../../../../mobile-view/Common.module.scss';

export const CraftingMobileItemHeader = () => {
  return (
    <>
      <div className={styles.mobileVerticalLineDiv} />
      <Row className={`pt-2 pb-1 px-0 ${styles.mobileCraftingRowContentDiv}`}>
        <Col xs={2} />
        <Col xs={3} />
        <Col xs={2}>
          <div className={commonStyles.smallText}>Posiadane</div>
        </Col>
        <Col xs={2}>
          <div className={commonStyles.smallText}>Potrzebne</div>
        </Col>
        <Col xs={2}>
          <div className={commonStyles.smallText}>Końcowe</div>
        </Col>
        <Col xs={1} />
      </Row>
    </>
  );
};
