import { usePostAction } from '../http-client';

export interface CraftProductRequest {
  id: string;
  craftQuantity: number;
}

export function useCraftProduct() {
  const { callPost } = usePostAction<CraftProductRequest, void>(
    '/craft-product'
  );
  return { callCraftProduct: callPost };
}
