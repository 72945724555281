import { usePostFetch } from '../http-client';
import { Category } from '../../data/category-model';
import { ItemType } from '../../data/item-model';

interface GetCategoryListRequest {
  itemType: ItemType;
}

interface GetCategoryListResponse {
  categoryList: Category[];
}

export function useGetCategoryList(request: GetCategoryListRequest) {
  const { response } = usePostFetch<
    GetCategoryListRequest,
    GetCategoryListResponse
  >('/get-category-list', request);
  return { getCategoryListResponse: response };
}

export type { GetCategoryListRequest, GetCategoryListResponse };
