import React, { useContext } from 'react';
import { Toast } from 'react-bootstrap';
import { ErrorContext } from '../context/ErrorContext';

const ErrorToast = () => {
  const { show, setShow, error } = useContext(ErrorContext);

  return (
    <div
      className='d-flex justify-content-center sticky-bottom'
      style={{ bottom: '40px' }}>
      <Toast
        className='align-items-sm-center w-50 align-self-center border-danger'
        style={{
          borderColor: 'danger',
          backgroundColor: 'danger',
          bottom: '40px',
        }}
        onClose={() => setShow(false)}
        show={show}
        animation={false}>
        <Toast.Header className='bg-danger-subtle border-danger'>
          <strong className='me-auto text-danger'>
            BŁĄD: {error?.message ?? ''}
          </strong>
        </Toast.Header>
        <Toast.Body>
          {error?.code ?? ''} : {error?.id ?? ''}
        </Toast.Body>
      </Toast>
    </div>
  );
};

export { ErrorToast };
