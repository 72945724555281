import { useMemo, useState } from 'react';
import { useGetVariantList } from './variant/get-variant-list';

export function useVariants() {
  const [getVariantListRequest] = useState({});
  const { getVariantListResponse } = useGetVariantList(getVariantListRequest);
  const variantList = useMemo(
    () => getVariantListResponse?.data?.variantList ?? [],
    [getVariantListResponse]
  );

  return {
    variantList,
  };
}
