import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

interface NewSelectionPrefixProps {
  prefix: string;
}

const NewSelectionPrefix = ({ prefix }: NewSelectionPrefixProps) => {
  return (
    <>
      <FontAwesomeIcon icon={faPlus} />
      <span>
        {' '}
        <b>{prefix}</b>:{' '}
      </span>
    </>
  );
};

export { NewSelectionPrefix };
