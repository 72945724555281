import { usePostFetch } from '../http-client';
import { Variant } from '../../data/variant-model';

interface GetVariantListRequest {}

interface GetVariantListResponse {
  variantList: Variant[];
}

export function useGetVariantList(request: GetVariantListRequest) {
  const { response } = usePostFetch<
    GetVariantListRequest,
    GetVariantListResponse
  >('/get-variant-list', request);
  return { getVariantListResponse: response };
}

export type { GetVariantListRequest, GetVariantListResponse };
