import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faQrcode } from '@fortawesome/free-solid-svg-icons';
import CustomPagination from '../../../components/CustomPagination';
import { useNavigate } from 'react-router-dom';
import { NavigationPath } from '../../../data/constants';
import { ElementFilters } from '../../../components/elements/ElementFilters';
import { CenteredSpinner } from '../../../components/CenteredSpinner';
import { ItemType } from '../../../data/item-model';
import { useCategories } from '../../../api/categories';
import { useElements } from '../../../api/elements';
import { RoleFilter } from '../../../components/RoleFilter';
import { UserRole } from '../../../api/auth/token-content';
import {
  CustomColumn,
  CustomTable,
} from '../../../components/table/CustomTable';
import { ElementSetRenderer } from '../../../components/table/renderers/ElementSetRenderer';
import { ImageRenderer } from '../../../components/table/renderers/ImageRenderer';
import { SchemaActionButtonsRenderer } from '../../../components/table/renderers/action-buttons/SchemaActionButtonsRenderer';
import { useSchemaElementActionButtonsState } from '../../../components/table/field-states/SchemaActionButtonsState';
import {
  GenerateElementQrCodesRequest,
  useGenerateElementQrCodes,
} from '../../../api/elements/generate-element-qr-codes';
import download from 'downloadjs';

const columns: CustomColumn[] = [
  {
    name: '',
    field: 'imageId',
    className: 'col-sm-1',
    renderer: ImageRenderer,
  },
  {
    name: 'Nazwa',
    field: 'name',
    className: 'col-sm-3',
  },
  {
    name: 'SKU',
    field: 'stockKeepingUnit',
    className: 'col-sm-2',
  },
  {
    name: 'Kategoria',
    field: 'category',
    className: 'col-sm-2',
  },
  {
    name: 'Komplet',
    field: 'isElementSet',
    className: 'col-sm-2',
    renderer: ElementSetRenderer,
  },
  {
    name: '',
    field: 'actionButtons',
    className: 'col-sm-2',
    renderer: SchemaActionButtonsRenderer,
    prepareInitialState: useSchemaElementActionButtonsState,
  },
];

export default function ElementSchemas() {
  const navigate = useNavigate();

  const {
    elementListResponse,
    isLoading,
    getElementListRequest,
    setGetElementListRequest,
    handlePaginationChange,
  } = useElements();

  const { categoryList } = useCategories(ItemType.ELEMENT);

  const { callGenerateElementQrCodes } = useGenerateElementQrCodes();

  const navigateToAddElementSchema = () => {
    navigate(NavigationPath.ADD_ELEMENT_SCHEMA);
  };

  const generateQRCodes = () => {
    const generateElementQrCodesRequest: GenerateElementQrCodesRequest = {
      name: getElementListRequest.name,
      stockKeepingUnit: getElementListRequest.stockKeepingUnit,
      category: getElementListRequest.category,
      isElementSet: getElementListRequest.isElementSet,
    };

    callGenerateElementQrCodes(generateElementQrCodesRequest).then(response => {
      if (!response.error && response.data) {
        download(response.data, 'kody-qr-elementy.zip');
      }
    });
  };

  const isElementSchemasEmpty = () => {
    return elementListResponse.elementList?.length === 0;
  };

  return (
    <>
      <Row className='align-items-sm-center'>
        <Col sm={10}>
          <ElementFilters
            elementCategories={categoryList}
            setElementListRequest={setGetElementListRequest}
            elementListRequest={getElementListRequest}
            showElementSetFilter={true}
          />
        </Col>
        <RoleFilter
          visibleForRoles={[UserRole.WAREHOUSE_ADMIN, UserRole.SYSTEM_ADMIN]}>
          <Col sm={2}>
            <Row>
              <Col>
                <Button
                  variant={'primary'}
                  onClick={navigateToAddElementSchema}
                  className='w-100'>
                  <FontAwesomeIcon icon={faPlus} />
                  <span className={'m-2'}>Dodaj element</span>
                </Button>
              </Col>
            </Row>
            <Row className={'mt-2'}>
              <Col>
                <Button
                  variant={'outline-primary'}
                  onClick={generateQRCodes}
                  className='w-100'>
                  <FontAwesomeIcon icon={faQrcode} />
                  <span className={'m-2'}>Generuj kody QR</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </RoleFilter>
      </Row>
      {isLoading && <CenteredSpinner />}
      {!isLoading && (
        <CustomTable
          columns={columns}
          data={elementListResponse.elementList}
          emptyListMessage='Brak schematów elementów.'
          refetch={() =>
            setGetElementListRequest({
              ...getElementListRequest,
            })
          }
        />
      )}

      {!isElementSchemasEmpty() && (
        <CustomPagination
          pageNumber={elementListResponse.page.pageNumber + 1}
          elementsPerPage={elementListResponse.page.pageSize}
          items={elementListResponse.elementList}
          totalPages={elementListResponse.page.pageCount}
          onPaginationParamsChange={(pageNumber, elementsPerPage) =>
            handlePaginationChange(pageNumber, elementsPerPage)
          }
        />
      )}
    </>
  );
}
