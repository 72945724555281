import { usePostAction } from '../http-client';

export interface UpdateElementRequest {
  id: string;
  name: string;
  stockKeepingUnit: string;
  category: string;
  imageId: string;
  isElementSet: boolean;
  subElementList: SubElement[];
}

interface SubElement {
  id: string;
  schemaQuantity: number;
}

export function useUpdateElement() {
  const { callPost } = usePostAction<UpdateElementRequest, void>(
    '/update-element'
  );
  return { callUpdateElement: callPost };
}
