export const INITIAL_URL_LOCAL_STORAGE_KEY = 'initial_url';
export const OPEN_ID_ISSUER_URL = `${window.location.protocol}//${window.location.host}/auth/realms/brent-flow`;
export const CONFIG_LOCAL_STORAGE_KEY = 'config';
export const CODE_VERIFIER = 'code_verifier';

export const REDIRECT_URI = `${window.location.protocol}//${window.location.host}/sign-in`;
export const ACCESS_TOKEN_EXPIRATION_DEBOUNCE_SECONDS = 5;
export const TOKEN_LOCAL_STORAGE_KEY = 'token';
export const TIME_FOR_REDIRECTING_TO_OAUTH_SERVER_MILLIS = 1000;
export const TIME_TO_WAIT_BEFORE_CHECKING_IF_REFRESH_TOKEN_FINISHED_MILLIS = 1000;
export const CLIENT_ID = 'brent-flow-web';
export const UPDATE_PASSWORD_URL = `/auth/realms/brent-flow/protocol/openid-connect/auth?client_id=brent-flow-web&redirect_uri=${REDIRECT_URI}&response_type=code&kc_action=UPDATE_PASSWORD`;
