import React, { useContext } from 'react';
import styles from './LoginCard.module.scss';
import commonStyles from '../Common.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faUser } from '@fortawesome/free-solid-svg-icons';
import { ButtonGroup, Col, Dropdown, Row } from 'react-bootstrap';
import { UserContext } from '../../context/UserContextProvider';
import { authProvider } from '../../api/auth/auth';

interface CustomToggleProps {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {};
}

const IconToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.LegacyRef<HTMLDivElement>) => (
    <div
      className={`${styles.border} p-3`}
      ref={ref}>
      <Row className='align-items-center'>
        <Col sm={'auto'}>
          <FontAwesomeIcon
            icon={faUser}
            size={'xl'}
            className={`${styles.icon}`}
          />
        </Col>
        <Col sm={'auto'}>{props.children}</Col>
        <Col sm={'auto'}>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`${commonStyles.iconPrimary}`}
            size='xl'
            onClick={(e: React.MouseEvent<SVGSVGElement>) => {
              e.preventDefault();
              props.onClick(e);
            }}
          />
        </Col>
      </Row>
    </div>
  )
);

function LoginCard() {
  const user = useContext(UserContext);
  return (
    <Dropdown
      className='d-inline-block'
      as={ButtonGroup}>
      <Dropdown.Toggle
        as={IconToggle}>{`${user.firstName} ${user.lastName}`}</Dropdown.Toggle>
      <Dropdown.Menu className='w-100'>
        <Dropdown.Item
          className={commonStyles.customHover}
          eventKey='1'
          onClick={() => {
            authProvider.logout();
          }}>
          Wyloguj
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export { LoginCard };
