import { useSetTitle } from '../../context/TitleContext';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CenteredSpinner } from '../../components/CenteredSpinner';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { PreviewCraftingElementSetTable } from '../../components/crafting/PreviewCraftingElementSetTable';
import { PreviewCraftingElementTable } from '../../components/crafting/PreviewCraftingElementTable';
import {
  PreviewProductCraftingRequest,
  usePreviewProductCrafting,
} from '../../api/elements/preview-product-crafting';
import { useCraftProduct } from '../../api/elements/craft-product';
import { PreviewCraftingProductTable } from '../../components/crafting/PreviewCraftingProductTable';
import { PreviewCraftingModuleTable } from '../../components/crafting/PreviewCraftingModuleTable';

export function CraftProduct() {
  useSetTitle('Produkty - Wytwórz produkt');
  const [activeTab, setActiveTab] = useState('products');
  const { productId } = useParams();
  const [quantity, setQuantity] = useState(0);
  const [elementToScrollId, setElementToScrollId] = useState<
    string | undefined
  >(undefined);

  const request: PreviewProductCraftingRequest = useMemo(
    () => ({
      id: productId!,
      craftQuantity: quantity,
    }),
    [productId, quantity]
  );
  const { response: previewProductCraftingResponse } =
    usePreviewProductCrafting(request);

  const onGoToElementSetsClick = useCallback(
    (id: string) => {
      setActiveTab('elements');
      setElementToScrollId(id);
    },
    [setActiveTab]
  );

  const onGoToActiveTabClick = useCallback(
    (id: string, activeTab: string) => {
      setActiveTab(activeTab);
      setElementToScrollId(id);
    },
    [setActiveTab]
  );

  const onGoToEnd = useCallback(() => {
    setElementToScrollId(undefined);
  }, [setElementToScrollId]);

  const isCraftPossible = useMemo(() => {
    return (
      (previewProductCraftingResponse.data &&
        previewProductCraftingResponse.data.product.craftedQuantity !== 0) ||
      false
    );
  }, [previewProductCraftingResponse.data]);

  return (
    <>
      {previewProductCraftingResponse.isLoading && <CenteredSpinner />}
      {previewProductCraftingResponse.data && (
        <div className='d-flex flex-column h-75'>
          <Tabs
            className='border-bottom border-4 h4'
            activeKey={activeTab}
            onSelect={k => setActiveTab(k!!)}>
            <Tab
              className={'h-100 pt-5'}
              eventKey='products'
              title='Produkty'>
              <div className='d-flex flex-column h-100'>
                <PreviewCraftingProductTable
                  productList={[previewProductCraftingResponse.data.product]}
                  isCraftingRoot={true}
                  onQuantityChange={setQuantity}
                  onGoToClick={onGoToActiveTabClick}
                />
              </div>
            </Tab>
            <Tab
              className={'h-100 pt-5'}
              eventKey='modules'
              title='Moduły'>
              <div className='d-flex flex-column h-100'>
                <PreviewCraftingModuleTable
                  moduleList={previewProductCraftingResponse.data.moduleList}
                  isCraftingRoot={true}
                  onQuantityChange={setQuantity}
                  onGoToClick={onGoToActiveTabClick}
                  elementIdToScroll={elementToScrollId}
                  onGoToEnd={onGoToEnd}
                />
              </div>
            </Tab>
            {previewProductCraftingResponse.data.elementSetList.length > 0 && (
              <Tab
                className={'h-100 pt-5'}
                eventKey='elementSets'
                title='Komplety Elementów'>
                <div className='d-flex flex-column h-100'>
                  <PreviewCraftingElementSetTable
                    elementSetList={
                      previewProductCraftingResponse.data.elementSetList
                    }
                    isCraftingRoot={false}
                    onGoToClick={onGoToElementSetsClick}
                    elementIdToScroll={elementToScrollId}
                    onGoToEnd={onGoToEnd}
                  />
                </div>
              </Tab>
            )}
            <Tab
              className={'h-100 pt-5'}
              eventKey='elements'
              title='Elementy'>
              <PreviewCraftingElementTable
                elementList={previewProductCraftingResponse.data.elementList}
                elementIdToScroll={elementToScrollId}
                onGoToEnd={onGoToEnd}
              />
            </Tab>
          </Tabs>
          <div className='mt-auto pt-5'>
            <CraftButtons
              previewProductCraftingRequest={request}
              isCraftPossible={isCraftPossible}
            />
          </div>
        </div>
      )}
    </>
  );
}

const CraftButtons = (props: {
  isCraftPossible: boolean;
  previewProductCraftingRequest: PreviewProductCraftingRequest;
}) => {
  const navigate = useNavigate();
  const { callCraftProduct } = useCraftProduct();

  const handleCreateClick = () => {
    callCraftProduct({
      id: props.previewProductCraftingRequest.id,
      craftQuantity: props.previewProductCraftingRequest.craftQuantity,
    }).then(() => {
      navigate(-1);
    });
  };

  return (
    <Row className='justify-content-sm-center'>
      <Col
        sm={3}
        className='d-flex justify-content-center'>
        <Button
          variant='outline-primary'
          onClick={() => {
            navigate(-1);
          }}
          className='w-50 mb-2'>
          <span className='m-2'>Anuluj</span>
        </Button>
      </Col>
      <Col
        sm={3}
        className='d-flex justify-content-center'>
        <Button
          onClick={() => handleCreateClick()}
          disabled={
            !props.isCraftPossible ||
            props.previewProductCraftingRequest.craftQuantity === 0
          }
          variant='primary'
          className='w-50 mb-2'>
          <span className='m-2'>Utwórz</span>
        </Button>
      </Col>
    </Row>
  );
};
