import React, { useMemo, useState } from 'react';
import { MobileListFilterButtons } from '../../../components/MobileListFilterButtons';
import { useProductsHistory } from '../../../api/products/products-history';
import { ActiveProductsHistoryFilters } from '../../../components/products/ActiveProductsHistoryFilters';
import styles from '../../warehouse/Warehouse.module.scss';
import { CenteredSpinner } from '../../../components/CenteredSpinner';
import { QrCodeScanner } from '../../../components/QrCodeScanner';
import commonStyles from '../../Common.module.scss';
import CustomPagination from '../../../components/CustomPagination';
import { EmptyListMessage } from '../../../components/EmptyListMessage';
import { Accordion, Col, Row, Table } from 'react-bootstrap';
import { ProductHistoryModel } from '../../../data/history-model';
import { productsOperationTypeMappings } from '../../../api/operation-type';
import moment from 'moment/moment';
import { ProductsHistoryFiltersModal } from '../../../components/products/ProductsHistoryFiltersModal';
import { AccordionToggle } from '../../../components/AccordionToggle';

export function ProductsHistory() {
  const {
    productsHistoryResponse,
    isLoading,
    getProductsHistoryRequest,
    setGetProductsHistoryRequest,
    handlePaginationChange,
  } = useProductsHistory();

  const [showFilters, setShowFilters] = useState(false);
  const [showScanner, setShowScanner] = useState(false);

  const filterBySKU = (stockKeepingUnit: string) => {
    setShowScanner(false);
    setGetProductsHistoryRequest({
      ...getProductsHistoryRequest,
      stockKeepingUnit: stockKeepingUnit,
    });
  };

  const isProductsHistoryEmpty =
    productsHistoryResponse.productHistory.length === 0;

  return (
    <>
      {!showScanner && (
        <>
          <MobileListFilterButtons
            setShowFilters={setShowFilters}
            setShowScanner={setShowScanner}
          />
          <ActiveProductsHistoryFilters
            getProductsHistoryRequest={getProductsHistoryRequest}
            setGetProductsHistoryRequest={setGetProductsHistoryRequest}
          />
          {isLoading && (
            <div className={styles.list}>
              <CenteredSpinner />
            </div>
          )}
          {!isLoading && !isProductsHistoryEmpty && (
            <div className={styles.list}>
              <Table>
                <thead>
                  <tr className={`${commonStyles.tableHeader} row`}>
                    <th className={'col-3 text-center'}>SKU</th>
                    <th className={'col-4 text-center'}>Typ Akcji</th>
                    <th className={'col-3 text-center'}>Data</th>
                    <th className={'col-2'}></th>
                  </tr>
                </thead>
                <tbody>
                  <Accordion alwaysOpen>
                    {productsHistoryResponse.productHistory.map(
                      (history, index) => (
                        <TableRow
                          key={history.creationTimestamp.toString()}
                          historyEntry={history}
                          eventKey={index.toString()}
                        />
                      )
                    )}
                  </Accordion>
                </tbody>
              </Table>
            </div>
          )}

          {!isLoading && isProductsHistoryEmpty && (
            <EmptyListMessage message={'Brak wpisów w historii produktów.'} />
          )}
          {!isProductsHistoryEmpty && (
            <div className={commonStyles.stickyBottom}>
              <CustomPagination
                pageNumber={productsHistoryResponse.page.pageNumber + 1}
                elementsPerPage={productsHistoryResponse.page.pageSize}
                items={productsHistoryResponse.productHistory}
                totalPages={productsHistoryResponse.page.pageCount}
                onPaginationParamsChange={(pageNumber, elementsPerPage) =>
                  handlePaginationChange(pageNumber, elementsPerPage)
                }
                disablePageSizeChange
              />
            </div>
          )}
          <ProductsHistoryFiltersModal
            show={showFilters}
            setShow={setShowFilters}
            getProductsHistoryRequest={getProductsHistoryRequest}
            setGetProductsHistoryRequest={setGetProductsHistoryRequest}
          />
        </>
      )}
      {showScanner && (
        <QrCodeScanner
          onClose={() => setShowScanner(false)}
          onDecode={text => filterBySKU(text)}
        />
      )}
    </>
  );
}

interface TableRowProps {
  eventKey: string;
  historyEntry: ProductHistoryModel;
}

function TableRow(props: Readonly<TableRowProps>) {
  const { historyEntry, eventKey } = props;
  const operationTypeMapping = useMemo(
    () => productsOperationTypeMappings(),
    []
  );

  return (
    <tr className={'row border-bottom'}>
      <td className={'col-3 text-break'}>{historyEntry.stockKeepingUnit}</td>
      <td className={'col-4'}>
        {operationTypeMapping.get(historyEntry.operationType)}
      </td>
      <td className={'col-3'}>
        {moment(historyEntry.creationTimestamp).format('DD.MM.YYYY, HH:mm')}
      </td>
      <td className={'col-2 d-flex justify-content-end'}>
        <AccordionToggle eventKey={eventKey} />
      </td>
      <Accordion.Collapse eventKey={eventKey}>
        <>
          <Row className={'mt-3 text-break'}>
            <Col xs={3}>
              <div className={commonStyles.secondaryText}>Imię</div>
              <div>{historyEntry.firstName}</div>
            </Col>
            <Col xs={4}>
              <div className={commonStyles.secondaryText}>Nazwisko</div>
              <div>{historyEntry.lastName}</div>
            </Col>
            <Col xs={5}>
              <div className={commonStyles.secondaryText}>Email</div>
              <div>{historyEntry.email}</div>
            </Col>
          </Row>
          <Row className={'mt-3 text-break'}>
            <Col xs={5}>
              <div className={commonStyles.secondaryText}>Ilość wytworzona</div>
              <div>{historyEntry.craftedQuantity}</div>
            </Col>
          </Row>
        </>
      </Accordion.Collapse>
    </tr>
  );
}
