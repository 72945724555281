import React, { Dispatch, SetStateAction } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ApiResult } from '../api/http-client';

export interface DeleteSchemaModalProps {
  showDeleteModal: boolean;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  modalTitle: string;
  modalContent: string;
  deleteSchemaId: string;
  deleteFunction: (id: string) => Promise<ApiResult<any>>;
  refetch: () => void;
}

export function DeleteModal(props: Readonly<DeleteSchemaModalProps>) {
  const handleDeleteSchema = () => {
    props.deleteFunction(props.deleteSchemaId).then(() => {
      props.refetch();
      props.setShowDeleteModal(false);
    });
  };

  return (
    <Modal
      show={props.showDeleteModal}
      onHide={() => props.setShowDeleteModal(false)}
      size={'lg'}>
      <Modal.Header>
        <Modal.Title>{props.modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.modalContent}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'outline-primary'}
          onClick={() => props.setShowDeleteModal(false)}
          className='w-25'>
          Nie
        </Button>
        <Button
          variant={'danger'}
          onClick={handleDeleteSchema}
          style={{ color: 'white' }}
          className='w-25 ms-3'>
          Tak
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
