import React, { useState } from 'react';
import { useSetTitle } from '../../../context/TitleContext';
import {
  ModuleSchemaForm,
  ModuleSchemaFormModel,
} from '../../../components/modules/ModuleSchemaForm';
import { useCreateModule } from '../../../api/modules/create-module';
import { SchemaFormModal } from '../../../components/form/SchemaFormModal';
import { useSearchParams } from 'react-router-dom';
import { QueryParams } from '../../../data/constants';
import {
  GetSingleModuleRequest,
  useGetSingleModule,
} from '../../../api/modules/get-single-module';
import { ModuleModel } from '../../../data/module-model';
import { CenteredSpinner } from '../../../components/CenteredSpinner';

const useBaseModuleId: () => string | undefined = () => {
  const [searchParams] = useSearchParams();
  return searchParams.get(QueryParams.BASE_MODULE_ID) ?? undefined;
};

const useBaseModule: (baseModuleId?: string) => ModuleModel | undefined = (
  baseModuleId?: string
) => {
  const baseModuleRequest: GetSingleModuleRequest | undefined = baseModuleId
    ? { id: baseModuleId }
    : undefined;
  const [request] = useState<GetSingleModuleRequest | undefined>(
    baseModuleRequest
  );
  const { response } = useGetSingleModule(request);
  return response?.data;
};

const AddModuleSchema = () => {
  useSetTitle('Schematy - Dodaj moduł');
  const { createModule } = useCreateModule();
  const [showAfterSubmitModal, setShowAfterSubmitModal] = useState(false);
  const baseModuleId = useBaseModuleId();
  const baseModule = useBaseModule(baseModuleId);
  const shouldShowSpinner = baseModuleId && !baseModule;

  const handleSubmit = async (form: ModuleSchemaFormModel) => {
    return createModule({
      name: form.name,
      stockKeepingUnit: form.stockKeepingUnit,
      category: form.category,
      variant: form.variant,
      imageId: form.imageId,
      elementList: form.elementList,
    }).then(response => {
      if (!response.error) {
        setShowAfterSubmitModal(true);
      }
      return response;
    });
  };
  return shouldShowSpinner ? (
    <CenteredSpinner />
  ) : (
    <>
      <ModuleSchemaForm
        moduleSchema={baseModule}
        onSubmit={handleSubmit}
        editMode={false}
      />
      <SchemaFormModal
        show={showAfterSubmitModal}
        setShow={setShowAfterSubmitModal}
        title={'Dodano nowy schemat modułu'}
        body={
          'Udało się dodać nowy schemat modułu! Wróć do listy lub dodaj kolejny schemat.'
        }
        backButtonText={'Wróć do listy'}
        nextButtonText={'Dodaj kolejny schemat'}
      />
    </>
  );
};

export { AddModuleSchema };
