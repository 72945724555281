import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';

interface EmptyListMessageProps {
  message: string;
}

export const EmptyListMessage = (props: EmptyListMessageProps) => {
  return (
    <Row>
      <Col
        sm={12}
        className={'d-flex justify-content-center'}>
        <FontAwesomeIcon
          size={'3x'}
          icon={faTriangleExclamation}
          style={{ color: 'lightgray' }}
        />
      </Col>
      <Col
        sm={12}
        className={'d-flex justify-content-center text-center'}>
        <span
          className={'h3'}
          style={{ color: 'lightgray' }}>
          {props.message}
        </span>
      </Col>
    </Row>
  );
};
