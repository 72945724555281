import { usePostAction } from '../http-client';

interface DeleteCategoryRequest {
  id: string;
}

interface DeleteCategoryResponse {
  id: string;
  deleted: boolean;
}

function useDeleteCategory() {
  const { callPost } = usePostAction<
    DeleteCategoryRequest,
    DeleteCategoryResponse
  >('/delete-category', false);
  return { deleteCategory: callPost };
}

export { useDeleteCategory };
export type { DeleteCategoryRequest };
