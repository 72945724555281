import { useNavigate } from 'react-router-dom';
import { useSelectedPath } from '../../hook/UseSelectedPath';
import { Image, Nav, Offcanvas } from 'react-bootstrap';
import styles from './RootPage.module.scss';
import { NavigationPath } from '../../data/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChair, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export function Menu(props: { show: boolean; onHide: () => void }) {
  const navigate = useNavigate();
  const selectedPath = useSelectedPath();
  return (
    <Offcanvas
      show={props.show}
      onHide={props.onHide}
      placement='start'
      className={styles.offcanvasCustom}>
      <Offcanvas.Header>
        <Image
          src='/brent.svg'
          fluid
          className='mt-3'
        />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Nav
          activeKey={selectedPath}
          defaultActiveKey={NavigationPath.WAREHOUSE}
          className='flex-column'
          variant='pills'>
          <Nav.Link
            className={styles.customHover}
            onClick={() => {
              navigate(NavigationPath.WAREHOUSE);
              props.onHide();
            }}
            eventKey={NavigationPath.WAREHOUSE}>
            <FontAwesomeIcon
              icon={faWarehouse}
              size='lg'
              className='me-3'
              fixedWidth
            />
            <span>Magazyn</span>
          </Nav.Link>
          <Nav.Link
            className={styles.customHover}
            onClick={() => {
              navigate(NavigationPath.PRODUCTS);
              props.onHide();
            }}
            eventKey={NavigationPath.PRODUCTS}>
            <FontAwesomeIcon
              icon={faChair}
              size='lg'
              className='me-3'
              fixedWidth
            />
            <span>Produkty</span>
          </Nav.Link>
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
