import { usePostFetch } from '../http-client';
import { ElementModel } from '../../data/element-model';

export interface GetSingleElementRequest {
  id: string;
}

export function useGetSingleElement(request: GetSingleElementRequest) {
  const { response } = usePostFetch<GetSingleElementRequest, ElementModel>(
    '/get-single-element',
    request
  );
  return { response };
}
