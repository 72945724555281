import styles from './SignIn.module.scss';
import { authProvider } from '../../api/auth/auth';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { NavigationPath } from '../../data/constants';
import { InitLoginProps } from '../../api/auth/init-login-props';

function handleNavigationAfterLoginFlow(
  navigate: NavigateFunction,
  props: InitLoginProps
) {
  if (props.redirectURL && props.redirectURL !== NavigationPath.SIGN_IN) {
    navigate(props.redirectURL);
  } else {
    navigate(NavigationPath.ROOT);
  }
}

function SignIn() {
  const navigate = useNavigate();
  authProvider.initLoginFlow().then(
    value => {
      handleNavigationAfterLoginFlow(navigate, value);
    },
    reason => {
      throw Error('Login flow failed');
    }
  );
  return (
    <div>
      <img
        className={styles.logoImage}
        src='/brent.svg'
        alt='Logo'
      />
    </div>
  );
}

export { SignIn };
