import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ElementSchemaForm,
  ElementSchemaFormModel,
} from '../../../components/elements/ElementSchemaForm';
import { apiError } from '../../../api/http-client';
import { useSetTitle } from '../../../context/TitleContext';
import { useUpdateElement } from '../../../api/elements/update-element';
import {
  GetSingleElementRequest,
  useGetSingleElement,
} from '../../../api/elements/get-single-element';
import { SchemaFormModal } from '../../../components/form/SchemaFormModal';
import { CenteredSpinner } from '../../../components/CenteredSpinner';

export default function EditElementSchema() {
  useSetTitle('Schematy - Edytuj element');
  const { elementSchemaId } = useParams();
  const { callUpdateElement } = useUpdateElement();
  const [request] = useState<GetSingleElementRequest>({ id: elementSchemaId! });
  const { response: elementResponse } = useGetSingleElement(request);
  const element = elementResponse.data;
  const [showAfterSubmitModal, setShowAfterSubmitModal] = useState(false);

  const handleSubmit = async (elementSchemaForm: ElementSchemaFormModel) => {
    return callUpdateElement({
      id: element?.id!,
      name: elementSchemaForm.name,
      stockKeepingUnit: elementSchemaForm.stockKeepingUnit,
      category: elementSchemaForm.category,
      imageId: elementSchemaForm.imageId,
      isElementSet: elementSchemaForm.isElementSet,
      subElementList: elementSchemaForm.subElementList,
    })
      .then(response => {
        setShowAfterSubmitModal(!response.error);
        return response;
      })
      .catch(e => {
        return apiError(e);
      });
  };

  return element ? (
    <>
      <ElementSchemaForm
        onSubmit={handleSubmit}
        elementSchema={element}
        editMode={true}
      />
      <SchemaFormModal
        show={showAfterSubmitModal}
        setShow={setShowAfterSubmitModal}
        title={'Edytowano schemat elementu'}
        body={
          'Udało się edytować schemat elementu! Wróć do listy lub dokonaj kolejnych zmian.'
        }
        backButtonText={'Wróć do listy'}
        nextButtonText={'Dokonaj kolejnych zmian'}
      />
    </>
  ) : (
    <CenteredSpinner />
  );
}
