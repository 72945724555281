import { useSetTitle } from '../../../context/TitleContext';
import { useCreateProduct } from '../../../api/products/create-product';
import { useState } from 'react';
import {
  ProductSchemaForm,
  ProductSchemaFormModel,
} from '../../../components/products/ProductSchemaForm';
import { SchemaFormModal } from '../../../components/form/SchemaFormModal';

const AddProductSchema = () => {
  useSetTitle('Schematy - Dodaj produkt');
  const { createProduct } = useCreateProduct();
  const [showAfterSubmitModal, setShowAfterSubmitModal] = useState(false);

  const handleSubmit = async (form: ProductSchemaFormModel) => {
    return createProduct({
      name: form.name,
      stockKeepingUnit: form.stockKeepingUnit,
      category: form.category,
      europeanArticleNumber: form.europeanArticleNumber,
      imageId: form.imageId,
      moduleList: form.moduleList,
    }).then(response => {
      if (!response.error) {
        setShowAfterSubmitModal(true);
      }
      return response;
    });
  };

  return (
    <>
      <ProductSchemaForm
        onSubmit={handleSubmit}
        editMode={false}
      />
      <SchemaFormModal
        show={showAfterSubmitModal}
        setShow={setShowAfterSubmitModal}
        title={'Dodano nowy schemat produktu'}
        body={
          'Udało się dodać nowy schemat produktu! Wróć do listy lub dodaj kolejny schemat.'
        }
        backButtonText={'Wróć do listy'}
        nextButtonText={'Dodaj kolejny schemat'}
      />
    </>
  );
};

export { AddProductSchema };
