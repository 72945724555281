import { ElementCraftingPreview } from '../../../api/elements/preview-element-set-crafting';
import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Col, Image, Row } from 'react-bootstrap';
import styles from '../Crafting.module.scss';
import { getThumbnailUrl } from '../../../api/image/get-image';
import commonStyles from '../../../mobile-view/Common.module.scss';
import { ImageZoomModal } from '../../ImageZoomModal';
import { AccordionToggle } from '../../AccordionToggle';
import { CraftingMobileItemHeader } from './generic/CraftingMobileItemHeader';

export const PreviewCraftingElementMobileTable = (props: {
  elementList: ElementCraftingPreview[];
  elementIdToScroll?: string;
  onGoToEnd?: () => void;
}) => {
  const refToScroll = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (props.elementIdToScroll && refToScroll.current) {
      refToScroll.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [props.elementIdToScroll]);

  return (
    <>
      {props.elementList.map(it => {
        if (it.id === props.elementIdToScroll) {
          return (
            <Accordion alwaysOpen>
              <ElementRow
                ref={refToScroll}
                onGoToEnd={props.onGoToEnd}
                elementPreview={it}
              />
            </Accordion>
          );
        } else {
          return (
            <Accordion alwaysOpen>
              <ElementRow elementPreview={it} />
            </Accordion>
          );
        }
      })}
    </>
  );
};

const ElementRow = React.forwardRef<
  HTMLDivElement,
  {
    elementPreview: ElementCraftingPreview;
    onGoToEnd?: (() => void) | undefined;
  }
>((props, ref) => {
  const { elementPreview, onGoToEnd } = props;

  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setShowImageModal(true);
  };
  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (ref) {
      setIsHighlighted(true);
      setTimeout(() => {
        setIsHighlighted(false);
        onGoToEnd!!();
      }, 1000); // Remove highlight after 1 second
    }
  }, [ref, onGoToEnd]);

  return (
    <>
      <Row
        ref={ref}
        className={`${isHighlighted ? styles.highlight : ''}`}>
        <CraftingMobileItemHeader />
        <div className={styles.mobileVerticalLineDiv} />
        <Row
          className={`pb-2 px-0 align-items-center border-bottom border-1 ${styles.mobileCraftingRowContentDiv}`}>
          <Col
            xs={2}
            className={'p-0'}>
            <Image
              src={getThumbnailUrl(elementPreview.imageId)}
              fluid
              className={`${commonStyles.thumbnailSmallImage}`}
              onClick={() => handleImageClick(elementPreview.imageId)}
            />
            <ImageZoomModal
              showImageModal={showImageModal}
              setShowImageModal={setShowImageModal}
              imageId={selectedImage}
            />
          </Col>
          <Col
            xs={3}
            className={'p-0'}>
            <div className={commonStyles.smallBoldText}>
              {elementPreview.name}
            </div>
            <div className={commonStyles.smallText}>
              {elementPreview.stockKeepingUnit}
            </div>
          </Col>
          <Col xs={2}>{elementPreview.oldQuantity}</Col>
          <Col xs={2}>{elementPreview.requiredQuantity}</Col>
          <Col
            xs={2}
            className={
              elementPreview.newQuantity >= 0
                ? styles.greenText
                : styles.redText
            }>
            {elementPreview.newQuantity}
          </Col>
          <Col
            xs={1}
            className={'d-flex justify-content-center'}>
            <AccordionToggle
              eventKey={elementPreview.id}
              iconSize='lg'
            />
          </Col>
          <Accordion.Collapse
            className={'ps-0'}
            eventKey={elementPreview.id}>
            <>
              <Row className={'mt-3'}>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>Nazwa</div>
                  <div>{elementPreview.name}</div>
                </Col>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>SKU</div>
                  <div>{elementPreview.stockKeepingUnit}</div>
                </Col>
              </Row>
              <Row className={'mt-3 pe-2'}>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Kategoria</div>
                  <div>{elementPreview.category}</div>
                </Col>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Komplet</div>
                  <div>Tak</div>
                </Col>
                <Col xs={4}>
                  <div className={commonStyles.secondaryText}>W magazynie</div>
                  <div>{elementPreview.oldQuantity}</div>
                </Col>
                <Col xs={2} />
              </Row>
            </>
          </Accordion.Collapse>
        </Row>
      </Row>
    </>
  );
});
