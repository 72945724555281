import React from 'react';

interface EmptyLabelProps {
  label: string;
}

const EmptyLabel = ({ label }: EmptyLabelProps) => {
  return (
    <>
      <span>{label}</span>
    </>
  );
};

export { EmptyLabel };
