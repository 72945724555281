import { GetProductsHistoryRequest } from '../../api/products/get-products-history';
import React, { useMemo } from 'react';
import { productsOperationTypeMappings } from '../../api/operation-type';
import { ActiveFilterTile } from '../ActiveFilterTile';
import moment from 'moment/moment';
import { Row } from 'react-bootstrap';

export interface ActiveProductsHistoryFiltersProps {
  getProductsHistoryRequest: GetProductsHistoryRequest;
  setGetProductsHistoryRequest: (request: GetProductsHistoryRequest) => void;
}

export function ActiveProductsHistoryFilters(
  props: Readonly<ActiveProductsHistoryFiltersProps>
) {
  const { getProductsHistoryRequest, setGetProductsHistoryRequest } = props;
  const operationTypeMapping = useMemo(
    () => productsOperationTypeMappings(),
    []
  );

  return (
    <Row>
      <ActiveFilterTile
        name={'SKU'}
        value={getProductsHistoryRequest.stockKeepingUnit}
        onRemove={() =>
          setGetProductsHistoryRequest({
            ...getProductsHistoryRequest,
            stockKeepingUnit: undefined,
          })
        }
      />
      <ActiveFilterTile
        name={'Email'}
        value={getProductsHistoryRequest.email}
        onRemove={() =>
          setGetProductsHistoryRequest({
            ...getProductsHistoryRequest,
            email: undefined,
          })
        }
      />
      <ActiveFilterTile
        name={'Typ operacji'}
        value={getProductsHistoryRequest.operationType}
        onRemove={() =>
          setGetProductsHistoryRequest({
            ...getProductsHistoryRequest,
            operationType: undefined,
          })
        }
        mapFunction={value => operationTypeMapping.get(value)}
      />
      <ActiveFilterTile
        name={'Data od'}
        value={getProductsHistoryRequest.dateFrom}
        onRemove={() =>
          setGetProductsHistoryRequest({
            ...getProductsHistoryRequest,
            dateFrom: undefined,
          })
        }
        mapFunction={value => moment(value).format('DD.MM.YYYY')}
      />
      <ActiveFilterTile
        name={'Data do'}
        value={getProductsHistoryRequest.dateTo}
        onRemove={() =>
          setGetProductsHistoryRequest({
            ...getProductsHistoryRequest,
            dateTo: undefined,
          })
        }
        mapFunction={value => moment(value).format('DD.MM.YYYY')}
      />
    </Row>
  );
}
