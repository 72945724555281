import { FloatingLabel, Form, FormGroup } from 'react-bootstrap';
import React from 'react';

interface FormSelectProps {
  controlId: string;
  label: string;
  required: boolean;
  onChange: (selectedValue: string) => void;
  emptyValueLabel: string;
  values: any[];
  valueLabelMappings: Map<any, string>;
  errorText: string;
  value: string;
  disabled: boolean;
}

export const FormSelect = ({
  controlId,
  label,
  required,
  onChange,
  emptyValueLabel,
  values,
  valueLabelMappings,
  errorText,
  value,
  disabled,
}: FormSelectProps) => {
  return (
    <FormGroup>
      <FloatingLabel
        controlId={controlId}
        label={label}>
        <Form.Select
          disabled={disabled}
          required={required}
          value={value}
          onChange={e => onChange(e.target.value)}>
          <option value=''>{emptyValueLabel}</option>
          {values.map(value => {
            return (
              <option value={value}>{valueLabelMappings.get(value)}</option>
            );
          })}
        </Form.Select>
        <Form.Control.Feedback type='invalid'>
          {errorText}
        </Form.Control.Feedback>
      </FloatingLabel>
    </FormGroup>
  );
};
