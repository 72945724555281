import { usePostAction } from '../http-client';

export interface UpdateProductRequest {
  id: string;
  name: string;
  stockKeepingUnit: string;
  category: string;
  europeanArticleNumber: string;
  imageId: string;
  moduleList: Module[];
}

interface Module {
  id: string;
  schemaQuantity: number;
}

export function useUpdateProduct() {
  const { callPost } = usePostAction<UpdateProductRequest, void>(
    '/update-product'
  );

  return { updateProduct: callPost };
}
