import { ItemType } from '../data/item-model';
import { useMemo } from 'react';
import { useGetCategoryList } from './category/get-catategory-list';

export function useCategories(itemType: ItemType) {
  const categoryListRequest = useMemo(() => {
    return { itemType: itemType };
  }, [itemType]);
  const { getCategoryListResponse } = useGetCategoryList(categoryListRequest);
  const categoryList = useMemo(
    () => getCategoryListResponse?.data?.categoryList ?? [],
    [getCategoryListResponse]
  );

  return {
    categoryList,
  };
}
