export interface WarehouseHistoryModel {
  stockKeepingUnit: string;
  email: string;
  firstName: string;
  lastName: string;
  operationType: OperationType;
  creationTimestamp: Date;
  oldQuantity: number;
  newQuantity: number;
}

export interface ProductHistoryModel {
  stockKeepingUnit: string;
  email: string;
  firstName: string;
  lastName: string;
  operationType: OperationType;
  creationTimestamp: Date;
  craftedQuantity: number;
}

export enum OperationType {
  SCHEMA_CREATED = 'SCHEMA_CREATED',
  QUANTITY_MODIFIED = 'QUANTITY_MODIFIED',
  ITEM_CRAFTED = 'ITEM_CRAFTED',
}
