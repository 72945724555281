import { usePostAction } from '../http-client';

interface CreateModuleRequest {
  name: string;
  stockKeepingUnit: string;
  category: string;
  variant: string;
  imageId: string;
  elementList: Element[];
}

interface Element {
  id: string;
  schemaQuantity: number;
}

function useCreateModule() {
  const { callPost } = usePostAction<CreateModuleRequest, void>(
    '/create-module'
  );
  return { createModule: callPost };
}

export { useCreateModule };
