import { usePostAction } from '../http-client';

interface DeleteVariantRequest {
  id: string;
}

interface DeleteVariantResponse {
  id: string;
  deleted: boolean;
}

function useDeleteVariant() {
  const { callPost } = usePostAction<
    DeleteVariantRequest,
    DeleteVariantResponse
  >('/delete-variant', false);
  return { deleteVariant: callPost };
}

export { useDeleteVariant };
export type { DeleteVariantRequest };
