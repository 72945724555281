import { Tab, Tabs } from 'react-bootstrap';
import { useSetTitle } from '../../context/TitleContext';
import ProductsOverview from './overview/ProductsOverview';
import { ProductsHistory } from './history/ProductsHistory';

function Products() {
  useSetTitle('Produkty');
  return (
    <Tabs
      mountOnEnter
      unmountOnExit
      className='border-bottom border-4 h4'>
      <Tab
        eventKey='overview'
        title='Podgląd'>
        <ProductsOverview />
      </Tab>
      <Tab
        eventKey='history'
        title='Historia'>
        <ProductsHistory />
      </Tab>
    </Tabs>
  );
}

export { Products };
