export enum NavigationPath {
  SIGN_IN = '/sign-in',
  ROOT = '/',
  WAREHOUSE = '/magazyn',
  PRODUCTS = '/produkty',
  SCHEMAS = '/schematy',
  ADD_ELEMENT_SCHEMA = '/schematy/dodaj-element',
  EDIT_ELEMENT_SCHEMA = '/schematy/edytuj-element',
  ADD_MODULE_SCHEMA = '/schematy/dodaj-modul',
  EDIT_MODULE_SCHEMA = '/schematy/edytuj-modul',
  SETTINGS = '/ustawienia',
  CRAFT_ELEMENT = '/magazyn/wytworz-element',
  CRAFT_MODULE = '/magazyn/wytworz-modul',
  CRAFT_PRODUCT = '/produkty/wytworz-produkt',
  ADD_PRODUCT_SCHEMA = '/schematy/dodaj-produkt',
  EDIT_PRODUCT_SCHEMA = '/schematy/edytuj-produkt',
  ADD_USER = '/ustawienia/dodaj-uzytkownika',
  EDIT_USER = '/ustawienia/edytuj-uzytkownika',
}

export enum QueryParams {
  BASE_MODULE_ID = 'base-module-id',
}
