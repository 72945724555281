import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { ModuleFilters } from '../../../components/modules/ModuleFilters';
import { CenteredSpinner } from '../../../components/CenteredSpinner';
import CustomPagination from '../../../components/CustomPagination';
import { useModules } from '../../../api/modules';
import { useCategories } from '../../../api/categories';
import { ItemType } from '../../../data/item-model';
import { useVariants } from '../../../api/variants';
import { NavigationPath } from '../../../data/constants';
import { useNavigate } from 'react-router-dom';
import { RoleFilter } from '../../../components/RoleFilter';
import { UserRole } from '../../../api/auth/token-content';
import {
  CustomColumn,
  CustomTable,
} from '../../../components/table/CustomTable';
import { SchemaActionButtonsRenderer } from '../../../components/table/renderers/action-buttons/SchemaActionButtonsRenderer';
import { ImageRenderer } from '../../../components/table/renderers/ImageRenderer';
import { useSchemaModuleActionButtonsState } from '../../../components/table/field-states/SchemaActionButtonsState';
import {
  GenerateModuleQrCodesRequest,
  useGenerateModuleQrCodes,
} from '../../../api/modules/generate-module-qr-codes';
import download from 'downloadjs';

const columns: CustomColumn[] = [
  {
    name: '',
    field: 'imageId',
    className: 'col-sm-1',
    renderer: ImageRenderer,
  },
  {
    name: 'Nazwa',
    field: 'name',
    className: 'col-sm-3',
  },
  {
    name: 'SKU',
    field: 'stockKeepingUnit',
    className: 'col-sm-2',
  },
  {
    name: 'Kategoria',
    field: 'category',
    className: 'col-sm-2',
  },
  {
    name: 'Wariant',
    field: 'variant',
    className: 'col-sm-2',
  },
  {
    name: '',
    field: 'actionButtons',
    className: 'col-sm-2',
    renderer: SchemaActionButtonsRenderer,
    prepareInitialState: useSchemaModuleActionButtonsState,
  },
];

export default function ModuleSchemas() {
  const navigate = useNavigate();
  const {
    moduleListResponse,
    isLoading,
    getModuleListRequest,
    handlePaginationChange,
    setGetModuleListRequest,
  } = useModules();

  const { categoryList } = useCategories(ItemType.MODULE);
  const { variantList } = useVariants();

  const { callGenerateModuleQrCodes } = useGenerateModuleQrCodes();

  const navigateToAddModuleSchema = () => {
    navigate(NavigationPath.ADD_MODULE_SCHEMA);
  };

  const isModuleListEmpty = moduleListResponse.moduleList.length === 0;

  const generateQRCodes = () => {
    const generateModuleQrCodesRequest: GenerateModuleQrCodesRequest = {
      name: getModuleListRequest.name,
      stockKeepingUnit: getModuleListRequest.stockKeepingUnit,
      category: getModuleListRequest.category,
      variant: getModuleListRequest.variant,
    };

    callGenerateModuleQrCodes(generateModuleQrCodesRequest).then(response => {
      if (!response.error && response.data) {
        download(response.data, 'kody-qr-moduly.zip');
      }
    });
  };

  return (
    <>
      <Row className='align-items-sm-center'>
        <Col sm='10'>
          <ModuleFilters
            moduleCategories={categoryList}
            moduleVariants={variantList}
            moduleListRequest={getModuleListRequest}
            setModuleListRequest={setGetModuleListRequest}
          />
        </Col>
        <RoleFilter
          visibleForRoles={[UserRole.WAREHOUSE_ADMIN, UserRole.SYSTEM_ADMIN]}>
          <Col sm={2}>
            <Row>
              <Col>
                <Button
                  variant='primary'
                  onClick={navigateToAddModuleSchema}
                  className={'w-100'}>
                  <FontAwesomeIcon icon={faPlus} />
                  <span className='m-2'>Dodaj moduł</span>
                </Button>
              </Col>
            </Row>
            <Row className={'mt-2'}>
              <Col>
                <Button
                  variant={'outline-primary'}
                  onClick={generateQRCodes}
                  className='w-100'>
                  <FontAwesomeIcon icon={faQrcode} />
                  <span className={'m-2'}>Generuj kody QR</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </RoleFilter>
      </Row>
      {isLoading && <CenteredSpinner />}
      {!isLoading && (
        <CustomTable
          columns={columns}
          data={moduleListResponse.moduleList}
          emptyListMessage='Brak schematów modułów.'
          refetch={() => setGetModuleListRequest({ ...getModuleListRequest })}
        />
      )}
      {!isModuleListEmpty && (
        <CustomPagination
          pageNumber={moduleListResponse.page.pageNumber + 1}
          elementsPerPage={moduleListResponse.page.pageSize}
          items={moduleListResponse.moduleList}
          totalPages={moduleListResponse.page.pageCount}
          onPaginationParamsChange={(pageNumber, elementsPerPage) =>
            handlePaginationChange(pageNumber, elementsPerPage)
          }
        />
      )}
    </>
  );
}
