import { usePostFetchWithDebounce } from '../http-client';

export interface PreviewElementSetCraftingRequest {
  id: string;
  craftQuantity: number;
}

export interface PreviewElementSetCraftingResponse {
  elementSet: ElementSetCraftingPreview;
  elementList: ElementCraftingPreview[];
}

export interface ElementSetCraftingPreview {
  id: string;
  name: string;
  stockKeepingUnit: string;
  category: string;
  imageId: string;
  oldQuantity: number;
  requiredQuantity: number;
  newQuantity: number;
  craftedQuantity: number;
  subItemList: ElementCraftingPreview[];
}

export interface ElementCraftingPreview {
  id: string;
  name: string;
  stockKeepingUnit: string;
  category: string;
  imageId: string;
  oldQuantity: number;
  requiredQuantity: number;
  newQuantity: number;
}

export function usePreviewElementSetCrafting(
  request: PreviewElementSetCraftingRequest
) {
  const { response } = usePostFetchWithDebounce<
    PreviewElementSetCraftingRequest,
    PreviewElementSetCraftingResponse
  >('/preview-element-set-crafting', request);
  return { response };
}
