import { usePostAction } from '../http-client';
import { UserRole } from '../auth/token-content';

export interface UpdateUserRequest {
  userId: string;
  firstName: String;
  lastName: String;
  role: UserRole;
}

export function useUpdateUser() {
  const { callPost } = usePostAction<UpdateUserRequest, void>('/update-user');
  return { updateUser: callPost };
}
