import { BaseRendererType } from '../CustomTable';

export function ProductHistoryCraftedQuantityRenderer(
  props: BaseRendererType
): JSX.Element {
  const object = props.tableState.object;
  return (
    <p className='mb-0'>
      {object[props.field] > 0 ? object[props.field] : '-'}
    </p>
  );
}
