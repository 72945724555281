import { usePostFetch } from '../http-client';
import { ModuleModel } from '../../data/module-model';

export interface GetSingleModuleRequest {
  id: string;
}

export function useGetSingleModule(request?: GetSingleModuleRequest) {
  const { response } = usePostFetch<GetSingleModuleRequest, ModuleModel>(
    '/get-single-module',
    request
  );
  return { response };
}
