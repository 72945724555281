import { useState, useCallback } from 'react';

export const useImageModal = () => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = useCallback(
    (image: string) => {
      setSelectedImage(image);
      setShowImageModal(true);
    },
    [setSelectedImage, setShowImageModal]
  );

  return { showImageModal, setShowImageModal, selectedImage, handleImageClick };
};
