import React, { useMemo, useState } from 'react';
import {
  Autocomplete,
  AutocompleteOption,
} from '../form/autocomplete/Autocomplete';
import {
  GetVariantListRequest,
  GetVariantListResponse,
  useGetVariantList,
} from '../../api/variant/get-variant-list';
import { useAddVariant } from '../../api/variant/add-variant';
import { useDeleteVariant } from '../../api/variant/delete-variant';

interface VariantListSelectProps {
  variant: string;
  setVariant: (value: string) => void;
}

function mapToAutocompleteOptions(
  response?: GetVariantListResponse
): AutocompleteOption[] {
  if (response) {
    return response.variantList.map(variant => {
      return { ...variant, canBeDeleted: !variant.isUsed };
    });
  } else {
    return [];
  }
}

const VariantListSelect = ({ variant, setVariant }: VariantListSelectProps) => {
  const initialRequest: GetVariantListRequest = {};
  const { addVariant } = useAddVariant();
  const { deleteVariant } = useDeleteVariant();
  const [request, setRequest] = useState(initialRequest);
  const { getVariantListResponse } = useGetVariantList(request);
  const variantList = useMemo(
    () => mapToAutocompleteOptions(getVariantListResponse.data),
    [getVariantListResponse]
  );
  const addNewValue = async (value: string) => {
    await addVariant({ label: value });
  };
  const refreshValues = () => {
    setRequest({ ...request });
  };
  const deleteValue = async (id: string) => {
    await deleteVariant({ id });
  };

  return (
    <Autocomplete
      inputId={'variant'}
      placeholder={'Wariant'}
      addNewLabel={'Dodaj nowy wariant'}
      emptyLabel={'Wpisz nazwę wariantu'}
      value={variant}
      setValue={setVariant}
      addNewValue={addNewValue}
      values={variantList}
      refreshValues={refreshValues}
      deleteValue={deleteValue}
    />
  );
};

export { VariantListSelect };
