import { createBrowserRouter, Navigate } from 'react-router-dom';
import { SignIn } from '../universal-view/sign-in/SignIn';
import { RootPage } from '../desktop-view/root-page/RootPage';
import { Warehouse } from '../desktop-view/warehouse/Warehouse';
import { Products } from '../desktop-view/products/Products';
import { Schemas } from '../desktop-view/schemas/Schemas';
import { ProtectedView } from '../components/ProtectedView';
import { UserRole } from '../api/auth/token-content';
import AddElementSchema from '../desktop-view/schemas/elements/AddElementSchema';
import EditElementSchema from '../desktop-view/schemas/elements/EditElementSchema';
import { AddModuleSchema } from '../desktop-view/schemas/modules/AddModuleSchema';
import { EditModuleSchema } from '../desktop-view/schemas/modules/EditModuleSchema';
import { Settings } from '../desktop-view/settings/Settings';
import { CraftElementSet } from '../desktop-view/crafting/CraftElementSet';
import { AddProductSchema } from '../desktop-view/schemas/products/AddProductSchema';
import { EditProductSchema } from '../desktop-view/schemas/products/EditProductSchema';
import React from 'react';
import { NavigationPath } from '../data/constants';
import { CraftModule } from '../desktop-view/crafting/CraftModule';
import { CraftProduct } from '../desktop-view/crafting/CraftProduct';
import { EditUser } from '../desktop-view/settings/add-user/EditUser';
import { AddUser } from '../desktop-view/settings/add-user/AddUser';

export const desktopRouter = createBrowserRouter([
  {
    path: NavigationPath.SIGN_IN,
    element: <SignIn />,
  },
  {
    path: NavigationPath.ROOT,
    element: <RootPage />,
    children: [
      {
        path: NavigationPath.ROOT,
        element: (
          <Navigate
            to={NavigationPath.WAREHOUSE}
            replace
          />
        ),
      },
      {
        path: NavigationPath.WAREHOUSE,
        element: <Warehouse />,
      },
      {
        path: NavigationPath.PRODUCTS,
        element: <Products />,
      },
      {
        path: NavigationPath.SCHEMAS,
        element: <Schemas />,
      },
      {
        path: NavigationPath.ADD_ELEMENT_SCHEMA,
        element: (
          <ProtectedView
            visibleForRoles={[UserRole.WAREHOUSE_ADMIN, UserRole.SYSTEM_ADMIN]}>
            <AddElementSchema />
          </ProtectedView>
        ),
      },
      {
        path: `${NavigationPath.EDIT_ELEMENT_SCHEMA}/:elementSchemaId`,
        element: (
          <ProtectedView
            visibleForRoles={[UserRole.WAREHOUSE_ADMIN, UserRole.SYSTEM_ADMIN]}>
            <EditElementSchema />
          </ProtectedView>
        ),
      },
      {
        path: NavigationPath.ADD_MODULE_SCHEMA,
        element: (
          <ProtectedView
            visibleForRoles={[UserRole.WAREHOUSE_ADMIN, UserRole.SYSTEM_ADMIN]}>
            <AddModuleSchema />
          </ProtectedView>
        ),
      },
      {
        path: `${NavigationPath.EDIT_MODULE_SCHEMA}/:moduleSchemaId`,
        element: (
          <ProtectedView
            visibleForRoles={[UserRole.WAREHOUSE_ADMIN, UserRole.SYSTEM_ADMIN]}>
            <EditModuleSchema />
          </ProtectedView>
        ),
      },
      {
        path: '*',
        element: <Navigate to={NavigationPath.WAREHOUSE} />,
      },
      {
        path: NavigationPath.SETTINGS,
        element: <Settings />,
      },
      {
        path: `${NavigationPath.CRAFT_ELEMENT}/:elementId`,
        element: <CraftElementSet />,
      },
      {
        path: `${NavigationPath.CRAFT_MODULE}/:moduleId`,
        element: <CraftModule />,
      },
      {
        path: `${NavigationPath.CRAFT_PRODUCT}/:productId`,
        element: <CraftProduct />,
      },
      {
        path: NavigationPath.ADD_PRODUCT_SCHEMA,
        element: (
          <ProtectedView
            visibleForRoles={[UserRole.WAREHOUSE_ADMIN, UserRole.SYSTEM_ADMIN]}>
            <AddProductSchema />
          </ProtectedView>
        ),
      },
      {
        path: `${NavigationPath.EDIT_PRODUCT_SCHEMA}/:productId`,
        element: (
          <ProtectedView
            visibleForRoles={[UserRole.WAREHOUSE_ADMIN, UserRole.SYSTEM_ADMIN]}>
            <EditProductSchema />
          </ProtectedView>
        ),
      },
      {
        path: NavigationPath.ADD_USER,
        element: <AddUser />,
      },
      {
        path: `${NavigationPath.EDIT_USER}/:userId`,
        element: <EditUser />,
      },
    ],
  },
]);
