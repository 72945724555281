import { RequestPreparer } from './request-preparer';
import {
  AuthorizationRequest,
  AuthorizationResponse,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  FetchRequestor,
  TokenRequest,
  TokenResponse,
} from '@openid/appauth';
import { TokenInfo } from '../../data/common-model';
import { CODE_VERIFIER } from './auth-consts';

export class TokenRequestPerformer {
  private requestPreparer = new RequestPreparer();
  private tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());

  private mapToTokenInfo(response: TokenResponse): TokenInfo {
    if (response.refreshToken && response.expiresIn && response.idToken) {
      return {
        idToken: response.idToken,
        accessToken: response.accessToken,
        refreshToken: response.refreshToken,
        expirationTimestampSeconds: Date.now() / 1000 + response.expiresIn,
      };
    } else {
      throw new Error('No refresh token or no expiration time');
    }
  }

  private performTokenRequest(
    config: AuthorizationServiceConfiguration,
    tokenRequest: TokenRequest
  ) {
    return this.tokenHandler.performTokenRequest(config, tokenRequest);
  }

  fetchInitialToken(
    config: AuthorizationServiceConfiguration,
    request: AuthorizationRequest,
    response: AuthorizationResponse
  ) {
    if (request.internal) {
      const tokenRequest = this.requestPreparer.prepareTokenRequestByCode(
        response.code,
        request.internal[CODE_VERIFIER]
      );
      return this.performTokenRequest(config, tokenRequest).then(value =>
        this.mapToTokenInfo(value)
      );
    } else {
      return Promise.reject('Fail during token fetching');
    }
  }

  fetchNextTokenRequest(
    config: AuthorizationServiceConfiguration,
    refreshToken: TokenInfo
  ): Promise<TokenInfo> {
    if (refreshToken) {
      const tokenRequest =
        this.requestPreparer.prepareTokenRequestByRefreshToken(
          refreshToken.refreshToken
        );
      return this.performTokenRequest(config, tokenRequest).then(
        this.mapToTokenInfo
      );
    } else {
      return Promise.reject('Token refresh request failed');
    }
  }
}
