import { usePostAction } from '../http-client';

interface AddVariantRequest {
  label: string;
}

interface AddVariantResponse {
  id: string;
  label: string;
}

function useAddVariant() {
  const { callPost } = usePostAction<AddVariantRequest, AddVariantResponse>(
    '/add-variant'
  );
  return { addVariant: callPost };
}

export { useAddVariant };
export type { AddVariantResponse };
