import { useNavigate } from 'react-router-dom';
import {
  faPenToSquare,
  faPlus,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import commonStyles from '../../../../desktop-view/Common.module.scss';
import { WarehouseQuantityState } from '../../field-states/WarehouseQuantityState';
import { BaseRendererType, CustomTableState } from '../../CustomTable';
import { useMemo } from 'react';

function WarehouseActionButtonsRenderer(
  props: BaseRendererType,
  isCraftable: (props: CustomTableState) => boolean
): JSX.Element {
  const tableState: CustomTableState = props.tableState;
  const navigate = useNavigate();
  const warehouseState: WarehouseQuantityState =
    props.cellStates['warehouseQuantity'];

  const isEditMode = () => {
    return tableState.object.isEditMode;
  };

  const craftable = useMemo(
    () => isCraftable(tableState),
    [tableState, isCraftable]
  );
  const handleEditClick = (object: any) => {
    tableState.setObject({
      ...object,
      isEditMode: true,
    });
  };

  const handleConfirmationClick = () => {
    warehouseState.setShowQuantityConfirmationModal(true);
  };

  const handleRemovalClick = (object: any) => {
    tableState.setObject({
      ...object,
      isEditMode: false,
    });
    warehouseState.setQuantity(tableState.object.warehouseQuantity);
  };

  const handleCraftElementClick = (object: any) => {
    navigate(`${warehouseState.craftElementNavigationPath}/${object.id}`);
  };

  return (
    <>
      {isEditMode() && (
        <FontAwesomeIcon
          icon={faCheck}
          size={'xl'}
          className={`${commonStyles.iconGreen} me-3`}
          onClick={() => handleConfirmationClick()}
        />
      )}
      {!isEditMode() && (
        <>
          {craftable && (
            <FontAwesomeIcon
              icon={faPlus}
              size='xl'
              className={`${commonStyles.iconPrimary} me-3`}
              onClick={() => handleCraftElementClick(tableState.object)}
            />
          )}
          <FontAwesomeIcon
            icon={faPenToSquare}
            size={'xl'}
            className={`${commonStyles.iconPrimary} me-3`}
            onClick={() => handleEditClick(tableState.object)}
          />
        </>
      )}
      {isEditMode() && (
        <FontAwesomeIcon
          icon={faXmark}
          size={'xl'}
          className={`${commonStyles.iconRed} me-3`}
          onClick={() => handleRemovalClick(tableState.object)}
        />
      )}
    </>
  );
}

export function WarehouseElementActionButtonsRenderer(
  props: BaseRendererType
): JSX.Element {
  return WarehouseActionButtonsRenderer(
    props,
    (props: CustomTableState) => props.object.isElementSet
  );
}

export function WarehouseModuleActionButtonsRenderer(
  props: BaseRendererType
): JSX.Element {
  return WarehouseActionButtonsRenderer(
    props,
    (props: CustomTableState) => true
  );
}
