export enum UserRole {
  WAREHOUSE_ADMIN = 'WAREHOUSE_ADMIN',
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
  EMPLOYEE = 'EMPLOYEE',
  BLANK = 'BLANK', //Role only for creating "null object". Not supported on keycloak,
}

interface RealmAccess {
  roles: UserRole[];
}

export interface TokenContent {
  exp: number; //expiration time (in seconds since epoch)
  iat: number; //issued at  (in seconds since epoch)
  auth_time: number; //auth time (in seconds since epoch)
  jti: string; //unique id of token
  iss: string; //issuer (url of oauth server when auth happened)
  sub: string; //subject (no idea what it is)
  typ: string; //type of token (in our case Bearer)
  azp: string; // client id used for auth (in our case brent-flow-web)
  session_state: string; // probably something for keycloak iternal token handling
  realm_access: RealmAccess; // property which contains user role information
  scope: string; // auth source (in our case openid)
  sid: string; //session id
  given_name: string; // first name of user
  family_name: string; // last name of user
  email: string; // email/login of user
}

export function roleMappings() {
  const roleMapping = new Map();
  roleMapping.set(UserRole.WAREHOUSE_ADMIN, 'Administrator magazynu');
  roleMapping.set(UserRole.SYSTEM_ADMIN, 'Administrator systemu');
  roleMapping.set(UserRole.EMPLOYEE, 'Pracownik');
  roleMapping.set(UserRole.BLANK, 'Brak(użytkownik nieprawidłowy)');
  return roleMapping;
}
