import { usePostAction } from '../http-client';

export interface GenerateElementQrCodesRequest {
  name?: string;
  stockKeepingUnit?: string;
  category?: string;
  isElementSet?: boolean;
}

export function useGenerateElementQrCodes() {
  const { callPost } = usePostAction<GenerateElementQrCodesRequest, Blob>(
    '/generate-element-qr-codes',
    true,
    { responseType: 'blob' }
  );

  return { callGenerateElementQrCodes: callPost };
}
