import {
  TypeaheadInputProps,
  TypeaheadManagerChildProps,
} from 'react-bootstrap-typeahead/types/types';
import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { Hint } from 'react-bootstrap-typeahead';

interface RenderAutocompleteItemProps {
  controlId: string;
  label: string;
}

const RenderAutocompleteInput = ({
  controlId,
  label,
}: RenderAutocompleteItemProps): ((
  inputProps: TypeaheadInputProps,
  props: TypeaheadManagerChildProps
) => JSX.Element) => {
  return (inputProps, props) => {
    const { inputRef, referenceElementRef, value, id, ...requiredInputProps } =
      inputProps;
    return (
      <Hint>
        <FloatingLabel
          controlId={controlId}
          label={label}
          style={{ width: '100%' }}>
          <Form.Control
            {...requiredInputProps}
            value={value as string}
            ref={(node: any) => {
              inputRef(node);
              referenceElementRef(node);
            }}
          />
        </FloatingLabel>
      </Hint>
    );
  };
};

export { RenderAutocompleteInput };
