import { useSetTitle } from '../../context/TitleContext';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PreviewModuleCraftingRequest,
  usePreviewModuleCrafting,
} from '../../api/elements/preview-module-crafting';
import { CenteredSpinner } from '../../components/CenteredSpinner';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { PreviewCraftingElementSetTable } from '../../components/crafting/PreviewCraftingElementSetTable';
import { PreviewCraftingElementTable } from '../../components/crafting/PreviewCraftingElementTable';
import { PreviewCraftingModuleTable } from '../../components/crafting/PreviewCraftingModuleTable';
import { useCraftModule } from '../../api/elements/craft-module';

export function CraftModule() {
  useSetTitle('Magazyn - Wytwórz moduł');
  const [activeTab, setActiveTab] = useState('modules');
  const { moduleId } = useParams();
  const [quantity, setQuantity] = useState(0);
  const [elementToScrollId, setElementToScrollId] = useState<
    string | undefined
  >(undefined);
  const request: PreviewModuleCraftingRequest = useMemo(
    () => ({
      id: moduleId!,
      craftQuantity: quantity,
    }),
    [moduleId, quantity]
  );
  const { response: previewModuleCraftingResponse } =
    usePreviewModuleCrafting(request);

  const onGoToElementSetsClick = useCallback(
    (id: string) => {
      setActiveTab('elements');
      setElementToScrollId(id);
    },
    [setActiveTab]
  );
  const onGoToModulesClick = useCallback(
    (id: string, activeTab: string) => {
      setActiveTab(activeTab);
      setElementToScrollId(id);
    },
    [setActiveTab]
  );
  const onGoToEnd = useCallback(() => {
    setElementToScrollId(undefined);
  }, [setElementToScrollId]);

  const isCraftPossible = useMemo(() => {
    return (
      (previewModuleCraftingResponse.data &&
        previewModuleCraftingResponse.data.module.craftedQuantity !== 0) ||
      false
    );
  }, [previewModuleCraftingResponse.data]);

  return (
    <>
      {previewModuleCraftingResponse.isLoading && <CenteredSpinner />}
      {previewModuleCraftingResponse.data && (
        <div className='d-flex flex-column h-75'>
          <Tabs
            className='border-bottom border-4 h4'
            activeKey={activeTab}
            onSelect={k => setActiveTab(k!!)}>
            <Tab
              className={'h-100 pt-5'}
              eventKey='modules'
              title='Moduły'>
              <div className='d-flex flex-column h-100'>
                <PreviewCraftingModuleTable
                  moduleList={[previewModuleCraftingResponse.data.module]}
                  isCraftingRoot={true}
                  onQuantityChange={setQuantity}
                  onGoToClick={onGoToModulesClick}
                />
              </div>
            </Tab>
            {previewModuleCraftingResponse.data.elementSetList.length > 0 && (
              <Tab
                className={'h-100 pt-5'}
                eventKey='elementSets'
                title='Komplety Elementów'>
                <div className='d-flex flex-column h-100'>
                  <PreviewCraftingElementSetTable
                    elementSetList={
                      previewModuleCraftingResponse.data.elementSetList
                    }
                    isCraftingRoot={false}
                    onGoToClick={onGoToElementSetsClick}
                    elementIdToScroll={elementToScrollId}
                    onGoToEnd={onGoToEnd}
                  />
                </div>
              </Tab>
            )}
            <Tab
              className={'h-100 pt-5'}
              eventKey='elements'
              title='Elementy'>
              <PreviewCraftingElementTable
                elementList={previewModuleCraftingResponse.data.elementList}
                elementIdToScroll={elementToScrollId}
                onGoToEnd={onGoToEnd}
              />
            </Tab>
          </Tabs>
          <div className='mt-auto pt-5'>
            <CraftButtons
              previewModuleCraftingRequest={request}
              isCraftPossible={isCraftPossible}
            />
          </div>
        </div>
      )}
    </>
  );
}

const CraftButtons = (props: {
  isCraftPossible: boolean;
  previewModuleCraftingRequest: PreviewModuleCraftingRequest;
}) => {
  const navigate = useNavigate();
  const { callCraftModule } = useCraftModule();

  const handleCreateClick = () => {
    callCraftModule({
      id: props.previewModuleCraftingRequest.id,
      craftQuantity: props.previewModuleCraftingRequest.craftQuantity,
    }).then(() => {
      navigate(-1);
    });
  };

  return (
    <Row className='justify-content-sm-center'>
      <Col
        sm={3}
        className='d-flex justify-content-center'>
        <Button
          variant='outline-primary'
          onClick={() => {
            navigate(-1);
          }}
          className='w-50 mb-2'>
          <span className='m-2'>Anuluj</span>
        </Button>
      </Col>
      <Col
        sm={3}
        className='d-flex justify-content-center'>
        <Button
          onClick={() => handleCreateClick()}
          disabled={
            !props.isCraftPossible ||
            props.previewModuleCraftingRequest.craftQuantity === 0
          }
          variant='primary'
          className='w-50 mb-2'>
          <span className='m-2'>Utwórz</span>
        </Button>
      </Col>
    </Row>
  );
};
